import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, Row, FormGroup, Label, Badge, Table, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, InputGroup, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, } from 'reactstrap';
import Select from "react-select";
// for css
import './quoteCalculator.scss';
// Formik Validation
import * as Yup from "yup";
import { useFormik, Formik, Field, FieldArray } from "formik";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { QuoteService } from '../../../quotes/service/quote.service';
// message
import { saveQuoteModalErr, Regex, QUOTE_CALCULATOR, calculatorQuickTourSteps, SUBSCRIPTION_PLAN_UUID, registerValidationErr, SUBSCRIPTION_CALC_TYPE, CALCULATOR_TYPE } from '../../../shared/static/constants/constant';
// loader
import Spinners from '../../../shared/components/common/spinner';
import RichTextEditor from "react-rte";
import classnames from "classnames";
import { QuoteCalculatorService } from '../../service/quoteCalculator.service';
import Skeleton from 'react-loading-skeleton';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigatingAway } from "../../../shared/components/common/useNavigationAway";
import { useNavigate, useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import Breadcrumb from "../../../shared/components/common/breadcrumb";
import ConfirmationModal from '../../../shared/components/common/confirmationModal';
import ImagePreviewModal from '../../../shared/components/common/imagePreviewModal';
import TaxSelect from "../../../shared/components/common/addTax";
// for quick tour
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import ImageUpload from "../../../shared/components/common/imageUpload";
import { CustomerService } from "../../../customers/service/customer.service";
import SubscriptionMiddleware from "../../../shared/components/common/subscriptionMiddleware";
import QuoteCalcTabView from "../../../shared/components/common/quoteCalcTabView";
import { useAtom } from "jotai";
import { authenticatedUserInfo } from "../../../../atoms";

const masterTableLable = {
    location: 'Locations',
    hoopingFee: 'Hooping Fee',
    rpm: 'RPM',
    embroidory_heads: 'Embroidery Heads',
    stitchCount: 'Stitch Count',
    custom_charge: 'Custom Charge',
    applique: 'Applique',
    laborPerHour: 'Labor per hour',
    shirt_in_job: 'Shirts in job',
    of_location: '# of Locations',
    shirtPerHour: 'Shirts / Hour'
}

const QuoteCalculator = (props) => {
    const [get_customer_shop_tax_master_uuid, set_customer_shop_tax_master_uuid] = useState('');
    const [modal, setModal] = useState(false);
    const [getQuoteNotes, setQuoteNotes] = useState(RichTextEditor.createEmptyValue());
    const [getshouldRefreshSelectedTaxInfo, setshouldRefreshSelectedTaxInfo] = useState(true);
    // loader
    const [isLoading, setLoading] = useState(false);
    // for datepicker
    const [expiresAtVal, setExpiresAtVal] = useState();
    // for modal tab
    const [activeTabVartical, setoggleTabVertical] = useState(1)
    const [passedStepsVertical, setPassedStepsVertical] = useState([1]);
    // for client list option data
    const [quoteClientList, setQuoteClientList] = useState([]);
    // for client radio buton
    const [isExisting, setIsExisting] = useState(true);
    // for linear and grid view
    const [isLinearView, setIsLinearView] = useState(false);
    // for upload item image 
    const [selectedImages, setSelectedImages] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [imgTypeErrorArr, setImgTypeErrorArr] = useState([]);
    const [editQuoteAddedItemUuid, setEditQuoteAddedItemUuid] = useState([]);
    const [getSelectedTaxInfo, setSelectedTaxInfo] = useState([]);
    const [userLoginData, setLoginData] = useAtom(authenticatedUserInfo);
    const [loggedinUserPlan, setLoggedInUserPlan] = useState(null); // Initialize as null to prevent premature use
    const [activeTabview, setActiveTabView] = useState(null); // Initialize as null to prevent incorrect activeTabview

    const [previousCalculatorType, setPreviousCalculatorType] = useState(null); // State to track the previous calculatorType

    useEffect(() => {
        // Get the current calculator type from userLoginData
        const calculatorType = userLoginData?.data?.subscription_details?.subscription_plan_details?.calculator_type || SUBSCRIPTION_CALC_TYPE.ALL;

        // Set loggedinUserPlan
        setLoggedInUserPlan(calculatorType);

        // Update activeTabview based on the loggedinUserPlan
        const newActiveTabView = calculatorType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || calculatorType === SUBSCRIPTION_CALC_TYPE.ALL
            ? CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR
            : CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR;
        setActiveTabView(newActiveTabView);

        // Check if calculatorType has changed
        if (calculatorType !== previousCalculatorType) {

            // Call APIs based on the current calculatorType
            if (calculatorType === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || calculatorType === SUBSCRIPTION_CALC_TYPE.ALL) {
                getGarmentMasterListInfo();
                getStitchCountListInfo();
                getSetUpMasterListInfo();
                getTechnicalSpecificationListInfo();
                countGarmentsPerHourTechnicalSpecification();
            } else {
                getGarmentPrintingMasterInfo();
                getPrintingInkMasterInfo();
                getSetUpPrintingMasterInfo();
                getJobCriteriaListInfo();
                getArtPrintingMasterInfo();
            }

            // Update the previousCalculatorType state
            setPreviousCalculatorType(calculatorType);
        }
    }, [userLoginData, previousCalculatorType]);



    const [showMiddleware, setShowMiddleware] = useState(false);
    // for tax modal popup
    const [getTaxInfo, setTaxInfo] = useState([]);

    const { state } = useLocation();
    const [isEditMode, setisEditMode] = useState(state?.iseditMode == null ? false : true);
    const [quoteItems, setQuoteItems] = useState([]);
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeleted, setisDeleted] = useState(false);
    const [deletequoteItem, setiDeleteQuoteItem] = useState();
    const [getJobName, setJobName] = useState();

    // service
    const quoteCalculatorService = new QuoteCalculatorService();
    const quoteService = new QuoteService();
    const customerService = new CustomerService();

    // final figurecalculation
    const [finalFigurescalculatorState, setfinalFigurescalculatorState] = useState({
        cost_per_item: 0,
        gross_cost: 0,
        gross_sale: 0,
        net_profit: 0,
        gross_hourly_rate: 0,
        net_hourly_rate: 0,
        profit_margin_percentage: 0
    });

    // confirmation popup
    const [confirmationModal, setConfirmationModal] = useState(true);
    const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(confirmationModal);

    // for getting stitchQuanitity calculation, rpm value and embroidary heads
    const [stitchCount, setStitchCount] = useState(0);
    const [getRPM, setRpm] = useState(0);
    const [getEmbroideryHeads, setEmbroideryHeads] = useState(1);

    // for garment quantity
    const [itemQuantity, setitemQuantity] = useState(undefined);
    const [getGarmentPerHour, setGarmentPerHour] = useState(0);

    // for item name
    const [getItemDescription, setItemDescription] = useState('');
    const [getItemName, setItemName] = useState('');
    const [error, setError] = useState('');

    // for markup table unique key
    const [markupKey, setMarkUpKey] = useState(0);
    const [printingMarkupKey, setPrintingMarkupKey] = useState(0);

    // for stitchcount table uniquote key
    const [stitchCountKey, setStitchCountKey] = useState(0);

    // for entered sales peice and setup table location value
    const [quoteSalesPrice, setQuoteSalesPrice] = useState(0);
    const [quotePrintingSalesPrice, setQuotePrintingSalesPrice] = useState(0);
    const [getLocation, setlocationValue] = useState(0);

    // get hopping quantity
    const [getHoopingQuantity, setHoopingQuantity] = useState(0);

    // for garment master list table
    const [getGarmentsData, setGarmentsData] = useState([]);
    const [getPrintingGarmentData, setPrintingGarmentsData] = useState([]);
    // for stitch count master list table
    const [getStitchCountData, setStitchData] = useState([]);
    const [getPrintingInkCountData, setPrintingInkCountData] = useState([]);
    const [getPrintingArtCountData, setPrintingArtCountData] = useState([]);

    // for setup master list table
    const [getSetUpMasterData, setSetUpMasterData] = useState([]);
    const [getPrintingSetUpData, setPrintingSetupData] = useState([]);

    // for technical specification master list table
    const [getoriginalTechnicalMasterData, setOriginalTechnicalMasterData] = useState();
    const [getTechnicalMasterData, setTechnicalMatserData] = useState();
    const [getPrintingJobCriteriaData, setPrintingJobCriteria] = useState([]);
    const [laborPerHourKey, setLaborperHourKey] = useState(0);
    const [jobCriteriaKey, setJobCriteriaKey] = useState(0);
    // for pricing master list table
    const [getPricingMasterList, setPricingMasterList] = useState([]);

    const [getMasterTableLoadingState, setMasterTableLoadingState] = useState({
        isgarmentLoading: true,
        isStitchLoading: true,
        isSetUpIsLoading: true,
        isTechnicleMasterListIsLoading: true,
        isPricingMasterIsLoading: true
    });

    const [getScreenPrintingMasterTableLoadingStatus, setScreenPrintingMasterTableLoadingStatus] = useState({
        isgarmentLoading: true,
        isInkLoading: true,
        isSetupLoading: true,
        isJobCriteriaLoading: true,
        isArtTableLoading: true
    });

    // for the preview modal added data
    const [isPreviewModal, setisPreviewModal] = useState(false);

    // for markup master list table
    const [getMarkUpData, setMarkupData] = useState([
        { markup: 75, totalCost: 60, pricePerShirt: 6 },
        { markup: 60, totalCost: 37.50, pricePerShirt: 3.75 },
        { markup: 50, totalCost: 30, pricePerShirt: 3 },
        { markup: 40, totalCost: 25, pricePerShirt: 22.5 }
    ]);

    const [getPrintingMarkupData, setPrintingMarkupData] = useState([
        { markup: 70, totalCost: 0, pricePerShirt: 0 },
        { markup: 60, totalCost: 0, pricePerShirt: 0 },
        { markup: 50, totalCost: 0, pricePerShirt: 0 },
        { markup: 40, totalCost: 0, pricePerShirt: 0 }
    ]);

    // quote sales persentage
    const [quoteSalesPercentage, setQuoteSalesPercentage] = useState(0);

    // for quick tour
    const [isQuickTour, setIsQuickTour] = useState(false);
    // check device
    const [isExtraLargeDevice, setIsExtraLargeDevice] = useState(false);
    const [isSmallLaptopDevice, setIsSmallLaptopDevice] = useState(false);
    const [isLargeDevice, setIsLargeDevice] = useState(false);
    const [isMediumDevice, setIsMediumDevice] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsScrolled(scrollTop > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // for check device
    useEffect(() => {
        function handleResize() {
            window.innerWidth >= 766 ? setIsMediumDevice(true) : setIsMediumDevice(false);
            window.innerWidth >= 991 ? setIsLargeDevice(true) : setIsLargeDevice(false);
            window.innerWidth >= 1200 ? setIsSmallLaptopDevice(true) : setIsSmallLaptopDevice(false);
            window.innerWidth >= 1600 ? setIsExtraLargeDevice(true) : setIsExtraLargeDevice(false);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useState(() => {
        if (state !== null && state.showMiddleware === true) {
            setShowMiddleware(true);
        }
    }, [state]);

    // form
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            jobName: '',
            clientCompanyName: '',
            clientCompanyEmail: '',
            clientName: '',
            address: '',
            client_city: '',
            client_state: '',
            client_country: '',
            client_zip_code: '',
        },
        validationSchema: Yup.object({
            clientName: Yup.string().required(saveQuoteModalErr.ERR_CLIENT_REQUIRED).matches(Regex.ONLY_CHAR, saveQuoteModalErr.ERR_INVALID_CLIENT_NAME),
            jobName: Yup.string().required(saveQuoteModalErr.ERR_JOB_NAME_REQUIRED)
                .matches(Regex.SHOP_NAME, saveQuoteModalErr.ERR_JOB_NAME_INVALID).max(40, saveQuoteModalErr.ERR_JOB_NAME_LENGTH),
            clientCompanyName: Yup.string().required(saveQuoteModalErr.ERR_CLIENT_NAME_REQUIRED)
                .matches(Regex.MINIMUM_ONE_CHARACTER_STRING, saveQuoteModalErr.ERR_CLIENT_NAME_INVALID),
            clientCompanyEmail: Yup.string().required(saveQuoteModalErr.ERR_CLIENT_EMAIL_REQUIRED)
                .email(saveQuoteModalErr.ERR_CLIENT_EMAIL_INVALID),
            address: Yup.string().max(80, saveQuoteModalErr.ERR_ADD_LENGTH),
            client_city: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_CITY_INVALID),
            client_state: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_STATE_INVALID),
            client_country: Yup.string().matches(Regex.ONLY_CHAR, registerValidationErr.ERR_COUNTRY_INVALID),
            client_zip_code: Yup.string().matches(Regex.ZIP_CODE, registerValidationErr.ERR_INVALID_ZIP_CODE)
        })
    });

    // for select dropdown
    const [selectedGroup, setselectedGroup] = useState();

    // for datepicker
    const { date } = {
        date: expiresAtVal
    };

    // for radio button
    const changeRadioBtnValue = (value) => {
        setIsExisting(value);
        if (value === true) {
            validation.values.clientCompanyName = '';
            validation.values.clientCompanyEmail = '';
        } else if (value === false) {
            setselectedGroup(undefined);
        }
    }

    // for toggle tab
    function toggleTabVertical(tab) {
        if (activeTabVartical !== tab) {
            var modifiedSteps = [...passedStepsVertical, tab]
            if (tab >= 1 && tab <= 2) {
                setoggleTabVertical(tab);
                setPassedStepsVertical(modifiedSteps);
            }
        }
        if ((tab === 3 && (isExisting && (selectedGroup))) || (tab === 3 && (!isExisting && (validation.values.clientCompanyEmail && validation.values.clientCompanyName)))) {
            saveQuote();
        }
    }

    // for close modal
    const closeModal = () => {
        setModal(false);
        validation.resetForm();
        setselectedGroup(undefined);
        setExpiresAtVal(undefined);
        setoggleTabVertical(1);
    }

    // for save quote
    const saveQuote = () => {
        let quoteItems = JSON.parse(localStorage.getItem('quote_items'));
        quoteItems.forEach(obj => delete obj.quote_item_details.id);
        quoteItems.forEach(obj => {
            obj.quote_item_tax_details = obj.quote_item_tax_details.map(taxInfo => {
                return { customer_shop_tax_master_uuid: taxInfo.customer_shop_tax_master_uuid };
            });
        });
        // for gettign client email
        const selectedClientData = selectedGroup ? quoteClientList.filter(element => (element.client_name === selectedGroup.label)) : '';
        // for change date formate
        var date = new Date(expiresAtVal.toString().replace('IST', ''));
        const saveQuoteReq = {
            user_uuid: JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid,
            quote_details: {
                "job_name": validation.values.jobName,
                "job_notes": getQuoteNotes.toString('html') === "<p><br></p>" ? null : getQuoteNotes.toString('html'),
                "quote_expires_at": date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
            },
            "quote_client_details": {
                "client_company_name": selectedGroup ? selectedGroup.label : validation.values.clientCompanyName,
                "client_email": selectedGroup ? selectedClientData[0].client_email : validation.values.clientCompanyEmail,
                "client_name": selectedGroup ? selectedClientData[0].client_name : validation.values.clientName,
                "client_address": validation.values.address,
                "client_city": validation.values.client_city ? validation.values.client_city : '',
                "client_state": validation.values.client_state ? validation.values.client_state : '',
                "client_country": validation.values.client_country ? validation.values.client_country : '',
                "client_zip_code": validation.values.client_zip_code ? validation.values.client_zip_code : '',
            },
            "quote_items": quoteItems
        }
        setLoading(true);
        setQuoteItems([]);
        quoteCalculatorService.saveQuote(saveQuoteReq).then((response) => {
            if (response.status === 200) {
                toast.success(response.data.message, { autoClose: 3000 });
                localStorage.removeItem('quote_items');
                setTimeout(() => {
                    navigate('/quote-list');
                }, 3000);
            }
        }).finally(() => {
            setLoading(false);
            setModal(false);
        });
    }

    // calculate garment per hour in technical specification
    const countGarmentsPerHourTechnicalSpecification = (isvalueChanged) => {
        if ((!getMasterTableLoadingState.isTechnicleMasterListIsLoading || isvalueChanged)) {
            // when stitch count quantity is not 0 or Rpm value in setuo table is not 0 then find garment per hour in technical specificaion, hooping fee quantity
            if (stitchCount !== 0 && getRPM !== 0 && getEmbroideryHeads !== '' && +getEmbroideryHeads !== 0) {
                let garmentPerHour = (60 / (stitchCount / getRPM)) * getEmbroideryHeads || 0;
                let setupMasterData = getSetUpMasterData;
                let hoopingFeeObject = getSetUpMasterData?.find((setupobj) => { return setupobj.setup_label === masterTableLable.hoopingFee });
                let hoopingQuantity = itemQuantity < 1 ? 0 : +(+itemQuantity / +garmentPerHour || 0).toFixed(1);
                hoopingQuantity === 0.0 || hoopingQuantity === 0 ? setHoopingQuantity(0) : setHoopingQuantity(hoopingQuantity);
                hoopingQuantity === 0.0 || hoopingQuantity === 0 ? hoopingFeeObject.setup_quantity = 0 : hoopingFeeObject.setup_quantity = hoopingQuantity;
                hoopingFeeObject.total = (hoopingFeeObject.setup_quantity * hoopingFeeObject.setup_rate).toFixed(2);
                let indexToUpdate = setupMasterData.findIndex(obj => { return obj.setup_master_uuid === hoopingFeeObject.setup_master_uuid })
                if (indexToUpdate !== -1) {
                    setupMasterData[indexToUpdate] = hoopingFeeObject;
                }
                setSetUpMasterData(setupMasterData);
                setGarmentPerHour(garmentPerHour);
                return garmentPerHour;
            } else {
                setGarmentPerHour(0);
                setHoopingQuantity(0);
                let setupMasterData = getSetUpMasterData;
                let hoopingFeeObject = getSetUpMasterData?.find((setupobj) => { return setupobj.setup_label === masterTableLable.hoopingFee });
                if (hoopingFeeObject) {
                    hoopingFeeObject.total = 0 * hoopingFeeObject.setup_rate;
                    hoopingFeeObject.setup_quantity = 0;
                }
                let indexToUpdate = setupMasterData.findIndex(obj => { return obj?.setup_master_uuid === hoopingFeeObject.setup_master_uuid });
                if (indexToUpdate !== -1) {
                    setupMasterData[indexToUpdate] = hoopingFeeObject;
                }
                setSetUpMasterData(setupMasterData);
                return 0;
            }
        }
    }

    // get the value of garment perhour
    useEffect(() => {
        stitchCount !== 0 ? setGarmentPerHour(countGarmentsPerHourTechnicalSpecification(false)) : setGarmentPerHour(0);
    }, [!getMasterTableLoadingState.isTechnicleMasterListIsLoading, getRPM, getEmbroideryHeads, itemQuantity, stitchCount, getSetUpMasterData]);

    // calculate gross hourly rate and met hourly rate based on the value of hooping quantity
    useEffect(() => {
        +getHoopingQuantity === 0.0 || +getHoopingQuantity === 0 ? setfinalFigurescalculatorState(prevState => ({
            ...prevState,
            gross_hourly_rate: (0).toFixed(2),
            net_hourly_rate: (0).toFixed(2)
        })) : setfinalFigurescalculatorState(prevState => ({
            ...prevState,
            gross_hourly_rate: (prevState.gross_sale / getHoopingQuantity).toFixed(2),
            net_hourly_rate: (prevState.net_profit / getHoopingQuantity).toFixed(2)
        }));
    }, [getHoopingQuantity, itemQuantity]);

    const isDeleteHandle = () => {
        let responsedata = JSON.parse(localStorage.getItem('quote_items') || []);
        if (isEditMode) {
            let data = responsedata.filter((obj) => obj.quote_item_details.quote_item_uuid !== deletequoteItem.quote_item_details.quote_item_uuid);
            quoteCalculatorService.deleteQuoteItem(deletequoteItem.quote_item_details.quote_item_uuid).then(response => {
                if (response.status === 200) {
                    removeQuoteItem(data);
                }
            });
        } else {
            let data = responsedata.filter((obj) => obj.quote_item_details.id !== deletequoteItem.quote_item_details.id);
            removeQuoteItem(data);
        }
    }

    const removeQuoteItem = (data) => {
        localStorage.setItem('quote_items', JSON.stringify(data));
        setQuoteItems(data);
        if (data.length == 0) {
            localStorage.removeItem('quote_items');
        }
        setisDeleted(true);
    }

    // calculate hooping fee when quantity is changed
    const calculateHoopingFee = (quantity) => {
        if (getGarmentPerHour !== 0) {
            let hoopingQuantity = (quantity / getGarmentPerHour).toFixed(1);
            setHoopingQuantity(hoopingQuantity);
            let setupMasterData = getSetUpMasterData;
            let data = getSetUpMasterData?.find((data) => { return data.setup_label === masterTableLable.hoopingFee });
            data.setup_quantity = (quantity / getGarmentPerHour).toFixed(1);
            data.total = data.setup_quantity * data.setup_rate;
            let indexToUpdate = setupMasterData.findIndex(obj => { return obj.setup_master_uuid === data.setup_master_uuid });
            if (indexToUpdate !== -1) {
                setupMasterData[indexToUpdate] = data;
            }
            setSetUpMasterData(setupMasterData);
        }
    }

    // get garment master list 
    const getGarmentMasterListInfo = () => {
        setMasterTableLoadingState(prevState => ({
            ...prevState,
            isgarmentLoading: true
        }));
        // based on rate and quantity find total add key garment_quantity
        quoteCalculatorService.getGarmentMaterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                let updatedGarmentData = response.data.map((garmentData) => {
                    const garment_quantity = garmentData.garment_quantity || 0;
                    const garment_rate = +garmentData.garment_rate || 0;
                    const total = garment_quantity * garment_rate;
                    return {
                        ...garmentData,
                        garment_quantity,
                        total,
                    };
                });
                setGarmentsData(updatedGarmentData);
                setMasterTableLoadingState(prevState => ({
                    ...prevState,
                    isgarmentLoading: false
                }));
            }
        });
    }

    // get stitch count master list
    const getStitchCountListInfo = () => {
        setMasterTableLoadingState(prevState => ({
            ...prevState,
            isStitchLoading: true
        }));
        quoteCalculatorService.getStichCountMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                // store the response in state variable
                let updatedGarmentData = response.data.map((stitchData) => {
                    const quantity = 0;
                    const total = quantity * stitchData.stitch_count_rate;
                    return {
                        ...stitchData,
                        quantity,
                        total,
                    };
                });
                setStitchData(updatedGarmentData);
                // find the total of stitch count quantity
                const stitchCountQuantities = response.data.filter(item => item.stitch_count_label === masterTableLable.stitchCount).map(item => item.quantity || 0);
                const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                setStitchCount(totalStitchCountQuantity);
                setMasterTableLoadingState(prevState => ({
                    ...prevState,
                    isStitchLoading: false
                }));
            }
        });
    }

    // get setup master list
    const getSetUpMasterListInfo = () => {
        setMasterTableLoadingState(prevState => ({
            ...prevState,
            isSetUpIsLoading: true,
            isPricingMasterIsLoading: true
        }));
        quoteCalculatorService.getSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                // make a copy of response array and find the total of quantity and rate
                let updatedSetupMatserWithCalculation = response.data.map((setupItem) => {
                    const quantity = setupItem.setup_quantity || 0;
                    const rate = +setupItem.setup_rate || 0;
                    const total = quantity * rate;
                    return {
                        ...setupItem,
                        total,
                    };
                });
                // get the object location and store it in use state variable
                let locationsobj = updatedSetupMatserWithCalculation.find((setupInfo) => { return setupInfo.setup_label === masterTableLable.location });
                setlocationValue(locationsobj?.setup_quantity);
                // based on the value of location quantity find the rate of Hooping fee's rate and replace the value in array
                quoteCalculatorService.getPricingMatrixSpecificationMasterList().then((response) => {
                    if (response.status === 200) {
                        setPricingMasterList(response.data);
                        var matchingLocation = response.data.filter((matchingLocation) => { return matchingLocation?.no_of_locations === locationsobj?.setup_quantity });
                        let hoopingFeeObject = updatedSetupMatserWithCalculation.find((setupobj) => { return setupobj.setup_label === masterTableLable.hoopingFee });
                        if (hoopingFeeObject?.setup_rate) {
                            hoopingFeeObject.setup_rate = findRateforHoopingFee(itemQuantity, matchingLocation);
                        }
                        let indexToUpdate = updatedSetupMatserWithCalculation.findIndex(obj => { return obj.setup_master_uuid === hoopingFeeObject.setup_master_uuid });
                        if (indexToUpdate !== -1) {
                            updatedSetupMatserWithCalculation[indexToUpdate] = hoopingFeeObject;
                        }
                        setSetUpMasterData(updatedSetupMatserWithCalculation);
                        setMasterTableLoadingState(prevState => ({
                            ...prevState,
                            isSetUpIsLoading: false,
                            isPricingMasterIsLoading: false
                        }));
                    }
                })
                // find the value of Rpm and store it in state variable
                setRpm(response.data.find((setUp) => { return setUp.setup_label === masterTableLable.rpm })?.setup_quantity);
            }
        })
    }

    // find rate for hooping fee
    const findRateforHoopingFee = (targetGarments, matchingLocation) => {
        let closestObject = matchingLocation[0];
        for (const obj of matchingLocation) {
            if (Math.abs(obj.no_of_garments - targetGarments) < Math.abs(closestObject.no_of_garments - targetGarments)) {
                closestObject = obj;
            }
        }
        return closestObject?.rate;
    }

    // get technical specification master list 
    const getTechnicalSpecificationListInfo = () => {
        setMasterTableLoadingState(prevState => ({
            ...prevState,
            isTechnicleMasterListIsLoading: true
        }));
        quoteCalculatorService.getTechnicalSpecificationMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                let EmbroidoryHeads = response.data.find((technicalSpecification) => { return technicalSpecification.technical_specification_label === masterTableLable.embroidory_heads });
                let technicalMasterdata = response.data.filter((technical) => { return technical.technical_specification_label === masterTableLable.embroidory_heads });
                setTechnicalMatserData(technicalMasterdata);
                setOriginalTechnicalMasterData(response.data);
                setEmbroideryHeads(EmbroidoryHeads?.technical_specification_quantity);
                setMasterTableLoadingState(prevState => ({
                    ...prevState,
                    isTechnicleMasterListIsLoading: false
                }));
            }
        });
    }

    // for handle switch toggle for liniear view
    const handleToggleSwitch = (check) => {
        setIsLinearView(check);
    }

    const getGarmentPrintingMasterInfo = () => {
        setScreenPrintingMasterTableLoadingStatus(prevState => ({
            ...prevState,
            isgarmentLoading: true
        }));
        quoteCalculatorService.getPrintingGarmentMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                let updatedGarmentData = response.data.map((garmentData) => {
                    const garment_quantity = garmentData.garment_quantity || 0;
                    const garment_rate = +garmentData.garment_rate || 0;
                    const total = parseFloat(garment_quantity * garment_rate).toFixed(2);
                    return {
                        ...garmentData,
                        garment_quantity,
                        total,
                    };
                });
                setPrintingGarmentsData(updatedGarmentData);
                setScreenPrintingMasterTableLoadingStatus(prevState => ({
                    ...prevState,
                    isgarmentLoading: false
                }));
            }
        }).catch((error) => {
            setScreenPrintingMasterTableLoadingStatus(prevState => ({
                ...prevState,
                isgarmentLoading: false
            }));
        });
    }

    const getArtPrintingMasterInfo = () => {
        setScreenPrintingMasterTableLoadingStatus(prevState => ({
            ...prevState,
            isArtTableLoading: true
        }));
        quoteCalculatorService.getPrintingArtCountData(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                let updatedGarmentData = response.data.map((garmentData) => {
                    const art_quantity = garmentData.art_quantity || 0;
                    const art_rate = +garmentData.art_rate || 0;
                    const total = parseFloat(art_quantity * art_rate).toFixed(2);
                    return {
                        ...garmentData,
                        art_quantity,
                        total,
                    };
                });
                setPrintingArtCountData(updatedGarmentData)
                setScreenPrintingMasterTableLoadingStatus(prevState => ({
                    ...prevState,
                    isArtTableLoading: false
                }));
            }
        }).catch((error) => {
            setScreenPrintingMasterTableLoadingStatus(prevState => ({
                ...prevState,
                isArtTableLoading: false
            }));
        });
    }

    const getPrintingInkMasterInfo = () => {
        setScreenPrintingMasterTableLoadingStatus(prevState => ({
            ...prevState,
            isInkLoading: true
        }));
        quoteCalculatorService.getPrintingInkCountMatserList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                // store the response in state variable
                let updatedGarmentData = response.data.map((stitchData) => {
                    const quantity = 0;
                    const total = quantity * +stitchData.ink_rate;
                    const ink_rate = +stitchData.ink_rate || 0
                    return {
                        ...stitchData,
                        quantity,
                        total,
                        ink_rate
                    };
                });
                setPrintingInkCountData(updatedGarmentData);
                // find the total of stitch count quantity
                setScreenPrintingMasterTableLoadingStatus(prevState => ({
                    ...prevState,
                    isInkLoading: false
                }));
            }
        }).catch((error) => {
            setScreenPrintingMasterTableLoadingStatus(prevState => ({
                ...prevState,
                isInkLoading: false
            }));
        });
    }

    const getSetUpPrintingMasterInfo = () => {
        setScreenPrintingMasterTableLoadingStatus(prevState => ({
            ...prevState,
            isSetupLoading: true
        }));
        quoteCalculatorService.getPrintingSetUpMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                let updatedSetupMasterData = response.data.map((setupData) => {
                    const setup_quantity = setupData.setup_quantity || 0;
                    const setup_rate = +setupData.setup_rate || 0;
                    const total = setup_quantity * setup_rate;
                    return {
                        ...setupData,
                        setup_quantity,
                        setup_rate,
                        total,
                    };
                });
                setPrintingSetupData(updatedSetupMasterData);
                setScreenPrintingMasterTableLoadingStatus(prevState => ({
                    ...prevState,
                    isSetupLoading: false
                }));
            }
        }).catch((error) => {
            setScreenPrintingMasterTableLoadingStatus(prevState => ({
                ...prevState,
                isSetupLoading: false
            }));
        });
    }

    const getJobCriteriaListInfo = () => {
        setScreenPrintingMasterTableLoadingStatus(prevState => ({
            ...prevState,
            isJobCriteriaLoading: true
        }));
        quoteCalculatorService.getPrintingJobCriteriaMasterList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                setPrintingJobCriteria(response.data);
                setScreenPrintingMasterTableLoadingStatus(prevState => ({
                    ...prevState,
                    isJobCriteriaLoading: false
                }));
            }
        }).catch((error) => {
            setScreenPrintingMasterTableLoadingStatus(prevState => ({
                ...prevState,
                isJobCriteriaLoading: false
            }));
        });
    }

    // for handle tabview check
    const handleActiveTabView = (activeTab) => {
        setActiveTabView(activeTab);
        if (isLinearView) {
            var body = document.body;
            if (isExtraLargeDevice || isSmallLaptopDevice || isLargeDevice) {
                if (isLinearView) {
                    body.classList.add("vertical-collpsed");
                }
            }
        }
        setshouldRefreshSelectedTaxInfo(true);
        setTimeout(() => {
            setshouldRefreshSelectedTaxInfo(false);
        }, 1000);
        resetQuoteCalculator(activeTab);
    }

    useEffect(() => {
        setshouldRefreshSelectedTaxInfo(false);
        if (isLinearView) {
            document.body.classList.add("vertical-collpsed");
        }
    }, [isLinearView]);

    // call the master table API and other required stuff when web page is initialized
    useState(() => {
        // for get quote client list
        quoteCalculatorService.getCustomerQuoteClientList(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
            if (response.status === 200) {
                setQuoteClientList(response.data);
            }
        });
        // based on screensize set view of quote calculator
        window.innerWidth >= 601 ? setIsLinearView(true) : setIsLinearView(false);
        localStorage.removeItem('quote_items');
        if (state?.updatesessionStorage === true) {
            customerService.getSubscriptionplanDetailByUserId(JSON.parse(sessionStorage.getItem('userLogin')).data.user_uuid).then((response) => {
                if (response.status === 200) {
                    let userLogin = JSON.parse(sessionStorage.getItem('userLogin'));
                    userLogin.data.subscription_details = response.data;
                    sessionStorage.setItem('userLogin', JSON.stringify(userLogin));
                }
            });
        }
    }, []);

    // disallow user to enter any other key rather then only number
    const onKeyDownAllowUserToAddOnlyNumber = (e) => {
        if (
            (!/^\d$/.test(e.key) && !/^[a-zA-Z]$/.test(e.key)) &&
            e.key !== 'Backspace' &&
            e.key !== 'Delete' &&
            e.key !== 'ArrowLeft' &&
            e.key !== 'ArrowRight' &&
            e.key !== 'ArrowUp' &&
            e.key !== 'ArrowDown' &&
            e.key !== 'Tab' &&
            e.target.value === '-'
        ) {
            e.preventDefault();
        }
    }

    // for calculating final figures
    useEffect(() => {
        if (activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            if (getPrintingGarmentData && getPrintingInkCountData && (itemQuantity !== 0 && itemQuantity !== undefined)) {
                let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
                let cost_per_item = ((+garmentTotal + +inkCountDataTotal) / +itemQuantity);
                let gross_cost = (cost_per_item * itemQuantity);
                let gross_sale = (+quotePrintingSalesPrice * itemQuantity);
                let net_profit = (gross_sale - gross_cost);
                let profit_margin = gross_sale === 0 ? 0 : ((gross_sale - gross_cost) / gross_sale) * 100;
                setfinalFigurescalculatorState(prevState => ({
                    ...prevState,
                    cost_per_item: (cost_per_item).toFixed(2),
                    gross_cost: (gross_cost).toFixed(2),
                    gross_sale: (+gross_sale).toFixed(2),
                    net_profit: (net_profit).toFixed(2),
                    gross_hourly_rate: 0,
                    net_hourly_rate: 0,
                    profit_margin_percentage: (profit_margin).toFixed(2)
                }))
            } else {
                resetFinalFigureCalculatorState();
            }
        } else if (activeTabview !== CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            if (getGarmentsData && getStitchCountData && getSetUpMasterData && (itemQuantity !== 0 && itemQuantity !== undefined)) {
                let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                const desiredSetUpLabel = ["Art Creation", "Digitizing Fee"];
                let ArtCollectionAndDigitizingFee = getSetUpMasterData.filter(obj => desiredSetUpLabel.includes(obj.setup_label));
                let ArtCollectionAndDigitizingFeeTotal = ArtCollectionAndDigitizingFee.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                let cost_per_item = ((+garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal) / +itemQuantity);
                let gross_cost = (cost_per_item * itemQuantity);
                let gross_sale = (+quoteSalesPrice * itemQuantity);
                let net_profit = (gross_sale - gross_cost);
                setfinalFigurescalculatorState(prevState => ({
                    ...prevState,
                    cost_per_item: (cost_per_item).toFixed(2),
                    gross_cost: (gross_cost).toFixed(2),
                    gross_sale: (+gross_sale).toFixed(2),
                    net_profit: (net_profit).toFixed(2),
                    gross_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (+gross_sale / getHoopingQuantity).toFixed(2),
                    net_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (net_profit / getHoopingQuantity).toFixed(2),
                    profit_margin_percentage: 0
                }));
            } else {
                resetFinalFigureCalculatorState();
            }
        }
    }, [getGarmentsData, getStitchCountData, getSetUpMasterData, itemQuantity, activeTabview, getPrintingGarmentData, getPrintingInkCountData]);

    // for calculating quote sales percentage
    useEffect(() => {
        if (activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            if (getPrintingGarmentData && getPrintingInkCountData && getPrintingSetUpData && itemQuantity !== 0 && itemQuantity !== undefined && quotePrintingSalesPrice) {
                let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
                let setupTotalData = getPrintingSetUpData.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0);
                let masterTableTotal = (+garmentTotal + +inkCountDataTotal + +setupTotalData).toFixed(2);
                let finalPrice = 1 - ((masterTableTotal / itemQuantity) / quotePrintingSalesPrice);
                setQuoteSalesPercentage((finalPrice * 100).toFixed(1));
            } else {
                setQuoteSalesPercentage(0);
            }
        } else if (activeTabview !== CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            if (getGarmentsData && getStitchCountData && getSetUpMasterData && itemQuantity !== 0 && itemQuantity !== undefined && quoteSalesPrice !== 0) {
                let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0);
                let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0);
                let ArtCollectionAndDigitizingFeeTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                let masterTableTotal = (+garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal);
                let finalPrice = 1 - ((masterTableTotal / itemQuantity) / quoteSalesPrice);
                setQuoteSalesPercentage((finalPrice * 100).toFixed(1));
            } else {
                setQuoteSalesPercentage(0);
            }
        }
    }, [getGarmentsData, getStitchCountData, getSetUpMasterData, itemQuantity, quoteSalesPrice, activeTabview, quotePrintingSalesPrice, getPrintingGarmentData, getPrintingInkCountData, getPrintingSetUpData]);

    // calculate markuptable total cost index
    useEffect(() => {
        if (getGarmentsData && getStitchCountData && getSetUpMasterData && getMarkUpData) {
            let markUpData = getMarkUpData;
            let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0);
            let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0);
            let setupmasterTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
            let totalGrandTotal = (+garmentTotal + +stitchCountTotal + +setupmasterTotal).toFixed(2);

            markUpData.forEach(item => {
                let markupInPer = item.markup / 100;
                let newTotalCost = (totalGrandTotal / (1 - markupInPer));
                item.markup = item.markup;
                item.totalCost = +newTotalCost.toFixed(2);
                item.pricePerShirt = itemQuantity === 0 ? 0 : +(+newTotalCost.toFixed(2) / itemQuantity).toFixed(2);
            });
            setMarkupData(markUpData);
            setMarkUpKey(prevKey => prevKey + 1);
        }
    }, [getGarmentsData, getStitchCountData, getSetUpMasterData, getEmbroideryHeads]);


    useEffect(() => {
        if (getPrintingGarmentData && getPrintingInkCountData && getPrintingSetUpData && getPrintingMarkupData) {
            let markUpData = getPrintingMarkupData;
            let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
            let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
            let setupTotalData = getPrintingSetUpData.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0);
            let artTotal = getPrintingArtCountData.reduce((sum, art) => sum + (parseFloat(art.total) || 0), 0);
            let totalGrandTotal = (+garmentTotal + +inkCountDataTotal + +setupTotalData + +artTotal).toFixed(2);
            markUpData.forEach(item => {
                let markupInPer = item.markup / 100;
                let newTotalCost = (+totalGrandTotal / (1 - markupInPer));
                item.markup = item.markup;
                item.totalCost = +newTotalCost.toFixed(2);
                item.pricePerShirt = itemQuantity === 0 || itemQuantity === undefined ? 0 : +(+newTotalCost.toFixed(2) / itemQuantity).toFixed(2);
            });
            setPrintingMarkupData(markUpData);
            setPrintingMarkupKey(prev => prev + 1);
        }
    }, [getPrintingGarmentData, getPrintingInkCountData, getPrintingSetUpData, getPrintingArtCountData])

    // for finding quote sales percentage when value of embroidory heads changes
    useEffect(() => {
        if (getGarmentsData && getStitchCountData && getSetUpMasterData) {
            let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0);
            let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0);
            let setupmasterTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
            let masterTableTotal = (+garmentTotal + +stitchCountTotal + +setupmasterTotal);
            let finalPrice = 1 - ((masterTableTotal / itemQuantity) / quoteSalesPrice);
            finalPrice === Infinity || finalPrice === -Infinity ? setQuoteSalesPercentage(0) : setQuoteSalesPercentage((finalPrice * 100).toFixed(1));
        }
    }, [getEmbroideryHeads])

    // when quantity change find the rate of Hooping fee
    const rateOfHoopingFee = (pricingArray, quantity) => {
        for (let i = 0; i < pricingArray.length; i++) {
            const pricingObject = pricingArray[i];
            if (
                (quantity <= 24 && pricingObject.no_of_garments <= 24) ||
                (quantity > 24 && quantity <= 49 && pricingObject.no_of_garments === 49) ||
                (quantity > 49 && quantity <= 99 && pricingObject.no_of_garments === 99) ||
                (quantity > 99 && quantity <= 199 && pricingObject.no_of_garments === 199) ||
                (quantity > 199 && quantity <= 499 && pricingObject.no_of_garments === 499) ||
                (quantity > 499 && quantity <= 999 && pricingObject.no_of_garments === 999) ||
                (quantity > 999 && quantity >= 1000 && pricingObject.no_of_garments === 1000)
            ) {
                return pricingObject.rate;
            }
        }
        return null;
    }
    const [addedItem, setAddedItem] = useState(0);
    // onclick add to invoice 
    const onClickAddItemtoQuote = () => {
        // scroll to top
        setTimeout(() => {
            document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 100);
        setshouldRefreshSelectedTaxInfo(true);
        setTimeout(() => {
            setshouldRefreshSelectedTaxInfo(false);
        }, 1000);
        // for the garment master table remove total key and convert other key's value into number
        const updatedGarmentData = getGarmentsData.map(({ total, ...rest }) => ({
            ...rest,
            garment_rate: +rest.garment_rate,
            garment_quantity: +rest?.garment_quantity
        }));
        // for the stitch count data remmove total key replace quantity with stitch_count , if quantity does not exist then value will be 0 else convert to number
        const updatedStitchCountData = getStitchCountData.map(({ total, quantity, ...rest }) => ({
            ...rest,
            stitch_count: quantity !== undefined ? +quantity : 0,
            stitch_count_rate: +rest?.stitch_count_rate
        }));
        // for the setup master table remove total obeject and convert other key's value into number
        const updatedSetUpMasterData = getSetUpMasterData.map(({ total, ...rest }) => ({
            ...rest,
            setup_rate: +rest.setup_rate,
            setup_quantity: +rest.setup_quantity
        }));

        const updatedPrintingGarmentData = getPrintingGarmentData.map(({ total, screenprinting_garment_master_uuid, ...rest }) => ({
            ...rest,
            garment_rate: +rest?.garment_rate,
            garment_quantity: +rest?.garment_quantity,
            garment_master_uuid: screenprinting_garment_master_uuid
        }));

        const updatedPrintingInkData = getPrintingInkCountData.map(({ total, screenprinting_ink_master_uuid, ...rest }) => ({
            ...rest,
            ink_quantity: +rest.quantity,
            ink_master_uuid: screenprinting_ink_master_uuid
        }));

        const updatedPrintingSetupData = getPrintingSetUpData.map(({ total, screenprinting_setup_master_uuid, ...rest }) => ({
            ...rest,
            setup_master_uuid: screenprinting_setup_master_uuid
        }))

        const updatedPrintingJobCriteariaData = getPrintingJobCriteriaData.map(({ screenprinting_job_criteria_master_uuid, ...rest }) => ({
            ...rest,
            job_criteria_master_uuid: screenprinting_job_criteria_master_uuid
        }));

        const updatedPrintingArtmasterData = getPrintingArtCountData.map(({ total, screenprinting_art_master_uuid, ...rest }) => ({
            ...rest,
            art_master_uuid: screenprinting_art_master_uuid,
            art_quantity: +rest.art_quantity,
            art_rate: +rest.art_rate
        }))

        // for the technical master table replace the quanity into actual quantity 
        getoriginalTechnicalMasterData.forEach(obj => obj.technical_specification_quantity = obj.technical_specification_label === 'Garments / Hour' ? +getGarmentPerHour : (obj.technical_specification_label === masterTableLable.embroidory_heads ? +getEmbroideryHeads : obj.technical_specification_quantity));
        // for the markup master table replace the key name of the object
        const updatedMarkupData = activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR
            ? getPrintingMarkupData.map(({ markup, pricePerShirt, totalCost, ...rest }) => ({
                ...rest,
                markup_percentage: markup,
                price_per_item: pricePerShirt,
                total_cost: totalCost
            }))
            : getMarkUpData.map(({ markup, pricePerShirt, totalCost, ...rest }) => ({
                ...rest,
                markup_percentage: markup,
                price_per_item: pricePerShirt,
                total_cost: totalCost
            }));
        // make a copy of the final figures and replace it with desired quote_item_details value 
        const quote_item_details = finalFigurescalculatorState;
        quote_item_details.item_name = getItemName;
        quote_item_details.item_description = getItemDescription;
        quote_item_details.item_quantity = itemQuantity;
        quote_item_details.cost_per_item = +finalFigurescalculatorState.cost_per_item;
        quote_item_details.gross_cost = +finalFigurescalculatorState.gross_cost;
        quote_item_details.gross_hourly_rate = activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? 0 : +finalFigurescalculatorState.gross_hourly_rate;
        quote_item_details.gross_sale = +finalFigurescalculatorState.gross_sale;
        quote_item_details.net_hourly_rate = activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? 0 : +finalFigurescalculatorState.net_hourly_rate;
        quote_item_details.net_profit = +finalFigurescalculatorState.net_profit;
        quote_item_details.quoted_sales_price = activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? quotePrintingSalesPrice : quoteSalesPrice;
        quote_item_details.quoted_sales_percentage = +quoteSalesPercentage;
        quote_item_details.quote_item_type = activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? 'Screenprinting' : 'Embroidery';
        quote_item_details.profit_margin_percentage = +finalFigurescalculatorState.profit_margin_percentage;
        if (!isEditMode) {
            setAddedItem(prev => prev + 1);
            quote_item_details.id = addedItem;
        }
        // first find if the object exist or not based on it create or store the value
        let storedFinalQuoteArrayJSON = localStorage.getItem('quote_items');
        let storedFinalQuoteArray = storedFinalQuoteArrayJSON ? JSON.parse(storedFinalQuoteArrayJSON) : [];
        const commonQuoteItemDetails = {
            quote_item_garments_details: activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? updatedPrintingGarmentData : updatedGarmentData,
            quote_item_markup_details: updatedMarkupData,
            quote_item_details: quote_item_details,
            quote_item_tax_details: getSelectedTaxInfo
        };
        if (activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            storedFinalQuoteArray.push({
                ...commonQuoteItemDetails,
                quote_item_inks_details: updatedPrintingInkData,
                quote_item_setup_details: updatedPrintingSetupData,
                quote_item_job_criteria_details: updatedPrintingJobCriteariaData,
                quote_item_art_details: updatedPrintingArtmasterData
            });
        } else if (activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) {
            storedFinalQuoteArray.push({
                ...commonQuoteItemDetails,
                quote_item_stitch_counts_details: updatedStitchCountData,
                quote_item_setup_details: updatedSetUpMasterData,
                quote_item_technical_specification_details: getoriginalTechnicalMasterData
            });
        }
        setQuoteItems(storedFinalQuoteArray);
        localStorage.setItem('quote_items', JSON.stringify(storedFinalQuoteArray));
        selectedTaxInfo([]);
        // once invoice is stored change it to orignal values
        resetQuoteCalculator(activeTabview);
        if (isEditMode) {
            setLoading(true);
            let quoteDetail = {
                "quote_uuid": state.quote_uuid,
                "quote_item": {
                    "quote_item_garments_details": activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? updatedPrintingGarmentData : updatedGarmentData,
                    "quote_item_setup_details": activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? updatedPrintingSetupData : updatedSetUpMasterData,
                    "quote_item_markup_details": updatedMarkupData,
                    "quote_item_details": quote_item_details,
                    "quote_item_tax_details": getSelectedTaxInfo.map(taxInfo => {
                        return { customer_shop_tax_master_uuid: taxInfo.customer_shop_tax_master_uuid };
                    }),
                    ...(activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? {
                        "quote_item_inks_details": updatedPrintingInkData,
                        "quote_item_job_criteria_details": updatedPrintingJobCriteariaData,
                        "quote_item_art_details": updatedPrintingArtmasterData
                    } : {
                        "quote_item_stitch_counts_details": updatedStitchCountData,
                        "quote_item_technical_specification_details": getoriginalTechnicalMasterData,
                    }),
                }
            }
            quoteCalculatorService.saveItemToQuote(quoteDetail).then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    let quote_item_uuid = response.data.quote_item_uuid;
                    let lastObject = storedFinalQuoteArray[storedFinalQuoteArray.length - 1];
                    lastObject.quote_item_details.quote_item_uuid = quote_item_uuid;
                    storedFinalQuoteArray[storedFinalQuoteArray.length - 1] = lastObject;
                    localStorage.setItem('quote_items', JSON.stringify(storedFinalQuoteArray));
                    toast.success(QUOTE_CALCULATOR.ITEM_ADDED_TO_SAVE_QUOTE.replace(/{job_name}/, getJobName), { autoClose: 4000 });
                    setEditQuoteAddedItemUuid(quote_item_uuid);
                    setItemDescription('');
                }
            })
        } else {
            toast.success(QUOTE_CALCULATOR.ITEM_ADDED_TO_QUOTE, { autoClose: 4000 });
            setItemDescription('');
        }
    }

    const getQuoteDetails = () => {
        quoteService.getQuoteDetailsByQuoteId(state.quote_uuid).then((response) => {
            setJobName(response.data.job_name);
            localStorage.setItem('quote_items', JSON.stringify(response.data.quote_items));
            const storedItems = localStorage.getItem("quote_items");
            if (storedItems) {
                setQuoteItems(JSON.parse(storedItems));
            }
        });
    }

    useState(() => {
        if (isEditMode) {
            getQuoteDetails();
            let customerInfo = JSON.parse(sessionStorage.getItem('userLogin'));
            customerService.getCustomerDetail(customerInfo?.data.user_uuid).then(response => {
                set_customer_shop_tax_master_uuid(response.data[0].customer_shop_info_uuid);
                quoteService.getTaxInfo(response.data[0].customer_shop_info_uuid).then(response => {
                    setTaxInfo(response.data);
                })
            });
        } else {
            let customerInfo = JSON.parse(sessionStorage.getItem('userLogin'));
            customerService.getCustomerDetail(customerInfo?.data.user_uuid).then(response => {
                if (response.status === 200) {
                    let shopOwnerInfo = response.data[0];
                    setQuoteNotes(RichTextEditor.createValueFromString(shopOwnerInfo.quote_notes === null || shopOwnerInfo.quote_notes === "" || shopOwnerInfo.quote_notes === "<p><br></p>" || shopOwnerInfo.quote_notes === "null" ? '' : shopOwnerInfo.quote_notes, 'html'));
                    set_customer_shop_tax_master_uuid(shopOwnerInfo.customer_shop_info_uuid);
                    quoteService.getTaxInfo(shopOwnerInfo.customer_shop_info_uuid).then(response => {
                        if (response.status === 200) {
                            setTaxInfo(response.data);
                        }
                    });
                }
            });
        }
    }, [isEditMode]);

    const resetQuoteCalculator = (activetab) => {
        if (activetab === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) {
            setItemDescription('');
            getGarmentPrintingMasterInfo();
            getArtPrintingMasterInfo();
            getPrintingInkMasterInfo();
            getSetUpPrintingMasterInfo();
            getJobCriteriaListInfo();
            setQuotePrintingSalesPrice(0);
        } else if (activetab === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) {
            getGarmentMasterListInfo();
            getStitchCountListInfo();
            getSetUpMasterListInfo();
            getTechnicalSpecificationListInfo();
            countGarmentsPerHourTechnicalSpecification();
            setEmbroideryHeads(1);
            setQuoteSalesPrice(0);
        }
        setQuoteSalesPercentage(0);
        setItemName('');
        setitemQuantity(undefined);
        resetFinalFigureCalculatorState();
    }
    // for resetting final figure state
    const resetFinalFigureCalculatorState = () => {
        setfinalFigurescalculatorState(prevState => ({
            ...prevState,
            cost_per_item: '0.00',
            gross_cost: '0.00',
            gross_sale: '0.00',
            net_profit: '0.00',
            gross_hourly_rate: '0.00',
            net_hourly_rate: '0.00',
            profit_margin_percentage: '0.00'
        }));
    }
    // display confirmation poup  in add mode if user nevigate to other page without saving quote to other page
    useEffect(() => {
        if (isEditMode === false) {
            if (JSON.parse(localStorage.getItem('quote_items')) !== null) {
                setTimeout(() => { JSON.parse(localStorage.getItem('quote_items')) && JSON.parse(localStorage.getItem('quote_items')).length > 0 ? setConfirmationModal(true) : setConfirmationModal(false); }, 100);
            } else {
                setConfirmationModal(false);
            }
        }
    }, [localStorage.getItem('quote_items')]);

    // display confirmation popup in edit mode if user nevugate to  other page without saving item
    useEffect(() => {
        if (isEditMode) {
            if (JSON.parse(localStorage.getItem('quote_items')) && JSON.parse(localStorage.getItem('quote_items')).length === 0) {
                setConfirmationModal(true);
            } else {
                (getItemName !== '' || itemQuantity !== undefined || quoteSalesPrice !== 0) ? setConfirmationModal(true) : setConfirmationModal(false);
            }
            if (JSON.parse(localStorage.getItem('quote_items')) === null) {
                setConfirmationModal(true);
            }
        }
    }, [getItemName, itemQuantity, quoteSalesPrice, localStorage.getItem('quote_items')]);

    // for sidebar collpse when linear view in desktop devices 
    useEffect(() => {
        var body = document.body;
        if (isExtraLargeDevice || isSmallLaptopDevice || isLargeDevice) {
            if (isLinearView) {
                body.classList.add("vertical-collpsed");
            }
        }
    }, [isLinearView]);

    // for quick tour
    const introRef = useRef(null);

    const startQuickTourService = () => {
        setIsQuickTour(true);
        const intro = IntroJs();
        intro.setOptions({
            hidePrev: true,
            hideNext: true,
            nextToDone: true,
            showStepNumbers: true,
            showBullets: false,
            scrollToElement: true,
            steps: [
                {
                    element: '#step1',
                    position: 'auto',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.embroidoryQuoteCalc : calculatorQuickTourSteps.screenPrintingQuoteCalc,
                    tooltipClass: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING || activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR ? 'step-righ-left' : 'step-left-0'
                },
                {
                    element: '#step2',
                    intro: calculatorQuickTourSteps.step2,
                    position: 'auto'
                },
                {
                    element: '#step3',
                    intro: calculatorQuickTourSteps.step3,
                    position: 'auto',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#description',
                    intro: calculatorQuickTourSteps.description,
                    position: 'auto',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#step4',
                    intro: calculatorQuickTourSteps.step4,
                    position: 'auto',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#step5',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step5 : calculatorQuickTourSteps.garmentPrintingTable,
                    position: 'auto',
                },
                {
                    element: '#step6',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step6 : calculatorQuickTourSteps.inkPrintingTable,
                    position: 'auto',
                    tooltipClass: 'min-width-325'
                },
                {
                    element: '#step7',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step7 : calculatorQuickTourSteps.setupPrintingTable,
                    position: 'auto',
                    tooltipClass: (loggedinUserPlan !== SUBSCRIPTION_CALC_TYPE.EMBROIDERY && activeTabview !== CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) ? 'setup-table-into' : '',
                },
                {
                    element: '#step8',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step8 : calculatorQuickTourSteps.jobCriteriaPrintingTable,
                    position: 'auto'
                },
                {
                    element: '#step9',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step9 : calculatorQuickTourSteps.markupPrintingTable,
                    position: 'top'
                },
                {
                    element: '#step10',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step10 : calculatorQuickTourSteps.salesPricePrintingTable,
                    position: 'top'
                },
                {
                    element: '#step11',
                    intro: calculatorQuickTourSteps.step11,
                    position: 'top',
                    tooltipClass: 'step-left-0'
                },
                {
                    element: '#step12',
                    intro: calculatorQuickTourSteps.step12,
                    position: 'bottom',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#step13',
                    intro: calculatorQuickTourSteps.step13,
                    position: 'bottom',
                    tooltipClass: 'step-left-0'
                },
                {
                    element: '#step14',
                    intro: calculatorQuickTourSteps.step14,
                    position: 'bottom',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#step15',
                    intro: loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY || activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR ? calculatorQuickTourSteps.step15 : 'Calculation job profit margin',
                    position: 'bottom',
                    tooltipClass: 'step-left-0'
                },
                {
                    element: '#step16',
                    intro: calculatorQuickTourSteps.step16,
                    position: 'bottom',
                    tooltipClass: 'step-right-0'
                },
                {
                    element: '#step17',
                    intro: calculatorQuickTourSteps.step17,
                    position: 'auto',
                    highlightClass: ''
                },
                {
                    element: '#step18',
                    intro: calculatorQuickTourSteps.step18,
                    position: 'auto'
                },
                {
                    element: '#step19',
                    intro: calculatorQuickTourSteps.step19,
                    position: 'auto'
                }
            ]
        });
        if (JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details !== null && (JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN || JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN || JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN)) {
            intro['_options'].steps.splice(17);
            intro['_options'].steps.push({
                element: '#resetCalculator',
                intro: calculatorQuickTourSteps.resetCalculator,
                position: 'auto'
            });
        } else {
            if (localStorage.getItem('quote_items') == null) {
                intro['_options'].steps.splice(18, 1);
            }
            if (isEditMode === true) {
                intro['_options'].steps.splice(19, 1);
            }
        }
        if (activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR || loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) {
            const index = intro['_options'].steps.findIndex((obj) => obj.element === '#step16');
            if (index !== -1) {
                intro['_options'].steps.splice(index, 1);
            }
            intro['_options'].steps.splice(9, 0, {
                element: '#artTable',
                intro: calculatorQuickTourSteps.artTable,
                position: 'auto',
            });
        }
        intro.onexit(() => {
            var element = document.getElementsByClassName("quote-calculator");
            element[0].classList.remove('remove-scroll');
        });
        introRef.current = intro;
        addBodyClass();
        removeBodyClass();
        intro.start();
        //  remove scroll for statistics card
        intro.onchange((targetElement) => {
            setTimeout(() => {
                if (document.querySelector('.setup-table-into')) {
                    document.querySelector('.setup-table-into').scrollTop = 0;
                }
            }, 500);
            var element = document.getElementsByClassName("quote-calculator");
            const isRemoveScrollStep = (activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR || loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING) ? [12, 13, 14, 15, 16, 17].includes(intro._currentStep) : [11, 12, 13, 14, 15, 16, 17].includes(intro._currentStep);
            if (isRemoveScrollStep || (localStorage.getItem('quote_items') !== null && intro._currentStep === 18)) {
                element[0].classList.add('remove-scroll');
            } else {
                element[0].classList.remove('remove-scroll');
            }
        });
    }

    // close sidebar when quick tour start in linar view for large device
    const addBodyClass = () => {
        if (isLinearView && isLargeDevice) {
            document.body.classList.add("vertical-collpsed");
        }
    };

    // close sidebar when quick tour start in linar view for mobile and tablet device
    const removeBodyClass = () => {
        if (!isLargeDevice) {
            document.body.classList.remove("sidebar-enable");
        }
    };

    // for item image in quote list
    const handleImageChange = (e, rowIndex, quoteItemUuid) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const updatedImages = [...selectedImages];
                updatedImages[rowIndex] = e.target.result;
                setSelectedImages(updatedImages);
                if (!isEditMode) {
                    handleAddImage(updatedImages);
                } else {
                    const body = {
                        "quote_item_uuid": quoteItemUuid ? quoteItemUuid : editQuoteAddedItemUuid,
                        "item_image_base64": e.target.result // base64 url
                    }
                    quoteCalculatorService.addItemImg(body).then(response => {
                    });
                }
                // Clear the error for the current index
                const updatedErrorArr = [...imgTypeErrorArr];
                updatedErrorArr[rowIndex] = null;
                setImgTypeErrorArr(updatedErrorArr);
            };
            reader.readAsDataURL(file);
        } else {
            // Set the error message for the current index
            const updatedErrorArr = [...imgTypeErrorArr];
            updatedErrorArr[rowIndex] = 'Please select a valid image file.';
            setImgTypeErrorArr(updatedErrorArr);
        }
    };

    // for add image while add quote
    const handleAddImage = (imageArr) => {
        const quoteItem = JSON.parse(localStorage.getItem('quote_items'));
        imageArr.map((obj, index) => {
            quoteItem[index].quote_item_details.item_image_base64 = obj;
        });
        const updatedArrayString = JSON.stringify(quoteItem);
        localStorage.setItem('quote_items', updatedArrayString);
    }

    // for remove image
    const handleDeleteImage = (rowIndex, quoteItemUuid = null) => {
        const updatedImages = [...selectedImages];
        updatedImages[rowIndex] = null;
        setSelectedImages(updatedImages);
        if (!isEditMode) {
            delete quoteItems[rowIndex].quote_item_details.item_image_base64;
        } else {
            quoteItems[rowIndex].quote_item_details.item_image_url = null;
        }
        const updatedArrayString = JSON.stringify(quoteItems);
        localStorage.setItem('quote_items', updatedArrayString);
        if (isEditMode && quoteItemUuid) {
            const body = {
                "quote_item_uuid": quoteItemUuid
            }
            quoteCalculatorService.deleteItemImage(body).then(response => {
            });
        }
    };

    const updatedTaxInfo = (newTaxInfo) => {
        setTaxInfo(newTaxInfo);
    }

    const selectedTaxInfo = (selectedTaxInfo) => {
        setSelectedTaxInfo(selectedTaxInfo);
    }

    // for preview image
    const handlePreviewImage = (imageSrc) => {
        setPreviewImage(imageSrc);
    };

    const getConfirmationDescription = () => {
        if (!isEditMode) {
            if (JSON.parse(localStorage.getItem('quote_items')) !== null) {
                return QUOTE_CALCULATOR.NAVIGATE_AWAY_ADD_MODE;
            } else if (JSON.parse(localStorage.getItem('quote_items')) === null) {
                return QUOTE_CALCULATOR.NAVIGATE_AWAY_WITH_NO_ITEM_IN_QUOTE;
            }
        } else {
            if (JSON.parse(localStorage.getItem('quote_items')) && JSON.parse(localStorage.getItem('quote_items')).length === 0) {
                return QUOTE_CALCULATOR.NAVIGATE_AWAY_WITH_NO_ITEM_IN_QUOTE;
            } else {
                if ((getItemName !== '' || itemQuantity !== undefined) && JSON.parse(localStorage.getItem('quote_items')) && JSON.parse(localStorage.getItem('quote_items')).length === 0) {
                    return QUOTE_CALCULATOR.NAVIGATE_AWAY_EDIT_MODE
                } else if ((JSON.parse(localStorage.getItem('quote_items')) === null || JSON.parse(localStorage.getItem('quote_items')) && JSON.parse(localStorage.getItem('quote_items')).length === 0) && ((getItemName === '' || itemQuantity === undefined))) {
                    return QUOTE_CALCULATOR.NAVIGATE_AWAY_WITH_NO_ITEM_IN_QUOTE
                } else {
                    return QUOTE_CALCULATOR.NAVIGATE_AWAY_EDIT_MODE
                }
            }
        }
        if (JSON.parse(localStorage.getItem('quote_items')) === null && isEditMode) {
            return QUOTE_CALCULATOR.NAVIGATE_AWAY_WITH_NO_ITEM_IN_QUOTE;
        }
    }

    const laborPerHourQuantity = (itemQuantity, location, shirtPerHour, setFieldValue) => {
        let shirtInJOB = parseFloat(+itemQuantity * +location).toFixed(2);
        let laborPerHour = (+shirtInJOB / +shirtPerHour);

        // Check if laborPerHour is not a finite number (Infinity, -Infinity, NaN)
        if (!isFinite(laborPerHour)) {
            laborPerHour = 0;
        }

        // Any other necessary logic can remain unchanged
        setPrintingJobCriteria((prevJobCriteriaData) => {
            const updatedJobCriteria = [...prevJobCriteriaData];
            updatedJobCriteria[0] = {
                ...updatedJobCriteria[0],
                job_criteria_quantity: +shirtInJOB || 0,
            };
            if (setFieldValue !== undefined) {
                setFieldValue("jobCriteria[0].job_criteria_quantity", +shirtInJOB || 0);
            }
            return updatedJobCriteria;
        });

        setPrintingSetupData((prevSetUpData) => {
            const updatedSetUpData = [...prevSetUpData];
            updatedSetUpData[5] = {
                ...updatedSetUpData[5],
                setup_quantity: laborPerHour,
                total: laborPerHour * updatedSetUpData[5].setup_rate,
            };
            setJobCriteriaKey((prevKey) => prevKey + 1);
            return updatedSetUpData;
        });

        if (setFieldValue == undefined) {
            setLaborperHourKey((prevKey) => prevKey + 1);
        }
    };

    const getQuoteCalculatorClass = () => {
        if (activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) {
            if (
                getMasterTableLoadingState.isTechnicleMasterListIsLoading ||
                getMasterTableLoadingState.isgarmentLoading ||
                getMasterTableLoadingState.isPricingMasterIsLoading ||
                getMasterTableLoadingState.isSetUpIsLoading ||
                getMasterTableLoadingState.isStitchLoading
            ) {
                return 'loading-quote-calculator'; // When loading is still happening
            }
        } else {
            if (
                getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ||
                getScreenPrintingMasterTableLoadingStatus.isInkLoading ||
                getScreenPrintingMasterTableLoadingStatus.isSetupLoading ||
                getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading ||
                getScreenPrintingMasterTableLoadingStatus.isArtTableLoading
            ) {
                return 'loading-quote-calculator'; // When loading is still happening
            }
        }

        // Return this class if all loading has finished
        return 'linear-quote-calculator';
    };


    return (
        <React.Fragment>
            {/* confirmation modal */}
            <ConfirmationModal
                show={showDialogLeavingPage}
                onCloseClick={cancelNavigation}
                title={'Confirmation'}
                description={getConfirmationDescription()}
                onAction={confirmNavigation}
                actionName={'Confirm'}
            />
            <div className={`page-content quote-calculator ${isLinearView ? getQuoteCalculatorClass() : ''}`}>
                {/* Render Breadcrumb */}
                {
                    loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.EMBROIDERY ? (
                        <Breadcrumb
                            breadcrumbItem="Embroidery Calculator"
                            toggleView="switch"
                            quickTour="true"
                            onToggle={handleToggleSwitch}
                            handleQuickTour={startQuickTourService}
                        />
                    ) : loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.SCREENPRINTING ? (
                        <Breadcrumb
                            breadcrumbItem="Screen Printing Calculator"
                            toggleView="switch"
                            quickTour="true"
                            onToggle={handleToggleSwitch}
                            handleQuickTour={startQuickTourService}
                        />
                    ) : loggedinUserPlan === SUBSCRIPTION_CALC_TYPE.ALL ? (
                        <QuoteCalcTabView
                            quickTour={true}
                            toggleView={true}
                            activeTabView={handleActiveTabView}
                            onToggle={handleToggleSwitch}
                            handleQuickTour={startQuickTourService}
                        />
                    ) : (
                        <></>
                    )
                }
                {/* row for embroidory quote calc item name and quantity */}
                {(!isLinearView && activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) && <div className="grid-view">
                    {/* final figures cards */}
                    <Row className={isScrolled && !isQuickTour ? 'statistic-card-row-scroll px-2 statistic-card-row' : isScrolled && !isQuickTour ? 'px-2 statistic-card-row' : 'px-2'} >
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step11">
                            <Card className="mini-stats-wid  header-input-card">
                                <CardBody className="result-card d-flex align-items-center">
                                    <div className="flex-grow-1 position-relative">
                                        <p className={`text-muted fw-medium mb-2 statistic-label`}>Cost/item</p>
                                        <h5 className={`mb-0 value statistic-value ${isExtraLargeDevice ? finalFigurescalculatorState.cost_per_item.length > 12 : finalFigurescalculatorState.cost_per_item.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.cost_per_item}>${finalFigurescalculatorState.cost_per_item}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet-alt font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step12">
                            <Card className="mini-stats-wid header-input-card">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Gross Cost</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_cost.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_cost}>${finalFigurescalculatorState.gross_cost}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet  font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step13">
                            <Card className="mini-stats-wid  header-input-card">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Gross Sale</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_sale.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_sale}>${finalFigurescalculatorState.gross_sale}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-shopping-bag font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step14">
                            <Card className="mini-stats-wid header-input-card ">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Net profit</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_profit.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_profit}>${finalFigurescalculatorState.net_profit}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"mdi mdi-card-plus font-size-20"}></i> </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step15">
                            <Card className="mini-stats-wid header-input-card ">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Gross Hourly Rate</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_hourly_rate.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_hourly_rate}>${finalFigurescalculatorState.gross_hourly_rate}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={" bx bx-money  font-size-20"} ></i> </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step16">
                            <Card className="mini-stats-wid header-input-card ">
                                <CardBody className="result-card d-flex align-items-center">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Net Hourly Rate</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_hourly_rate.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_hourly_rate}>${finalFigurescalculatorState.net_hourly_rate}</h5>
                                    </div>
                                    <div className="align-self-center flex-shrink-0">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-dollar-circle  font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="col-12">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <Formik initialValues={{ itemName: '', quantity: '' }}>
                                        {({ }) => (
                                            <form>
                                                <Row className="quote-info">
                                                    {/* item field */}
                                                    <Col className="col-md-6 col-lg-7 col-12" id="step2">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="form-label">Item<span className="text-danger"> *</span></Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="itemName"
                                                                placeholder="Enter item name"
                                                                onChange={(e) => { setItemName(e.target.value); }}
                                                                value={getItemName}
                                                                autoComplete="off"
                                                            />
                                                            {error && <div className="item-name-error-message">{error}</div>}
                                                        </FormGroup>
                                                    </Col>
                                                    {/* quantity field */}
                                                    <Col className="col-md-3 col-lg-3 col-12 mt-sm-0 mt-2 mt-sm-0" id="step3">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="form-label">Quantity<span className="text-danger"> *</span></Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="quantity"
                                                                placeholder="Enter item quantity"
                                                                onChange={(e) => {
                                                                    const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                    setitemQuantity(+cleanedValue);
                                                                }}
                                                                value={itemQuantity === undefined ? '' : itemQuantity}
                                                                onKeyPress={(e) => { const isValidInput = /^\d*\.?\d*$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }}
                                                                onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                onKeyUp={((e) => {
                                                                    const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                    setitemQuantity(+cleanedValue);
                                                                    setStitchData(prevArray => {
                                                                        const updatedArray = prevArray.map((element, index) => {
                                                                            if (index < prevArray.length - 2) {
                                                                                const updatedTotal = itemQuantity !== 0 ?
                                                                                    ((element.quantity / 1000) * element.stitch_count_rate) * +cleanedValue :
                                                                                    0;

                                                                                return { ...element, total: updatedTotal };
                                                                            }
                                                                            return element;
                                                                        });
                                                                        setStitchCountKey(prevKey => prevKey + 1);
                                                                        return updatedArray;
                                                                    });
                                                                    calculateHoopingFee(e.target.value);
                                                                    let gross_cost = (finalFigurescalculatorState.cost_per_item * itemQuantity).toFixed(2);
                                                                    let gross_sale = (quoteSalesPrice * itemQuantity).toFixed(2);
                                                                    let net_profit = (gross_sale - gross_cost).toFixed(2);
                                                                    setfinalFigurescalculatorState(prevState => ({
                                                                        ...prevState,
                                                                        gross_sale: gross_sale,
                                                                        net_profit: net_profit,
                                                                        gross_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (gross_sale / getHoopingQuantity).toFixed(2),
                                                                        net_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (net_profit / getHoopingQuantity).toFixed(2)
                                                                    }));
                                                                    let markupdata = getMarkUpData;
                                                                    markupdata.forEach(item => {
                                                                        item.pricePerShirt = itemQuantity === 0 ? 0 : +(item.totalCost / +e.target.value).toFixed(2);
                                                                    });
                                                                    setMarkupData(markupdata);
                                                                    let matchingLocation = getPricingMasterList.filter((matchingLocation) => { return matchingLocation.no_of_locations === getLocation });
                                                                    let rate = rateOfHoopingFee(matchingLocation, +e.target.value);
                                                                    let setupdata = getSetUpMasterData;
                                                                    let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                    if (indexToUpdate !== -1) {
                                                                        const newRate = +e.target.value !== 0 ? rate : 0;
                                                                        setupdata[indexToUpdate].setup_rate = newRate;
                                                                        setupdata[indexToUpdate].total = newRate * setupdata[indexToUpdate].setup_quantity;
                                                                        setSetUpMasterData(setupdata);
                                                                    }
                                                                })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* tax field */}
                                                    <Col className={`col-md-3 col-12  d-grid col-lg-2 ${!isLinearView ? 'mt-3' : ''}`}>
                                                        <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={true} componentName={'quote-calculator'} customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid} selectedTaxInfo={selectedTaxInfo} shouldRefreshSelectedTaxInfo={getshouldRefreshSelectedTaxInfo} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className=" col-12  mt-2" id="description">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Description</Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="itemName"
                                                                placeholder="Enter item description"
                                                                onChange={(e) => {
                                                                    setItemDescription(e.target.value);
                                                                }}
                                                                value={getItemDescription}
                                                                autoComplete="off"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="px-1 mb-2 mb-md-0">
                        <Col className="col-xl-6 col-12 card-table">
                            {/* garments table card */}
                            <Card id="step5">
                                <CardBody>
                                    {/* skeleton loading for garments table */}
                                    {getMasterTableLoadingState.isgarmentLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Garments</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th th-width">Total</th>
                                                        <th className="calculator-th th-width">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            garments: getGarmentsData,
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th">Garments</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th th-width">Total</th>
                                                                <th className="calculator-th th-width">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="garments"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.garments.map((garment, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td">
                                                                                    {garment.garment_label}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`garments[${index}].garment_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control fill-in-input"
                                                                                                {...field}
                                                                                                value={field.value || 0}
                                                                                                onChange={(e) => {
                                                                                                    // find the total of quantity and rate based on quantity and update the state array
                                                                                                    const newQuantity = parseFloat(e.target.value);
                                                                                                    setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                    const rate = values.garments[index].garment_rate || 0;
                                                                                                    const total = newQuantity * rate;
                                                                                                    setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                    setGarmentsData((prevGarmentsData) => {
                                                                                                        const updatedGarmentsData = [...prevGarmentsData];
                                                                                                        updatedGarmentsData[index] = {
                                                                                                            ...updatedGarmentsData[index],
                                                                                                            garment_quantity: newQuantity,
                                                                                                            total: newQuantity * updatedGarmentsData[index].garment_rate,
                                                                                                        };
                                                                                                        return updatedGarmentsData;
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`garments[${index}].total`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control auto-populate-input"
                                                                                                {...field}
                                                                                                readOnly
                                                                                                value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`garments[${index}].garment_rate`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                onChange={(e) => {
                                                                                                    // find the total of quantity and garment_rate based on rate and update the state
                                                                                                    const newRate = e.target.value.replace('$', '');
                                                                                                    setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                    const quantity = values.garments[index].garment_quantity || 0;
                                                                                                    const total = +newRate * quantity;
                                                                                                    setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                    setGarmentsData((prevGarmentsData) => {
                                                                                                        const updatedGarmentsData = [...prevGarmentsData];
                                                                                                        updatedGarmentsData[index] = {
                                                                                                            ...updatedGarmentsData[index],
                                                                                                            garment_rate: newRate,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedGarmentsData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td">
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input"
                                                                                    value={`$${Number(values.garments.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td className="calculator-td"></td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* setup table card */}
                            {isSmallLaptopDevice && <Card className="d-xl-block d-none" id="step7">
                                <CardBody>
                                    {getMasterTableLoadingState.isSetUpIsLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <tr>
                                                    <th className="calculator-th">Setup</th>
                                                    <th className="calculator-th">Quantity</th>
                                                    <th className="calculator-th th-width">Total</th>
                                                    <th className=" calculator-th th-width">Rate</th>
                                                </tr>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            setup: getSetUpMasterData,
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th">Setup</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th th-width">Total</th>
                                                                <th className="calculator-th th-width">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="setup"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.setup.map((setup, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label}
                                                                                    {setup.setup_label === masterTableLable.location && (<span className="text-danger"> *</span>)}
                                                                                    {setup.setup_label === masterTableLable.rpm && (<span className="text-danger"> *</span>)}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`setup[${index}].setup_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'fill-in-input'} `}
                                                                                                {...field}
                                                                                                value={(field.value || 0).toString()}
                                                                                                onChange={(e) => {
                                                                                                    const newQuantity = Math.ceil(parseFloat(e.target.value || 0) * 10) / 10 || 0;
                                                                                                    // on location field allow to enter number only [1,2,3,4,5,6]
                                                                                                    if (setup.setup_label === masterTableLable.location && ![0, 1, 2, 3, 4, 5, 6].includes(newQuantity)) {
                                                                                                        setlocationValue(newQuantity)
                                                                                                        return;
                                                                                                    }
                                                                                                    setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                    // update the rom state value
                                                                                                    if (setup.setup_label === masterTableLable.rpm) {
                                                                                                        setRpm(newQuantity);
                                                                                                    }
                                                                                                    const rate = values.setup[index].setup_rate || 0;
                                                                                                    const total = newQuantity * rate;
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setSetUpMasterData((prevsetupData) => {
                                                                                                        const updatedsetupData = [...prevsetupData];
                                                                                                        updatedsetupData[index] = {
                                                                                                            ...updatedsetupData[index],
                                                                                                            setup_quantity: +field.value || 0,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedsetupData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyUp={(e) => {
                                                                                                    if (setup.setup_label === masterTableLable.location) {
                                                                                                        setlocationValue(+e.target.value || 0);
                                                                                                        let matchingLocations = getPricingMasterList.filter(location => location.no_of_locations === +e.target.value);
                                                                                                        let rate = rateOfHoopingFee(matchingLocations, itemQuantity)
                                                                                                        let setupdata = getSetUpMasterData;
                                                                                                        let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                                                        const newRate = +e.target.value !== 0 ? rate : 0;
                                                                                                        setupdata[indexToUpdate].setup_rate = newRate;
                                                                                                        setSetUpMasterData(setupdata);
                                                                                                    }
                                                                                                    const newQuantity = e.target.value || 0;
                                                                                                    const rate = values.setup[index].setup_rate || 0;
                                                                                                    const total = newQuantity * rate;
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setSetUpMasterData((prevsetupData) => {
                                                                                                        const updatedsetupData = [...prevsetupData];
                                                                                                        updatedsetupData[index] = {
                                                                                                            ...updatedsetupData[index],
                                                                                                            setup_quantity: +e.target.value || 0,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedsetupData;
                                                                                                    });
                                                                                                }}
                                                                                                autoCorrect="off"
                                                                                                disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {/* Conditionally render Rate field for RPM and Location */}
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                        const quantity = values.setup[index].setup_quantity || 0;
                                                                                                        const total = +newRate * quantity;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_rate: newRate,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td">
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input"
                                                                                    value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td className="calculator-td"></td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>}
                        </Col>
                        <Col className="col-xl-6 col-12 card-table">
                            {/* stitch count table card */}
                            <Card id="step6">
                                <CardBody>
                                    {getMasterTableLoadingState.isStitchLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Stitch Count</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th">Total</th>
                                                        <th className="calculator-th">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        key={stitchCountKey}
                                        initialValues={{
                                            stichCount: getStitchCountData
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th stitch-count-head">Stitch Count</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th">Total</th>
                                                                <th className="calculator-th">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="garments"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.stichCount.map((stichCount, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td custom-charges-label">
                                                                                    {stichCount.stitch_count_label}
                                                                                    {
                                                                                        index === 7 && (
                                                                                            <span>
                                                                                                <i id="custom_charges" className={`bx ms-1 mb-2 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                <UncontrolledTooltip placement="bottom" target="custom_charges" style={{ background: 'black' }}>
                                                                                                    This field needs to be garment quantity not stitch count
                                                                                                </UncontrolledTooltip>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (index === 6) && (
                                                                                            <span>
                                                                                                <i id="applique" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute  info-icon" />
                                                                                                <UncontrolledTooltip placement="bottom" target="applique" style={{ background: 'black' }}>
                                                                                                    This field needs to be garment quantity not stitch count
                                                                                                </UncontrolledTooltip>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        (index === 5) && (
                                                                                            <span>
                                                                                                <i id="dPuff" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon" />
                                                                                                <UncontrolledTooltip placement="bottom" target="dPuff" style={{ background: 'black' }}>
                                                                                                    This field needs to be garment quantity not stitch count
                                                                                                </UncontrolledTooltip>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`stichCount[${index}].quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control fill-in-input"
                                                                                                {...field}
                                                                                                value={field.value || 0}
                                                                                                onChange={(e) => {
                                                                                                    const newQuantity = parseFloat(e.target.value);
                                                                                                    setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                    // base don the value of the quantity find the total of rate and quantity
                                                                                                    const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                    // get the total quantity of the stith count object 
                                                                                                    const stitchCountQuantities = values.stichCount
                                                                                                        .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                        .map(item => item.quantity || 0);
                                                                                                    const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                    setStitchCount(totalStitchCountQuantity);
                                                                                                    if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                        let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                        setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: multiplicationwithRate,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                        let total = rate * newQuantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else {
                                                                                                        const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    }
                                                                                                    if (stichCount.stitch_count_label === masterTableLable.stitchCount) {
                                                                                                        let AppliqueTotal = (+getStitchCountData[6].stitch_count_rate * totalStitchCountQuantity) / 1000 * +getStitchCountData[6].quantity;
                                                                                                        setFieldValue(`stichCount[${6}].total`, AppliqueTotal === NaN ? 0 : AppliqueTotal || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[6] = {
                                                                                                                ...updatedStitchCountData[6],
                                                                                                                total: AppliqueTotal === NaN ? 0 : +(AppliqueTotal.toFixed(2)),
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    }

                                                                                                }}
                                                                                                onKeyUp={(e) => {
                                                                                                    const newQuantity = parseFloat(e.target.value);
                                                                                                    setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                    // base don the value of the quantity find the total of rate and quantity
                                                                                                    const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                    const stitchCountQuantities = values.stichCount
                                                                                                        .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                        .map(item => item.quantity || 0);
                                                                                                    const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                    setStitchCount(totalStitchCountQuantity);
                                                                                                    if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                        let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                        setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: multiplicationwithRate,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                        let total = rate * newQuantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else {
                                                                                                        const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    }
                                                                                                    if (stichCount.stitch_count_label === masterTableLable.stitchCount) {
                                                                                                        let AppliqueTotal = (+getStitchCountData[6].stitch_count_rate * totalStitchCountQuantity) / 1000 * +getStitchCountData[6].quantity;
                                                                                                        setFieldValue(`stichCount[${6}].total`, AppliqueTotal === NaN ? 0 : AppliqueTotal || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[6] = {
                                                                                                                ...updatedStitchCountData[6],
                                                                                                                total: AppliqueTotal === NaN ? 0 : +(AppliqueTotal.toFixed(2)),
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    }
                                                                                                }}
                                                                                                autoComplete="off"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`stichCount[${index}].total`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control auto-populate-input"
                                                                                                {...field}
                                                                                                readOnly
                                                                                                min={0}
                                                                                                value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`stichCount[${index}].stitch_count_rate`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                onChange={(e) => {
                                                                                                    const newRate = e.target.value.replace('$', '');
                                                                                                    setFieldValue(`stichCount[${index}].stitch_count_rate`, newRate, false);
                                                                                                    // get the total quantity of the stith count object 
                                                                                                    const stitchCountQuantities = values.stichCount
                                                                                                        .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                        .map(item => item.quantity || 0);
                                                                                                    const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                    setStitchCount(totalStitchCountQuantity);
                                                                                                    // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                    const quantity = values.stichCount[index].quantity || 0;
                                                                                                    if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                        let multiplicationwithRate = (+newRate * +totalStitchCountQuantity) / 1000 * +stichCount.quantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: quantity,
                                                                                                                total: multiplicationwithRate,
                                                                                                                stitch_count_rate: newRate
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                        const total = quantity * newRate;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: quantity,
                                                                                                                total: total,
                                                                                                                stitch_count_rate: newRate
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    } else {
                                                                                                        const total = ((quantity / 1000) * newRate) * itemQuantity;
                                                                                                        setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                        setStitchData((prevStitchCountData) => {
                                                                                                            const updatedStitchCountData = [...prevStitchCountData];
                                                                                                            updatedStitchCountData[index] = {
                                                                                                                ...updatedStitchCountData[index],
                                                                                                                quantity: quantity,
                                                                                                                total: total,
                                                                                                                stitch_count_rate: newRate
                                                                                                            };
                                                                                                            return updatedStitchCountData;
                                                                                                        });
                                                                                                    }
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td">
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input"
                                                                                    value={`$${Number(values.stichCount.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td className="calculator-td"></td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* setup table card */}
                            <Card className="d-xl-none d-block" id="step7">
                                <CardBody>
                                    {getMasterTableLoadingState.isSetUpIsLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Setup</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th">Total</th>
                                                        <th className="calculator-th">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            setup: getSetUpMasterData,
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th">Setup</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th">Total</th>
                                                                <th className="calculator-th">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="setup"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.setup.map((setup, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label}
                                                                                    {setup.setup_label === masterTableLable.location && (<span className="text-danger"> *</span>)}
                                                                                    {setup.setup_label === masterTableLable.rpm && (<span className="text-danger"> *</span>)}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`setup[${index}].setup_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'fill-in-input'} `}
                                                                                                {...field}
                                                                                                value={(field.value || 0).toString()}
                                                                                                onChange={(e) => {
                                                                                                    const newQuantity = Math.ceil(parseFloat(e.target.value || 0) * 10) / 10 || 0;
                                                                                                    // on location field allow to enter number only [1,2,3,4,5,6]
                                                                                                    if (setup.setup_label === masterTableLable.location && ![0, 1, 2, 3, 4, 5, 6].includes(newQuantity)) {
                                                                                                        setlocationValue(newQuantity)
                                                                                                        return;
                                                                                                    }
                                                                                                    setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                    // update the rom state value
                                                                                                    if (setup.setup_label === masterTableLable.rpm) {
                                                                                                        setRpm(newQuantity);
                                                                                                    }
                                                                                                    const rate = values.setup[index].setup_rate || 0;
                                                                                                    const total = newQuantity * rate;
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setSetUpMasterData((prevsetupData) => {
                                                                                                        const updatedsetupData = [...prevsetupData];
                                                                                                        updatedsetupData[index] = {
                                                                                                            ...updatedsetupData[index],
                                                                                                            setup_quantity: +field.value || 0,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedsetupData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyUp={(e) => {
                                                                                                    if (setup.setup_label === masterTableLable.location) {
                                                                                                        setlocationValue(+e.target.value || 0);
                                                                                                        let matchingLocations = getPricingMasterList.filter(location => location.no_of_locations === +e.target.value);
                                                                                                        let rate = rateOfHoopingFee(matchingLocations, itemQuantity)
                                                                                                        let setupdata = getSetUpMasterData;
                                                                                                        let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                                                        const newRate = +e.target.value !== 0 ? rate : 0;
                                                                                                        setupdata[indexToUpdate].setup_rate = newRate;
                                                                                                        setSetUpMasterData(setupdata);
                                                                                                    }
                                                                                                    const newQuantity = e.target.value || 0;
                                                                                                    const rate = values.setup[index].setup_rate || 0;
                                                                                                    const total = newQuantity * rate;
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setSetUpMasterData((prevsetupData) => {
                                                                                                        const updatedsetupData = [...prevsetupData];
                                                                                                        updatedsetupData[index] = {
                                                                                                            ...updatedsetupData[index],
                                                                                                            setup_quantity: +e.target.value || 0,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedsetupData;
                                                                                                    });
                                                                                                }}
                                                                                                autoCorrect="off"
                                                                                                disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {/* Conditionally render Rate field for RPM and Location */}
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                        const quantity = values.setup[index].setup_quantity || 0;
                                                                                                        const total = +newRate * quantity;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_rate: newRate,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td">
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input"
                                                                                    value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td className="calculator-td"></td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* technical specification table card */}
                            <Card id="step8">
                                <CardBody>
                                    {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Technical Specification</th>
                                                        <th className="calculator-th"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                        <Formik
                                            initialValues={{
                                                technicalSpecification: getTechnicalMasterData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Technical Specification</th>
                                                                    <th className="calculator-th"> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="technicalSpecification"
                                                                    render={() => (
                                                                        <>
                                                                            <tr>
                                                                                <td className="calculator-td">
                                                                                    Garments / Hour
                                                                                </td>
                                                                                <td className="calculator-td ">
                                                                                    {getGarmentPerHour.toFixed(2) || 0}
                                                                                </td>
                                                                            </tr>
                                                                            {values && values.technicalSpecification && values.technicalSpecification.map((technicalSpecification, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {technicalSpecification.technical_specification_label}<span className="text-danger"> *</span>
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`technicalSpecification[${index}].technical_specification_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control editable-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0.00}
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '').replace(/^0+/, '');
                                                                                                        setFieldValue(`technicalSpecification[${index}].technical_specification_quantity`, newRate || 0, false);
                                                                                                        setEmbroideryHeads(newRate);
                                                                                                        if (technicalSpecification.technical_specification_label !== 'Garments / Hour') {
                                                                                                            countGarmentsPerHourTechnicalSpecification(true);
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                            {/* mark up table card */}
                            <Card className="markup-table-card">
                                <CardBody>
                                    {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Markup</th>
                                                        <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                        <th className="calculator-th">Price/Shirt </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                        <Formik
                                            key={markupKey}
                                            initialValues={{ markupData: getMarkUpData, }} >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <FieldArray name="markupData">
                                                            {({ remove, push }) => (
                                                                <>
                                                                    <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                        {/* Table Header */}
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th className="calculator-th">Markup</th>
                                                                                <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                <th className="calculator-th">Price/Shirt </th>
                                                                            </tr>
                                                                        </thead>
                                                                        {/* Table Body */}
                                                                        <tbody>
                                                                            {values.markupData.map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.markup`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={`${Number(field.value || 0)}%`}
                                                                                                    onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                        onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newMarkUp = e.target.value.replace('%', '');
                                                                                                        let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let ArtCollectionAndDigitizingFeeTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0).toFixed(2);
                                                                                                        let totalGrandTotal = +garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal;
                                                                                                        let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                        setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                        setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                        setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                        setMarkupData((prevMarkupData) => {
                                                                                                            const updatedMarkupData = [...prevMarkupData];
                                                                                                            updatedMarkupData[index] = {
                                                                                                                ...updatedMarkupData[index],
                                                                                                                markup: +newMarkUp,
                                                                                                                totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                            };
                                                                                                            return updatedMarkupData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.totalCost`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`markupData[${index}].pricePerShirt`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger"> *</span></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control auto-populate-input"
                                                                                        name="quantity"
                                                                                        placeholder="Enter quantity"
                                                                                        value={quoteSalesPercentage + '%'}
                                                                                        type="text"
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="calculator-td">
                                                                                    <NumericFormat className="form-control fill-in-input" onKeyUp={(e) => {
                                                                                        let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                        let numericPrice = parseFloat(price) || 0;
                                                                                        setQuoteSalesPrice(numericPrice);
                                                                                        if (itemQuantity !== undefined) {
                                                                                            let gross_sale = (numericPrice * +itemQuantity);
                                                                                            let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                            setfinalFigurescalculatorState(prevState => ({
                                                                                                ...prevState,
                                                                                                gross_sale: (gross_sale).toFixed(2),
                                                                                                net_profit: (net_profit).toFixed(2),
                                                                                                gross_hourly_rate: (getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? '0.00' : ((gross_sale).toFixed(2) / getHoopingQuantity).toFixed(2),
                                                                                                net_hourly_rate: (getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? '0.00' : ((net_profit).toFixed(2) / getHoopingQuantity).toFixed(2)
                                                                                            }));
                                                                                        }
                                                                                    }}
                                                                                        thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                        onChange={(e) => {
                                                                                            let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                            let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                            setQuoteSalesPrice(numericPrice);
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        value={quoteSalesPrice} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                }
                {(isLinearView && activeTabview === CALCULATOR_TYPE.EMBOROIDERY_CALCULATOR) &&
                    <div className="linear-view">
                        {/* final figures cards */}
                        <Row className={isScrolled ? 'statistic-card-row-scroll px-2 statistic-card-row' : 'px-2 statistic-card-row'} >
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step11">
                                <Card className="mini-stats-wid  header-input-card">
                                    <CardBody className="result-card d-flex align-items-center">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Cost/item</p>
                                            <h5 className={`mb-0 value statistic-value ${isExtraLargeDevice ? finalFigurescalculatorState.cost_per_item.length > 12 : finalFigurescalculatorState.cost_per_item.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.cost_per_item}>${finalFigurescalculatorState.cost_per_item}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet-alt font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step12">
                                <Card className="mini-stats-wid header-input-card">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Gross Cost</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_cost.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_cost}>${finalFigurescalculatorState.gross_cost}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet  font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step13">
                                <Card className="mini-stats-wid  header-input-card">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Gross Sale</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_sale.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_sale}>${finalFigurescalculatorState.gross_sale}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-shopping-bag font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step14">
                                <Card className="mini-stats-wid header-input-card ">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Net profit</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_profit.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_profit}>${finalFigurescalculatorState.net_profit}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"mdi mdi-card-plus font-size-20"}></i> </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step15">
                                <Card className="mini-stats-wid header-input-card ">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Gross Hourly Rate</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_hourly_rate.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_hourly_rate}>${finalFigurescalculatorState.gross_hourly_rate}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={" bx bx-money  font-size-20"} ></i> </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step16">
                                <Card className="mini-stats-wid header-input-card ">
                                    <CardBody className="result-card d-flex align-items-center">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Net Hourly Rate</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_hourly_rate.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_hourly_rate}>${finalFigurescalculatorState.net_hourly_rate}</h5>
                                        </div>
                                        <div className="align-self-center flex-shrink-0">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-dollar-circle  font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-12">
                                <Card className="mini-stats-wid">
                                    <CardBody className="item-name-quantity-body-card">
                                        <Formik initialValues={{ itemName: '', quantity: '' }}>
                                            {({ }) => (
                                                <form>
                                                    <Row className="quote-info">
                                                        {/* item field */}
                                                        <Col className="col-md-6 col-xl-3 col-12" id="step2">
                                                            <FormGroup className="mb-0 form-group-margin-0">
                                                                <div className="d-flex">
                                                                    <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Item<span className="text-danger"> *</span></Label>
                                                                    <input
                                                                        className="form-control form-control-header"
                                                                        name="itemName"
                                                                        placeholder="Enter item name"
                                                                        onChange={(e) => { setItemName(e.target.value); }}
                                                                        value={getItemName}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                {error && <div className="item-name-error-message">{error}</div>}
                                                            </FormGroup>
                                                        </Col>
                                                        {/* quantity field */}
                                                        <Col className="col-md-6 col-xl-2 col-12 mt-md-0 mt-2 mt-sm-0" id="step3">
                                                            <FormGroup className="mb-0 form-group-margin-0 d-flex">
                                                                <Label className="mb-0 form-label d-flex align-items-center me-2">Quantity<span className="text-danger"> * </span></Label>
                                                                <input
                                                                    className="form-control form-control-header"
                                                                    name="quantity"
                                                                    placeholder="Enter item quantity"
                                                                    onChange={(e) => {
                                                                        const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                        setitemQuantity(+cleanedValue);
                                                                    }}
                                                                    value={itemQuantity === undefined ? '' : itemQuantity}
                                                                    onKeyPress={(e) => { const isValidInput = /^\d*\.?\d*$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }}
                                                                    onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                    onKeyUp={((e) => {
                                                                        const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                        setitemQuantity(+cleanedValue);
                                                                        setStitchData(prevArray => {
                                                                            const updatedArray = prevArray.map((element, index) => {
                                                                                if (index < prevArray.length - 3) {
                                                                                    const updatedTotal = itemQuantity !== 0 ?
                                                                                        ((element.quantity / 1000) * element.stitch_count_rate) * +cleanedValue :
                                                                                        0;
                                                                                    return { ...element, total: updatedTotal };
                                                                                }
                                                                                return element;
                                                                            });
                                                                            setStitchCountKey(prevKey => prevKey + 1);
                                                                            return updatedArray;
                                                                        });
                                                                        calculateHoopingFee(e.target.value);
                                                                        let gross_cost = (finalFigurescalculatorState.cost_per_item * itemQuantity).toFixed(2);
                                                                        let gross_sale = (quoteSalesPrice * itemQuantity).toFixed(2);
                                                                        let net_profit = (gross_sale - gross_cost).toFixed(2);
                                                                        setfinalFigurescalculatorState(prevState => ({
                                                                            ...prevState,
                                                                            gross_sale: gross_sale,
                                                                            net_profit: net_profit,
                                                                            gross_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (gross_sale / getHoopingQuantity).toFixed(2),
                                                                            net_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? (0).toFixed(2) : (net_profit / getHoopingQuantity).toFixed(2)
                                                                        }));
                                                                        let markupdata = getMarkUpData;
                                                                        markupdata.forEach(item => {
                                                                            item.pricePerShirt = itemQuantity === 0 ? 0 : +(item.totalCost / +e.target.value).toFixed(2);
                                                                        });
                                                                        setMarkupData(markupdata);
                                                                        let matchingLocation = getPricingMasterList.filter((matchingLocation) => { return matchingLocation.no_of_locations === getLocation });
                                                                        let rate = rateOfHoopingFee(matchingLocation, +e.target.value);
                                                                        let setupdata = getSetUpMasterData;
                                                                        let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                        if (indexToUpdate !== -1) {
                                                                            const newRate = +e.target.value !== 0 ? rate : 0;
                                                                            setupdata[indexToUpdate].setup_rate = newRate;
                                                                            setupdata[indexToUpdate].total = newRate * setupdata[indexToUpdate].setup_quantity;
                                                                            setSetUpMasterData(setupdata);
                                                                        }
                                                                    })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="col-md-9  col-xl-5 col-12 mt-md-2 mt-xl-0" id="description">
                                                            <FormGroup className="mb-0 form-group-margin-0">
                                                                <div className="d-flex">
                                                                    <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Description</Label>
                                                                    <input
                                                                        className="form-control form-control-header"
                                                                        name="itemName"
                                                                        placeholder="Enter item description"
                                                                        onChange={(e) => {
                                                                            setItemDescription(e.target.value);
                                                                        }}
                                                                        value={getItemDescription}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {/* add tax */}
                                                        <Col className="col-md-3 col-12  col-lg-3 col-xl-2 mt-md-2 mt-xl-0 ">
                                                            <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={true} componentName={'quote-calculator'} customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid} selectedTaxInfo={selectedTaxInfo} shouldRefreshSelectedTaxInfo={getshouldRefreshSelectedTaxInfo} />
                                                        </Col>
                                                    </Row>
                                                </form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* for laptop device */}
                        {isSmallLaptopDevice && <Row className="px-2 mb-2 d-none d-xl-flex">
                            <Col className="col-xl-3 col-12 card-table" >
                                {/* garments table card */}
                                <Card id="step5" className="d-none d-xl-flex">
                                    <CardBody className="linear-table-card-body">
                                        {/* skeleton loading for garments table */}
                                        {getMasterTableLoadingState.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Garments</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th th-width">Total</th>
                                                            <th className="calculator-th th-width">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                garments: getGarmentsData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Garments</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.garments.map((garment, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {garment.garment_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].garment_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and rate based on quantity and update the state array
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                        const rate = values.garments[index].garment_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                        setGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                garment_quantity: newQuantity,
                                                                                                                total: newQuantity * updatedGarmentsData[index].garment_rate,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].garment_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                        const quantity = values.garments[index].garment_quantity || 0;
                                                                                                        const total = +newRate * quantity;
                                                                                                        setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                        setGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                garment_rate: newRate,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(values.garments.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-xl-3 col-12 card-table">
                                {/* stitch count table card */}
                                <Card id="step6">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isStitchLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Stitch Count</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            key={stitchCountKey}
                                            initialValues={{
                                                stichCount: getStitchCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th stitch-count-calculator-th">Stitch Count</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.stichCount.map((stichCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {stichCount.stitch_count_label}
                                                                                        {
                                                                                            index === 7 && (
                                                                                                <span>
                                                                                                    <i id="custom_charges" className={`bx ms-1 mb-2 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                    <UncontrolledTooltip placement="bottom" target="custom_charges" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            (index === 6) && (
                                                                                                <span>
                                                                                                    <i id="applique" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" />
                                                                                                    <UncontrolledTooltip placement="bottom" target="applique" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            (index === 5) && (
                                                                                                <span>
                                                                                                    <i id="dPuff" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" />
                                                                                                    <UncontrolledTooltip placement="bottom" target="dPuff" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    // base don the value of the quantity find the total of rate and quantity
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                        // get the total quantity of the stith count object 
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity);
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            let total = rate * newQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.stitchCount) {
                                                                                                            let AppliqueTotal = (+getStitchCountData[6].stitch_count_rate * totalStitchCountQuantity) / 1000 * +getStitchCountData[6].quantity;
                                                                                                            setFieldValue(`stichCount[${6}].total`, AppliqueTotal === NaN ? 0 : AppliqueTotal || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[6] = {
                                                                                                                    ...updatedStitchCountData[6],
                                                                                                                    total: AppliqueTotal === NaN ? 0 : +(AppliqueTotal.toFixed(2)),
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity);
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            let total = rate * newQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.stitchCount) {
                                                                                                            let AppliqueTotal = (+getStitchCountData[6].stitch_count_rate * totalStitchCountQuantity) / 1000 * +getStitchCountData[6].quantity;
                                                                                                            setFieldValue(`stichCount[${6}].total`, AppliqueTotal === NaN ? 0 : AppliqueTotal || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[6] = {
                                                                                                                    ...updatedStitchCountData[6],
                                                                                                                    total: AppliqueTotal === NaN ? 0 : +(AppliqueTotal.toFixed(2)),
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].stitch_count_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`stichCount[${index}].stitch_count_rate`, newRate, false);
                                                                                                        // get the total quantity of the stith count object 
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.stichCount[index].quantity || 0;
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (+newRate * +totalStitchCountQuantity) / 1000 * +stichCount.quantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            const total = quantity * newRate;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: total,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((quantity / 1000) * newRate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: total,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(values.stichCount.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* setup table card */}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isSetUpIsLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Setup</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getSetUpMasterData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label}
                                                                                        {setup.setup_label === masterTableLable.location && (<span className="text-danger"> *</span>)}
                                                                                        {setup.setup_label === masterTableLable.rpm && (<span className="text-danger"> *</span>)}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'fill-in-input'} `}
                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = Math.ceil(parseFloat(e.target.value || 0) * 10) / 10 || 0;
                                                                                                        // on location field allow to enter number only [1,2,3,4,5,6]
                                                                                                        if (setup.setup_label === masterTableLable.location && ![0, 1, 2, 3, 4, 5, 6].includes(newQuantity)) {
                                                                                                            setlocationValue(newQuantity)
                                                                                                            return;
                                                                                                        }
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        // update the rom state value
                                                                                                        if (setup.setup_label === masterTableLable.rpm) {
                                                                                                            setRpm(newQuantity);
                                                                                                        }
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +field.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        if (setup.setup_label === masterTableLable.location) {
                                                                                                            setlocationValue(+e.target.value || 0);
                                                                                                            let matchingLocations = getPricingMasterList.filter(location => location.no_of_locations === +e.target.value);
                                                                                                            let rate = rateOfHoopingFee(matchingLocations, itemQuantity)
                                                                                                            let setupdata = getSetUpMasterData;
                                                                                                            let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                                                            const newRate = +e.target.value !== 0 ? rate : 0;
                                                                                                            setupdata[indexToUpdate].setup_rate = newRate;
                                                                                                            setSetUpMasterData(setupdata);
                                                                                                        }
                                                                                                        const newQuantity = e.target.value || 0;
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +e.target.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = +newRate * quantity;
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setSetUpMasterData((prevsetupData) => {
                                                                                                                const updatedsetupData = [...prevsetupData];
                                                                                                                updatedsetupData[index] = {
                                                                                                                    ...updatedsetupData[index],
                                                                                                                    setup_rate: newRate,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedsetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                            </Col>
                            <Col className="col-xl-3 col-12 card-table">
                                {/* setup table card */}
                                {isSmallLaptopDevice && <Card className="d-xl-block d-none setup-table-card" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isSetUpIsLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <tr>
                                                        <th className="calculator-th">Setup</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th th-width">Total</th>
                                                        <th className=" calculator-th th-width">Rate</th>
                                                    </tr>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getSetUpMasterData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th setup-calculator-th">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label}
                                                                                        {setup.setup_label === masterTableLable.location && (<span className="text-danger">*</span>)}
                                                                                        {setup.setup_label === masterTableLable.rpm && (<span className="text-danger">*</span>)}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'fill-in-input'} `}
                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = Math.ceil(parseFloat(e.target.value || 0) * 10) / 10 || 0;
                                                                                                        // on location field allow to enter number only [1,2,3,4,5,6]
                                                                                                        if (setup.setup_label === masterTableLable.location && ![0, 1, 2, 3, 4, 5, 6].includes(newQuantity)) {
                                                                                                            setlocationValue(newQuantity)
                                                                                                            return;
                                                                                                        }
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        // update the rom state value
                                                                                                        if (setup.setup_label === masterTableLable.rpm) {
                                                                                                            setRpm(newQuantity);
                                                                                                        }
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +field.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        if (setup.setup_label === masterTableLable.location) {
                                                                                                            setlocationValue(+e.target.value || 0);
                                                                                                            let matchingLocations = getPricingMasterList.filter(location => location.no_of_locations === +e.target.value);
                                                                                                            let rate = rateOfHoopingFee(matchingLocations, itemQuantity)
                                                                                                            let setupdata = getSetUpMasterData;
                                                                                                            let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                                                            const newRate = +e.target.value !== 0 ? rate : 0;
                                                                                                            setupdata[indexToUpdate].setup_rate = newRate;
                                                                                                            setSetUpMasterData(setupdata);
                                                                                                        }
                                                                                                        const newQuantity = e.target.value || 0;
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +e.target.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = +newRate * quantity;
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setSetUpMasterData((prevsetupData) => {
                                                                                                                const updatedsetupData = [...prevsetupData];
                                                                                                                updatedsetupData[index] = {
                                                                                                                    ...updatedsetupData[index],
                                                                                                                    setup_rate: newRate,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedsetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                                {/* technical specification table card */}
                                <Card id="step8">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Technical Specification</th>
                                                            <th className="calculator-th"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td calculator-tech-skeleton">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td calculator-tech-skeleton">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                            <Formik
                                                initialValues={{
                                                    technicalSpecification: getTechnicalMasterData,
                                                }}
                                                render={({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <Table className="table align-middle mb-0 table-nowrap">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th className="calculator-th">Technical Specification</th>
                                                                        <th className="calculator-th"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="calculator-table-body">
                                                                    <FieldArray
                                                                        name="technicalSpecification"
                                                                        render={() => (
                                                                            <>
                                                                                <tr>
                                                                                    <td className="calculator-td">
                                                                                        Garments / Hour
                                                                                    </td>
                                                                                    <td className="calculator-td ">
                                                                                        {getGarmentPerHour.toFixed(2) || 0}
                                                                                    </td>
                                                                                </tr>
                                                                                {values && values.technicalSpecification && values.technicalSpecification.map((technicalSpecification, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            {technicalSpecification.technical_specification_label}<span className="text-danger">*</span>
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`technicalSpecification[${index}].technical_specification_quantity`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control editable-input"
                                                                                                        {...field}
                                                                                                        value={field.value || 0.00}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '').replace(/^0+/, '');
                                                                                                            setFieldValue(`technicalSpecification[${index}].technical_specification_quantity`, newRate || 0, false);
                                                                                                            setEmbroideryHeads(newRate);
                                                                                                            if (technicalSpecification.technical_specification_label !== 'Garments / Hour') {
                                                                                                                countGarmentsPerHourTechnicalSpecification(true);
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Form>
                                                )}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-xl-3 col-12 card-table">
                                {/* mark up table card */}
                                <Card className="markup-table-card">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Markup</th>
                                                            <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                            <th className="calculator-th">Price/Shirt </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                            <Formik
                                                key={markupKey}
                                                initialValues={{ markupData: getMarkUpData, }} >
                                                {({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <FieldArray name="markupData">
                                                                {({ remove, push }) => (<>
                                                                    <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                        {/* Table Header */}
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th className="calculator-th">Markup</th>
                                                                                <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                <th className="calculator-th">Price/Shirt </th>
                                                                            </tr>
                                                                        </thead>
                                                                        {/* Table Body */}
                                                                        <tbody className="calculator-table-body">
                                                                            {values.markupData.map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.markup`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={`${Number(field.value || 0)}%`}
                                                                                                    onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                        onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newMarkUp = e.target.value.replace('%', '');
                                                                                                        let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let ArtCollectionAndDigitizingFeeTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0).toFixed(2);
                                                                                                        let totalGrandTotal = +garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal;
                                                                                                        let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                        setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                        setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                        setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                        setMarkupData((prevMarkupData) => {
                                                                                                            const updatedMarkupData = [...prevMarkupData];
                                                                                                            updatedMarkupData[index] = {
                                                                                                                ...updatedMarkupData[index],
                                                                                                                markup: +newMarkUp,
                                                                                                                totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                            };
                                                                                                            return updatedMarkupData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.totalCost`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`markupData[${index}].pricePerShirt`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger">*</span></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control auto-populate-input"
                                                                                        name="quantity"
                                                                                        placeholder="Enter quantity"
                                                                                        value={quoteSalesPercentage + '%'}
                                                                                        type="text"
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="calculator-td">
                                                                                    <NumericFormat className="form-control fill-in-input" onKeyUp={(e) => {
                                                                                        let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                        let numericPrice = parseFloat(price) || 0;
                                                                                        setQuoteSalesPrice(numericPrice);
                                                                                        if (itemQuantity !== undefined) {
                                                                                            let gross_sale = (numericPrice * +itemQuantity);
                                                                                            let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                            setfinalFigurescalculatorState(prevState => ({
                                                                                                ...prevState,
                                                                                                gross_sale: (gross_sale).toFixed(2),
                                                                                                net_profit: (net_profit).toFixed(2),
                                                                                                gross_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? '0.00' : ((gross_sale).toFixed(2) / getHoopingQuantity).toFixed(2),
                                                                                                net_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? '0.00' : ((net_profit).toFixed(2) / getHoopingQuantity).toFixed(2)
                                                                                            }));
                                                                                        }
                                                                                    }}
                                                                                        thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                        onChange={(e) => {
                                                                                            let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                            let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                            setQuoteSalesPrice(numericPrice);
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        value={quoteSalesPrice} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>}
                        {/* for tablet linear view */}
                        {(window.innerWidth >= 768 && window.innerWidth <= 1200) && <Row className="d-none d-md-flex d-xl-none mb-2">
                            <Col className="col-6 pe-1">
                                {/* garments table card */}
                                <Card id="step5">
                                    <CardBody className="linear-table-card-body">
                                        {/* skeleton loading for garments table */}
                                        {getMasterTableLoadingState.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Garments</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th th-width">Total</th>
                                                            <th className="calculator-th th-width">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                garments: getGarmentsData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Garments</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.garments.map((garment, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {garment.garment_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].garment_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and rate based on quantity and update the state array
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                        const rate = values.garments[index].garment_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                        setGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                garment_quantity: newQuantity,
                                                                                                                total: newQuantity * updatedGarmentsData[index].garment_rate,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].garment_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and garment_rate based on rate and update the state
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                        const quantity = values.garments[index].garment_quantity || 0;
                                                                                                        const total = +newRate * quantity;
                                                                                                        setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                        setGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                garment_rate: newRate,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(values.garments.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* setup table card */}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isSetUpIsLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Setup</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getSetUpMasterData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label}
                                                                                        {setup.setup_label === masterTableLable.location && (<span className="text-danger"> *</span>)}
                                                                                        {setup.setup_label === masterTableLable.rpm && (<span className="text-danger"> *</span>)}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'fill-in-input'} `}
                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = Math.ceil(parseFloat(e.target.value || 0) * 10) / 10 || 0;
                                                                                                        // on location field allow to enter number only [1,2,3,4,5,6]
                                                                                                        if (setup.setup_label === masterTableLable.location && ![0, 1, 2, 3, 4, 5, 6].includes(newQuantity)) {
                                                                                                            setlocationValue(newQuantity)
                                                                                                            return;
                                                                                                        }
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        // update the rom state value
                                                                                                        if (setup.setup_label === masterTableLable.rpm) {
                                                                                                            setRpm(newQuantity);
                                                                                                        }
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +field.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        if (setup.setup_label === masterTableLable.location) {
                                                                                                            setlocationValue(+e.target.value || 0);
                                                                                                            let matchingLocations = getPricingMasterList.filter(location => location.no_of_locations === +e.target.value);
                                                                                                            let rate = rateOfHoopingFee(matchingLocations, itemQuantity)
                                                                                                            let setupdata = getSetUpMasterData;
                                                                                                            let indexToUpdate = setupdata.findIndex(obj => obj.setup_label === masterTableLable.hoopingFee);
                                                                                                            const newRate = +e.target.value !== 0 ? rate : 0;
                                                                                                            setupdata[indexToUpdate].setup_rate = newRate;
                                                                                                            setSetUpMasterData(setupdata);
                                                                                                        }
                                                                                                        const newQuantity = e.target.value || 0;
                                                                                                        const rate = values.setup[index].setup_rate || 0;
                                                                                                        const total = newQuantity * rate;
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setSetUpMasterData((prevsetupData) => {
                                                                                                            const updatedsetupData = [...prevsetupData];
                                                                                                            updatedsetupData[index] = {
                                                                                                                ...updatedsetupData[index],
                                                                                                                setup_quantity: +e.target.value || 0,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedsetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = +newRate * quantity;
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setSetUpMasterData((prevsetupData) => {
                                                                                                                const updatedsetupData = [...prevsetupData];
                                                                                                                updatedsetupData[index] = {
                                                                                                                    ...updatedsetupData[index],
                                                                                                                    setup_rate: newRate,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedsetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                            </Col>
                            <Col className="col-6 ps-1">
                                {/* stitch count table card */}
                                <Card id="step6">
                                    <CardBody className="linear-table-card-body" id="step6">
                                        {getMasterTableLoadingState.isStitchLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Stitch Count</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            key={stitchCountKey}
                                            initialValues={{
                                                stichCount: getStitchCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th stitch-count-calculator-th">Stitch Count</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.stichCount.map((stichCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {stichCount.stitch_count_label}
                                                                                        {
                                                                                            index === 7 && (
                                                                                                <span>
                                                                                                    <i id="custom_charges" className={`bx ms-1 mb-2 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                    <UncontrolledTooltip placement="bottom" target="custom_charges" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            (index === 6) && (
                                                                                                <span>
                                                                                                    <i id="applique" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" />
                                                                                                    <UncontrolledTooltip placement="bottom" target="applique" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            (index === 5) && (
                                                                                                <span>
                                                                                                    <i id="dPuff" className="bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute search-info-icon" />
                                                                                                    <UncontrolledTooltip placement="bottom" target="dPuff" style={{ background: 'black' }}>
                                                                                                        This field needs to be garment quantity not stitch count
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                        // get the total quantity of the stith count object 
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity);
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            let total = rate * newQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value);
                                                                                                        setFieldValue(`stichCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = values.stichCount[index].stitch_count_rate || 0;
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity);
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (stichCount.stitch_count_rate * totalStitchCountQuantity) / 1000 * +e.target.value;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            let total = rate * newQuantity
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((newQuantity / 1000) * rate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.stitchCount) {
                                                                                                            let AppliqueTotal = (+getStitchCountData[6].stitch_count_rate * totalStitchCountQuantity) / 1000 * +getStitchCountData[6].quantity;
                                                                                                            setFieldValue(`stichCount[${6}].total`, AppliqueTotal === NaN ? 0 : AppliqueTotal || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[6] = {
                                                                                                                    ...updatedStitchCountData[6],
                                                                                                                    total: AppliqueTotal === NaN ? 0 : +(AppliqueTotal.toFixed(2)),
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`stichCount[${index}].stitch_count_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`stichCount[${index}].stitch_count_rate`, newRate, false);
                                                                                                        // get the total quantity of the stith count object 
                                                                                                        const stitchCountQuantities = values.stichCount
                                                                                                            .filter(item => item.stitch_count_label === masterTableLable.stitchCount)
                                                                                                            .map(item => item.quantity || 0);
                                                                                                        const totalStitchCountQuantity = stitchCountQuantities.reduce((sum, quantity) => sum + quantity || 0, 0);
                                                                                                        setStitchCount(totalStitchCountQuantity)
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.stichCount[index].quantity || 0;
                                                                                                        if (stichCount.stitch_count_label === masterTableLable.applique) {
                                                                                                            let multiplicationwithRate = (+newRate * +totalStitchCountQuantity) / 1000 * +stichCount.quantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, multiplicationwithRate || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: multiplicationwithRate,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else if (index === 5 || stichCount.stitch_count_label === masterTableLable.custom_charge) {
                                                                                                            const total = quantity * newRate;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: total,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        } else {
                                                                                                            const total = ((quantity / 1000) * newRate) * itemQuantity;
                                                                                                            setFieldValue(`stichCount[${index}].total`, total || 0);
                                                                                                            setStitchData((prevStitchCountData) => {
                                                                                                                const updatedStitchCountData = [...prevStitchCountData];
                                                                                                                updatedStitchCountData[index] = {
                                                                                                                    ...updatedStitchCountData[index],
                                                                                                                    quantity: quantity,
                                                                                                                    total: total,
                                                                                                                    stitch_count_rate: newRate
                                                                                                                };
                                                                                                                return updatedStitchCountData;
                                                                                                            });
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(values.stichCount.reduce((sum, garment) => sum + (garment.total || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* technical specification table card */}
                                <Card id="step8">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Technical Specification</th>
                                                            <th className="calculator-th"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                            <Formik
                                                initialValues={{
                                                    technicalSpecification: getTechnicalMasterData,
                                                }}
                                                render={({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <Table className="table align-middle mb-0 table-nowrap">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th className="calculator-th">Technical Specification</th>
                                                                        <th className="calculator-th"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="calculator-table-body">
                                                                    <FieldArray
                                                                        name="technicalSpecification"
                                                                        render={() => (
                                                                            <>
                                                                                <tr>
                                                                                    <td className="calculator-td">
                                                                                        Garments / Hour
                                                                                    </td>
                                                                                    <td className="calculator-td ">
                                                                                        {getGarmentPerHour.toFixed(2) || 0}
                                                                                    </td>
                                                                                </tr>
                                                                                {values && values.technicalSpecification && values.technicalSpecification.map((technicalSpecification, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            {technicalSpecification.technical_specification_label}<span className="text-danger">*</span>
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`technicalSpecification[${index}].technical_specification_quantity`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control editable-input"
                                                                                                        {...field}
                                                                                                        value={field.value || 0.00}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '').replace(/^0+/, '');
                                                                                                            setFieldValue(`technicalSpecification[${index}].technical_specification_quantity`, newRate || 0, false);
                                                                                                            setEmbroideryHeads(newRate);
                                                                                                            if (technicalSpecification.technical_specification_label !== 'Garments / Hour') {
                                                                                                                countGarmentsPerHourTechnicalSpecification(true);
                                                                                                            }
                                                                                                        }}
                                                                                                        onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Form>
                                                )}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                                {/* mark up table card */}
                                <Card className="markup-table-card">
                                    <CardBody className="linear-table-card-body">
                                        {getMasterTableLoadingState.isTechnicleMasterListIsLoading && getMasterTableLoadingState.isgarmentLoading && getMasterTableLoadingState.isPricingMasterIsLoading && getMasterTableLoadingState.isSetUpIsLoading && getMasterTableLoadingState.isStitchLoading && (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Markup</th>
                                                            <th className="calculasecondarytor-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                            <th className="calculator-th">Price/Shirt </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {!getMasterTableLoadingState.isTechnicleMasterListIsLoading && !getMasterTableLoadingState.isgarmentLoading && !getMasterTableLoadingState.isPricingMasterIsLoading && !getMasterTableLoadingState.isSetUpIsLoading && !getMasterTableLoadingState.isStitchLoading && (
                                            <Formik
                                                key={markupKey}
                                                initialValues={{ markupData: getMarkUpData, }} >
                                                {({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <FieldArray name="markupData">
                                                                {({ remove, push }) => (<>
                                                                    <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                        {/* Table Header */}
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th className="calculator-th">Markup</th>
                                                                                <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                <th className="calculator-th">Price/Shirt </th>
                                                                            </tr>
                                                                        </thead>
                                                                        {/* Table Body */}
                                                                        <tbody className="calculator-table-body">
                                                                            {values.markupData.map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.markup`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={`${Number(field.value || 0)}%`}
                                                                                                    onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                        onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newMarkUp = e.target.value.replace('%', '');
                                                                                                        let garmentTotal = getGarmentsData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let stitchCountTotal = getStitchCountData.reduce((sum, garment) => sum + (garment.total || 0), 0).toFixed(2);
                                                                                                        let ArtCollectionAndDigitizingFeeTotal = getSetUpMasterData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0).toFixed(2);
                                                                                                        let totalGrandTotal = +garmentTotal + +stitchCountTotal + +ArtCollectionAndDigitizingFeeTotal;
                                                                                                        let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                        setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                        setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                        setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                        setMarkupData((prevMarkupData) => {
                                                                                                            const updatedMarkupData = [...prevMarkupData];
                                                                                                            updatedMarkupData[index] = {
                                                                                                                ...updatedMarkupData[index],
                                                                                                                markup: +newMarkUp,
                                                                                                                totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                            };
                                                                                                            return updatedMarkupData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.totalCost`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`markupData[${index}].pricePerShirt`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger">*</span></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control auto-populate-input"
                                                                                        name="quantity"
                                                                                        placeholder="Enter quantity"
                                                                                        value={quoteSalesPercentage + '%'}
                                                                                        type="text"
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="calculator-td">
                                                                                    <NumericFormat className="form-control fill-in-input" onKeyUp={(e) => {
                                                                                        let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                        let numericPrice = parseFloat(price) || 0;
                                                                                        setQuoteSalesPrice(numericPrice);
                                                                                        if (itemQuantity !== undefined) {
                                                                                            let gross_sale = (numericPrice * +itemQuantity);
                                                                                            let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                            setfinalFigurescalculatorState(prevState => ({
                                                                                                ...prevState,
                                                                                                gross_sale: (gross_sale).toFixed(2),
                                                                                                net_profit: (net_profit).toFixed(2),
                                                                                                gross_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? 0 : ((gross_sale).toFixed(2) / getHoopingQuantity).toFixed(2),
                                                                                                net_hourly_rate: (+getHoopingQuantity === 0 || +getHoopingQuantity === 0.0) ? 0 : ((net_profit).toFixed(2) / getHoopingQuantity).toFixed(2)
                                                                                            }));
                                                                                        }
                                                                                    }}
                                                                                        thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                        onChange={(e) => {
                                                                                            let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                            let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                            setQuoteSalesPrice(numericPrice);
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        value={quoteSalesPrice} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>}
                    </div>
                }
                {/* row for screen-printing-quote-calc item name and quantity */}
                {(!isLinearView && activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) && <div className="grid-view">
                    {/* final figures cards */}
                    <Row className={isScrolled && !isQuickTour ? 'statistic-card-row-scroll px-2 statistic-card-row statistics-card-screen-printing-row' : isScrolled && !isQuickTour ? 'px-2 statistic-card-row statistics-card-screen-printing-row ' : 'px-2 statistics-card-screen-printing-row '} >
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step11">
                            <Card className="mini-stats-wid  header-input-card">
                                <CardBody className="result-card d-flex align-items-center">
                                    <div className="flex-grow-1 position-relative">
                                        <p className={`text-muted fw-medium mb-2 statistic-label`}>Cost/item</p>
                                        <h5 className={`mb-0 value statistic-value ${isExtraLargeDevice ? finalFigurescalculatorState.cost_per_item.length > 12 : finalFigurescalculatorState.cost_per_item.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.cost_per_item}>${finalFigurescalculatorState.cost_per_item}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet-alt font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step12">
                            <Card className="mini-stats-wid header-input-card">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Gross Cost</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_cost.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_cost}>${finalFigurescalculatorState.gross_cost}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet  font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step13">
                            <Card className="mini-stats-wid  header-input-card">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Gross Sale</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_sale.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_sale}>${finalFigurescalculatorState.gross_sale}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"bx bxs-shopping-bag font-size-20"}></i></span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step14">
                            <Card className="mini-stats-wid header-input-card ">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">Net profit</p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_profit.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_profit}>${finalFigurescalculatorState.net_profit}</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={"mdi mdi-card-plus font-size-20"}></i> </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step15">
                            <Card className="mini-stats-wid header-input-card ">
                                <CardBody className="result-card d-flex align-items-center py-0">
                                    <div className="flex-grow-1 position-relative">
                                        <p className="text-muted fw-medium mb-2 statistic-label">
                                            <span class="job_profit_margin_container">
                                                <i id="job_profit_margin" className={`bx me-2 mt-1 pt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing  info-icon`} />
                                                <UncontrolledTooltip placement="bottom" target="job_profit_margin" style={{ background: 'black' }}>
                                                    Recommended job profit margin % should be over 60-65% per job
                                                </UncontrolledTooltip>
                                            </span>
                                            Job Profit Margin %
                                        </p>
                                        <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.profit_margin_percentage.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={finalFigurescalculatorState.profit_margin_percentage + '%'}>{finalFigurescalculatorState.profit_margin_percentage}%</h5>
                                    </div>
                                    <div className="flex-shrink-0 align-self-center">
                                        <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle"><i className={" bx bx-money  font-size-20"} ></i> </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col className="col-12">
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <Formik initialValues={{ itemName: '', quantity: '' }}>
                                        {({ }) => (
                                            <form>
                                                <Row className="quote-info">
                                                    {/* item field */}
                                                    <Col className="col-md-6 col-lg-7 col-12" id="step2">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="form-label">Item<span className="text-danger"> *</span></Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="itemName"
                                                                placeholder="Enter item name"
                                                                onChange={(e) => { setItemName(e.target.value); }}
                                                                value={getItemName}
                                                                autoComplete="off"
                                                            />
                                                            {error && <div className="item-name-error-message">{error}</div>}
                                                        </FormGroup>
                                                    </Col>
                                                    {/* quantity field */}
                                                    <Col className="col-md-3 col-lg-3 col-12 mt-sm-0 mt-2 mt-sm-0" id="step3">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="form-label">Quantity<span className="text-danger"> *</span></Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="quantity"
                                                                placeholder="Enter item quantity"
                                                                onChange={(e) => {
                                                                    const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                    setitemQuantity(+cleanedValue);
                                                                }}
                                                                value={itemQuantity === undefined ? '' : itemQuantity}
                                                                onKeyPress={(e) => { const isValidInput = /^\d*\.?\d*$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }}
                                                                onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                onKeyUp={((e) => {
                                                                    const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                    setitemQuantity(+cleanedValue);
                                                                    let gross_cost = (finalFigurescalculatorState.cost_per_item * itemQuantity).toFixed(2);
                                                                    let gross_sale = (quotePrintingSalesPrice * itemQuantity).toFixed(2);
                                                                    let net_profit = (gross_sale - gross_cost).toFixed(2);
                                                                    setfinalFigurescalculatorState(prevState => ({
                                                                        ...prevState,
                                                                        gross_sale: gross_sale,
                                                                        net_profit: net_profit
                                                                    }));
                                                                    let markupdata = getPrintingMarkupData;
                                                                    markupdata.forEach(item => {
                                                                        item.pricePerShirt = itemQuantity === 0 ? 0 : +(item.totalCost / +e.target.value).toFixed(2);
                                                                    });
                                                                    setPrintingMarkupData(markupdata);
                                                                    laborPerHourQuantity(+cleanedValue, getPrintingJobCriteriaData[2].job_criteria_quantity, getPrintingJobCriteriaData[1].job_criteria_quantity, undefined)
                                                                })}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* tax field */}
                                                    <Col className={`col-md-3 col-12  d-grid col-lg-2 ${!isLinearView ? 'mt-3' : ''}`}>
                                                        <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={true} componentName={'quote-calculator'} customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid} selectedTaxInfo={selectedTaxInfo} shouldRefreshSelectedTaxInfo={getshouldRefreshSelectedTaxInfo} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className=" col-12  mt-2" id="description">
                                                        <FormGroup className="mb-0 form-group-margin-0">
                                                            <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Description</Label>
                                                            <input
                                                                className="form-control form-control-header"
                                                                name="itemName"
                                                                placeholder="Enter item description"
                                                                onChange={(e) => {
                                                                    setItemDescription(e.target.value);
                                                                }}
                                                                value={getItemDescription}
                                                                autoComplete="off"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </form>
                                        )}
                                    </Formik>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="px-1 mb-2 mb-md-0">
                        <Col className="col-xl-6 col-12 card-table">
                            {/* garments table card */}
                            <Card id="step5">
                                <CardBody>
                                    {/* skeleton loading for garments table */}
                                    {getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Garments</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th th-width">Total</th>
                                                        <th className="calculator-th th-width">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            garments: getPrintingGarmentData,
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th">Garments</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th th-width">Total</th>
                                                                <th className="calculator-th th-width">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="garments"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.garments.map((garment, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td">
                                                                                    {garment.garment_label}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {
                                                                                        garment.garment_label !== 'Shipping' && (
                                                                                            <Field
                                                                                                name={`garments[${index}].garment_quantity`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control fill-in-input"
                                                                                                        {...field}
                                                                                                        value={field.value || 0}
                                                                                                        onChange={(e) => {
                                                                                                            // find the total of quantity and rate based on quantity and update the state array
                                                                                                            const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                            setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                            const rate = parseFloat(values.garments[index]?.garment_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                            const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                            setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                            setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                updatedGarmentsData[index] = {
                                                                                                                    ...updatedGarmentsData[index],
                                                                                                                    garment_quantity: newQuantity,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedGarmentsData;
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`garments[${index}].total`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat
                                                                                                prefix="$"
                                                                                                decimalScale={2}
                                                                                                value={field.value || 0}
                                                                                                className={`form-control ${garment.garment_label === 'Shipping' ? 'fill-in-input' : 'auto-populate-input'}`}
                                                                                                readOnly={garment.garment_label !== 'Shipping'}
                                                                                                onValueChange={(values) => {
                                                                                                    const { floatValue: newRate } = values; // Extract the floating-point number
                                                                                                    setFieldValue(`garments[${index}].total`, newRate || 0, false);
                                                                                                    setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                        const updatedGarmentsData = [...prevGarmentsData];
                                                                                                        updatedGarmentsData[index] = {
                                                                                                            ...updatedGarmentsData[index],
                                                                                                            total: newRate || 0,
                                                                                                        };
                                                                                                        return updatedGarmentsData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {
                                                                                        garment.garment_label !== 'Shipping' && (
                                                                                            <Field
                                                                                                name={`garments[${index}].garment_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                        onChange={(e) => {
                                                                                                            // find the total of quantity and garment_rate based on rate and update the state
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                            const quantity = values.garments[index].garment_quantity || 0;
                                                                                                            const total = +newRate * quantity;
                                                                                                            setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                            setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                updatedGarmentsData[index] = {
                                                                                                                    ...updatedGarmentsData[index],
                                                                                                                    garment_rate: newRate,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedGarmentsData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td">
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input"
                                                                                    value={`$${Number(
                                                                                        Array.isArray(values.garments)
                                                                                            ? values.garments.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)
                                                                                            : 0
                                                                                    ).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td className="calculator-td"></td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* setup table card */}
                            {isSmallLaptopDevice && <Card className="d-xl-block d-none" id="step7">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isSetupLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <tr>
                                                    <th className="calculator-th">Setup</th>
                                                    <th className="calculator-th">Quantity</th>
                                                    <th className="calculator-th th-width">Total</th>
                                                    <th className=" calculator-th th-width">Rate</th>
                                                </tr>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        key={jobCriteriaKey}
                                        initialValues={{
                                            setup: getPrintingSetUpData,
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th setup-table-head">Setup</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th th-width">Total</th>
                                                                <th className="calculator-th th-width">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                key={laborPerHourKey}
                                                                name="setup"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.setup.map((setup, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td setup-td">
                                                                                    {setup.setup_label}
                                                                                    {
                                                                                        index === 6 && (
                                                                                            <span>
                                                                                                <i id="re-setup-fee" className={`bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing info-icon`} />
                                                                                                <UncontrolledTooltip placement="bottom" target="re-setup-fee" style={{ background: 'black' }}>
                                                                                                    RESETUP FEE: NUMBER OF TOTAL SCREENS BEING USED / SETUP IN THE JOB.<br />

                                                                                                    Only for jobs that we still have screens made for.
                                                                                                </UncontrolledTooltip>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`setup[${index}].setup_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className={`form-control  ${setup.setup_label === masterTableLable.laborPerHour ? 'auto-populate-input' : (setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') ? 'editable-input' : 'fill-in-input'} `}
                                                                                                {...field}
                                                                                                value={(field.value || 0).toString()}
                                                                                                onChange={(e) => {
                                                                                                    // find the total of quantity and rate based on quantity and update the state array
                                                                                                    const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                    setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                    const rate = parseFloat(values.setup[index]?.setup_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                    const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setPrintingSetupData((prevSetupData) => {
                                                                                                        const updatedSetupData = [...prevSetupData];
                                                                                                        updatedSetupData[index] = {
                                                                                                            ...updatedSetupData[index],
                                                                                                            setup_quantity: newQuantity,
                                                                                                            total: +total || 0,
                                                                                                        };
                                                                                                        return updatedSetupData;
                                                                                                    });
                                                                                                }}
                                                                                                autoCorrect="off"
                                                                                                disabled={setup.setup_label === masterTableLable.laborPerHour}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`setup[${index}].setup_rate`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                onChange={(e) => {
                                                                                                    const newRate = e.target.value.replace('$', '');
                                                                                                    setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                    const quantity = values.setup[index].setup_quantity || 0;
                                                                                                    const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setPrintingSetupData((prevSetupData) => {
                                                                                                        const updatedSetupData = [...prevSetupData];
                                                                                                        updatedSetupData[index] = {
                                                                                                            ...updatedSetupData[index],
                                                                                                            setup_rate: +newRate,
                                                                                                            total: total,
                                                                                                        };
                                                                                                        return updatedSetupData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td" colSpan={2}>
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                    value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>}
                            {/* job criteria specification table card */}
                            {
                                isSmallLaptopDevice &&
                                <Card id="artTable" className="markup-table-card art-table-card">
                                    <CardBody>
                                        {getScreenPrintingMasterTableLoadingStatus.isArtTableLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Art</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                artCount: getPrintingArtCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th art-count-head">Art</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.artCount.map((artCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {artCount.art_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`artCount[${index}].art_quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.artCount[index]?.art_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                art_quantity: newQuantity,
                                                                                                                total: total || 0,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`artCount[${index}].art_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.artCount[index].art_quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                art_rate: +newRate,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.artCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                            }
                        </Col>
                        <Col className="col-xl-6 col-12 card-table">
                            {/* stitch count table card */}
                            <Card id="step6">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isInkLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Ink</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th">Total</th>
                                                        <th className="calculator-th">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        key={stitchCountKey}
                                        initialValues={{
                                            inkCount: getPrintingInkCountData
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th ink-count-head">Ink</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th">Total</th>
                                                                <th className="calculator-th">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="garments"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.inkCount.map((inkCount, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td ink-charges-label">
                                                                                    {inkCount.ink_label}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`inkCount[${index}].quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control fill-in-input"
                                                                                                {...field}
                                                                                                value={field.value || 0}
                                                                                                onChange={(e) => {
                                                                                                    const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                    setFieldValue(`inkCount[${index}].quantity`, newQuantity, false);
                                                                                                    // base don the value of the quantity find the total of rate and quantity
                                                                                                    const rate = parseFloat(values.inkCount[index]?.ink_rate) || 0;
                                                                                                    const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                    setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                    setPrintingInkCountData((prevGarmentsData) => {
                                                                                                        const updatedInkTable = [...prevGarmentsData];
                                                                                                        updatedInkTable[index] = {
                                                                                                            ...updatedInkTable[index],
                                                                                                            quantity: newQuantity,
                                                                                                            total: total || 0,
                                                                                                        };
                                                                                                        return updatedInkTable;
                                                                                                    });
                                                                                                }}
                                                                                                autoComplete="off"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`inkCount[${index}].total`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control auto-populate-input"
                                                                                                {...field}
                                                                                                readOnly
                                                                                                min={0}
                                                                                                value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`inkCount[${index}].ink_rate`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                onChange={(e) => {
                                                                                                    const newRate = e.target.value.replace('$', '');
                                                                                                    setFieldValue(`inkCount[${index}].ink_rate`, newRate, false);
                                                                                                    // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                    const quantity = values.inkCount[index].quantity || 0;
                                                                                                    const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                    setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                    setPrintingInkCountData((prevInkData) => {
                                                                                                        const updatedPrintingData = [...prevInkData];
                                                                                                        updatedPrintingData[index] = {
                                                                                                            ...updatedPrintingData[index],
                                                                                                            ink_rate: +newRate,
                                                                                                            total: +total || 0,
                                                                                                        };
                                                                                                        return updatedPrintingData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td" colSpan={2}>
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                    value={`$${Number(values.inkCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* setup table card */}
                            <Card className="d-xl-none d-block" id="step7">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isSetupLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Setup</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th">Total</th>
                                                        <th className="calculator-th">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            setup: getPrintingSetUpData,
                                        }}

                                        key={jobCriteriaKey}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th setup-table-head">Setup</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th">Total</th>
                                                                <th className="calculator-th">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="setup"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.setup.map((setup, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td setup-td">
                                                                                    {setup.setup_label}
                                                                                    {
                                                                                        index === 6 && (
                                                                                            <span>
                                                                                                <i id="re-setup-fee" className={`bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                <UncontrolledTooltip placement="bottom" target="re-setup-fee" style={{ background: 'black' }}>
                                                                                                    RESETUP FEE: NUMBER OF TOTAL SCREENS BEING USED / SETUP IN THE JOB.<br />

                                                                                                    Only for jobs that we still have screens made for.
                                                                                                </UncontrolledTooltip>
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`setup[${index}].setup_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className={`form-control  ${setup.setup_label === masterTableLable.laborPerHour ? 'auto-populate-input' : (setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') ? 'editable-input' : 'fill-in-input'} `}
                                                                                                {...field}
                                                                                                value={(field.value || 0).toString()}
                                                                                                onChange={(e) => {
                                                                                                    // find the total of quantity and rate based on quantity and update the state array
                                                                                                    const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                    setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                    const rate = parseFloat(values.setup[index]?.setup_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                    const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                    setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                    setPrintingSetupData((prevSetupData) => {
                                                                                                        const updatedSetupData = [...prevSetupData];
                                                                                                        updatedSetupData[index] = {
                                                                                                            ...updatedSetupData[index],
                                                                                                            setup_quantity: newQuantity,
                                                                                                            total: +total || 0,
                                                                                                        };
                                                                                                        return updatedSetupData;
                                                                                                    });
                                                                                                }}
                                                                                                autoCorrect="off"
                                                                                                disabled={setup.setup_label === masterTableLable.laborPerHour}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    {/* Conditionally render Rate field for RPM and Location */}
                                                                                    {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                        const quantity = values.setup[index].setup_quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setPrintingSetupData((prevSetupData) => {
                                                                                                            const updatedSetupData = [...prevSetupData];
                                                                                                            updatedSetupData[index] = {
                                                                                                                ...updatedSetupData[index],
                                                                                                                setup_rate: +newRate,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedSetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                    disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td" colSpan={2}>
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                    value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            <Card className="d-xl-none d-block" id="artTable">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isArtTableLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Art</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th">Total</th>
                                                        <th className="calculator-th">Rate</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(2)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (<Formik
                                        initialValues={{
                                            artCount: getPrintingArtCountData
                                        }}
                                        render={({ values, setFieldValue }) => (
                                            <Form>
                                                <div className="table-responsive">
                                                    <Table className="table align-middle mb-0 table-nowrap">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="calculator-th art-count-head">Art</th>
                                                                <th className="calculator-th">Quantity</th>
                                                                <th className="calculator-th">Total</th>
                                                                <th className="calculator-th">Rate</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <FieldArray
                                                                name="garments"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {values.artCount.map((artCount, index) => (
                                                                            <tr key={index}>
                                                                                <td className="calculator-td ink-charges-label">
                                                                                    {artCount.art_label}
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`artCount[${index}].art_quantity`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control fill-in-input"
                                                                                                {...field}
                                                                                                value={field.value || 0}
                                                                                                onChange={(e) => {
                                                                                                    const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                    setFieldValue(`artCount[${index}].art_quantity`, newQuantity, false);
                                                                                                    // base don the value of the quantity find the total of rate and quantity
                                                                                                    const rate = parseFloat(values.artCount[index]?.art_rate) || 0;
                                                                                                    const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                    setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                    setPrintingArtCountData((prevGarmentsData) => {
                                                                                                        const updatedInkTable = [...prevGarmentsData];
                                                                                                        updatedInkTable[index] = {
                                                                                                            ...updatedInkTable[index],
                                                                                                            art_quantity: newQuantity,
                                                                                                            total: total || 0,
                                                                                                        };
                                                                                                        return updatedInkTable;
                                                                                                    });
                                                                                                }}
                                                                                                autoComplete="off"
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`artCount[${index}].total`}
                                                                                        type="number"
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                className="form-control auto-populate-input"
                                                                                                {...field}
                                                                                                readOnly
                                                                                                min={0}
                                                                                                value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td">
                                                                                    <Field
                                                                                        name={`artCount[${index}].art_rate`}
                                                                                        render={({ field }) => (
                                                                                            <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                onChange={(e) => {
                                                                                                    const newRate = e.target.value.replace('$', '');
                                                                                                    setFieldValue(`artCount[${index}].art_rate`, newRate, false);
                                                                                                    // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                    const quantity = values.artCount[index].art_quantity || 0;
                                                                                                    const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                    setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                    setPrintingArtCountData((prevInkData) => {
                                                                                                        const updatedPrintingData = [...prevInkData];
                                                                                                        updatedPrintingData[index] = {
                                                                                                            ...updatedPrintingData[index],
                                                                                                            art_rate: +newRate,
                                                                                                            total: +total || 0,
                                                                                                        };
                                                                                                        return updatedPrintingData;
                                                                                                    });
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (e.key === '-' || e.keyCode === 189) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td className="calculator-td"></td>
                                                                            <td className="total-value calculator-td">Total</td>
                                                                            <td className="calculator-td" colSpan={2}>
                                                                                <input
                                                                                    className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                    value={`$${Number(values.artCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            />
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Form>
                                        )}
                                    />)}
                                </CardBody>
                            </Card>
                            {/* job criteria specification table card */}
                            <Card id="step8">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th job-criteria-table">Job Criteria</th>
                                                        <th className="calculator-th"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <Formik
                                            key={laborPerHourKey}
                                            initialValues={{
                                                jobCriteria: getPrintingJobCriteriaData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Job Crieteria</th>
                                                                    <th className="calculator-th"> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="jobCriteria"
                                                                    render={() => (
                                                                        <>
                                                                            {values && values.jobCriteria && values.jobCriteria.map((jobCriteria, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {jobCriteria.job_criteria_label} {(jobCriteria.job_criteria_label === masterTableLable.of_location || jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) && (<span class="text-danger">*</span>)}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`jobCriteria[${index}].job_criteria_quantity`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat
                                                                                                    allowNegative={false}
                                                                                                    decimalScale={0}
                                                                                                    fixedDecimalScale={false}
                                                                                                    value={field.value || 0}
                                                                                                    className={`form-control ${jobCriteria.job_criteria_label === masterTableLable.shirt_in_job ? 'auto-populate-input' : 'editable-input'}`}
                                                                                                    onValueChange={({ value }) => {
                                                                                                        const newRate = value || 0;
                                                                                                        setFieldValue(`jobCriteria[${index}].job_criteria_quantity`, newRate, false);

                                                                                                        setPrintingJobCriteria((prevJobCriteriaData) => {
                                                                                                            const updatedJobCriteria = [...prevJobCriteriaData];
                                                                                                            updatedJobCriteria[index] = {
                                                                                                                ...updatedJobCriteria[index],
                                                                                                                job_criteria_quantity: +newRate || 0,
                                                                                                            };
                                                                                                            return updatedJobCriteria;
                                                                                                        });

                                                                                                        const clonedJobCriteriaData = [...getPrintingJobCriteriaData];
                                                                                                        if (jobCriteria.job_criteria_label === masterTableLable.of_location) {
                                                                                                            laborPerHourQuantity(
                                                                                                                itemQuantity,
                                                                                                                newRate,
                                                                                                                clonedJobCriteriaData[1].job_criteria_quantity,
                                                                                                                setFieldValue
                                                                                                            );
                                                                                                        } else if (jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) {
                                                                                                            laborPerHourQuantity(
                                                                                                                itemQuantity,
                                                                                                                clonedJobCriteriaData[2].job_criteria_quantity,
                                                                                                                newRate,
                                                                                                                setFieldValue
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                    disabled={jobCriteria.job_criteria_label === masterTableLable.shirt_in_job}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                        onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                            {/* mark up table card */}
                            <Card className="markup-table-card">
                                <CardBody>
                                    {getScreenPrintingMasterTableLoadingStatus.isInkLoading && getScreenPrintingMasterTableLoadingStatus.isArtTableLoading && getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading && getScreenPrintingMasterTableLoadingStatus.isSetupLoading && getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                        <div className="table-responsive">
                                            <table className="table align-middle mb-0 table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="calculator-th">Markup</th>
                                                        <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                        <th className="calculator-th">Price/Shirt </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {[...Array(5)].map((_, index) => (
                                                        <tr key={index}>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                            </td>
                                                            <td className="calculator-td">
                                                                <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <Formik
                                            key={printingMarkupKey}
                                            initialValues={{ markupData: getPrintingMarkupData, }} >
                                            {({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <FieldArray name="markupData">
                                                            {({ remove, push }) => (
                                                                <>
                                                                    <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                        {/* Table Header */}
                                                                        <thead className="table-light">
                                                                            <tr>
                                                                                <th className="calculator-th">Markup</th>
                                                                                <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                <th className="calculator-th">Price/Shirt </th>
                                                                            </tr>
                                                                        </thead>
                                                                        {/* Table Body */}
                                                                        <tbody>
                                                                            {values.markupData.map((data, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.markup`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={`${Number(field.value || 0)}%`}
                                                                                                    onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                        onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                    }}
                                                                                                    onKeyUp={(e) => {
                                                                                                        const newMarkUp = e.target.value.replace('%', '');
                                                                                                        let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                                                                                                        let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
                                                                                                        let setupTotalData = getPrintingSetUpData.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0);
                                                                                                        let totalGrandTotal = (+garmentTotal + +inkCountDataTotal + +setupTotalData).toFixed(2);
                                                                                                        let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                        setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                        setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                        setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                        setPrintingMarkupData((prevMarkupData) => {
                                                                                                            const updatedMarkupData = [...prevMarkupData];
                                                                                                            updatedMarkupData[index] = {
                                                                                                                ...updatedMarkupData[index],
                                                                                                                markup: +newMarkUp,
                                                                                                                totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                            };
                                                                                                            return updatedMarkupData;
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            type="text"
                                                                                            name={`markupData.${index}.totalCost`}
                                                                                            className="form-control auto-populate-input"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`markupData[${index}].pricePerShirt`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    readOnly
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger"> *</span></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="calculator-td">
                                                                                    <input
                                                                                        className="form-control auto-populate-input"
                                                                                        name="quantity"
                                                                                        placeholder="Enter quantity"
                                                                                        value={isNaN(quoteSalesPercentage) ? 0 + '%' : quoteSalesPercentage + '%'}
                                                                                        type="text"
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="calculator-td">
                                                                                    <NumericFormat className="form-control fill-in-input"
                                                                                        onKeyUp={(e) => {
                                                                                            let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                            let numericPrice = parseFloat(price) || 0;
                                                                                            setQuotePrintingSalesPrice(numericPrice);
                                                                                            if (itemQuantity !== undefined) {
                                                                                                let gross_sale = (numericPrice * +itemQuantity);
                                                                                                let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                                let profit_margin = gross_sale === 0 ? 0 : ((gross_sale - finalFigurescalculatorState.gross_cost) / gross_sale) * 100;
                                                                                                setfinalFigurescalculatorState(prevState => ({
                                                                                                    ...prevState,
                                                                                                    gross_sale: (gross_sale).toFixed(2),
                                                                                                    net_profit: (net_profit).toFixed(2),
                                                                                                    profit_margin_percentage: (profit_margin).toFixed(2)
                                                                                                }));
                                                                                            }
                                                                                        }}
                                                                                        thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                        onChange={(e) => {
                                                                                            let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                            let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                            setQuotePrintingSalesPrice(numericPrice);
                                                                                        }}
                                                                                        onKeyDown={(e) => {
                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                        value={quotePrintingSalesPrice} />
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                }
                {(isLinearView && activeTabview === CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR) &&
                    <div className="linear-view">
                        {/* final figures cards */}
                        <Row className={isScrolled ? 'statistic-card-row-scroll px-2 statistic-card-row statistics-card-screen-printing-row ' : 'px-2 statistic-card-row statistics-card-screen-printing-row '} >
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step11">
                                <Card className="mini-stats-wid  header-input-card">
                                    <CardBody className="result-card d-flex align-items-center">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Cost/item</p>
                                            <h5 className={`mb-0 value statistic-value ${isExtraLargeDevice ? finalFigurescalculatorState.cost_per_item.length > 12 : finalFigurescalculatorState.cost_per_item.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.cost_per_item}>${finalFigurescalculatorState.cost_per_item}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet-alt font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step12">
                                <Card className="mini-stats-wid header-input-card">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Gross Cost</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_cost.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_cost}>${finalFigurescalculatorState.gross_cost}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-wallet  font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step13">
                                <Card className="mini-stats-wid  header-input-card">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Gross Sale</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.gross_sale.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.gross_sale}>${finalFigurescalculatorState.gross_sale}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"bx bxs-shopping-bag font-size-20"}></i></span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step14">
                                <Card className="mini-stats-wid header-input-card ">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className={`text-muted fw-medium ${isLinearView ? 'mb-1' : 'mb-2'} statistic-label`}>Net profit</p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.net_profit.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={'$' + finalFigurescalculatorState.net_profit}>${finalFigurescalculatorState.net_profit}</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={"mdi mdi-card-plus font-size-20"}></i> </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} xl={2} className="col-6 result-card-col px-1" id="step15">
                                <Card className="mini-stats-wid header-input-card ">
                                    <CardBody className="result-card d-flex align-items-center py-0">
                                        <div className="flex-grow-1 position-relative">
                                            <p className="text-muted fw-medium mb-2 statistic-label">
                                                <span class="job_profit_margin_container">
                                                    <i id="job_profit_margin" className={`bx mt-1 pt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                    <UncontrolledTooltip placement="bottom" target="job_profit_margin" style={{ background: 'black' }}>
                                                        Recommended job profit margin % should be over 60-65% per job
                                                    </UncontrolledTooltip>
                                                </span> Job Profit Margin %
                                            </p>
                                            <h5 className={`mb-0 value statistic-value ${finalFigurescalculatorState.profit_margin_percentage.length > 6 ? 'quote_ellipsis' : ''}`} data-toggle='tooltip' data-title={finalFigurescalculatorState.profit_margin_percentage + '%'}>{finalFigurescalculatorState.profit_margin_percentage}%</h5>
                                        </div>
                                        <div className="flex-shrink-0 align-self-center">
                                            <div className="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle"><i className={" bx bx-money  font-size-20"} ></i> </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-12">
                                <Card className="mini-stats-wid">
                                    <CardBody className="item-name-quantity-body-card">
                                        <Formik initialValues={{ itemName: '', quantity: '' }}>
                                            {({ }) => (
                                                <form>
                                                    <Row className="quote-info">
                                                        {/* item field */}
                                                        <Col className="col-md-6 col-xl-3 col-12" id="step2">
                                                            <FormGroup className="mb-0 form-group-margin-0">
                                                                <div className="d-flex">
                                                                    <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Item<span className="text-danger"> *</span></Label>
                                                                    <input
                                                                        className="form-control form-control-header"
                                                                        name="itemName"
                                                                        placeholder="Enter item name"
                                                                        onChange={(e) => { setItemName(e.target.value); }}
                                                                        value={getItemName}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                                {error && <div className="item-name-error-message">{error}</div>}
                                                            </FormGroup>
                                                        </Col>
                                                        {/* quantity field */}
                                                        <Col className="col-md-6 col-xl-2 col-12 mt-md-0 mt-2 mt-sm-0" id="step3">
                                                            <FormGroup className="mb-0 form-group-margin-0 d-flex">
                                                                <Label className="mb-0 form-label d-flex align-items-center me-2">Quantity<span className="text-danger"> * </span></Label>
                                                                <input
                                                                    className="form-control form-control-header"
                                                                    name="quantity"
                                                                    placeholder="Enter item quantity"
                                                                    onChange={(e) => {
                                                                        const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                        setitemQuantity(+cleanedValue);
                                                                    }}
                                                                    value={itemQuantity === undefined ? '' : itemQuantity}
                                                                    onKeyPress={(e) => { const isValidInput = /^\d*\.?\d*$/.test(e.key); if (!isValidInput) { e.preventDefault(); } }}
                                                                    onKeyDown={(e) => { onKeyDownAllowUserToAddOnlyNumber(e); }}
                                                                    onKeyUp={((e) => {
                                                                        const cleanedValue = e.target.value.replace(/^0+/, '');
                                                                        setitemQuantity(+cleanedValue);
                                                                        let gross_cost = (finalFigurescalculatorState.cost_per_item * itemQuantity).toFixed(2);
                                                                        let gross_sale = (quotePrintingSalesPrice * itemQuantity).toFixed(2)
                                                                        let net_profit = (gross_sale - gross_cost).toFixed(2);
                                                                        setfinalFigurescalculatorState(prevState => ({
                                                                            ...prevState,
                                                                            gross_sale: gross_sale,
                                                                            net_profit: net_profit
                                                                        }));
                                                                        let markupdata = getPrintingMarkupData;
                                                                        markupdata.forEach(item => {
                                                                            item.pricePerShirt = itemQuantity === 0 ? 0 : +(item.totalCost / +e.target.value).toFixed(2);
                                                                        });
                                                                        setPrintingMarkupData(markupdata);
                                                                        laborPerHourQuantity(+cleanedValue, getPrintingJobCriteriaData[2].job_criteria_quantity, getPrintingJobCriteriaData[1].job_criteria_quantity, undefined)
                                                                    })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="col-md-9  col-xl-5 col-12 mt-md-2 mt-xl-0" id="description">
                                                            <FormGroup className="mb-0 form-group-margin-0">
                                                                <div className="d-flex">
                                                                    <Label className="mbgetItemName-0 mb-0 form-label d-flex align-items-center me-2">Description</Label>
                                                                    <input
                                                                        className="form-control form-control-header"
                                                                        name="itemName"
                                                                        placeholder="Enter item description"
                                                                        onChange={(e) => {
                                                                            setItemDescription(e.target.value);
                                                                        }}
                                                                        value={getItemDescription}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {/* add tax */}
                                                        <Col className="col-md-3 col-12  col-lg-3 col-xl-2 mt-md-2 mt-xl-0 ">
                                                            <TaxSelect options={getTaxInfo} updatedTaxInfo={updatedTaxInfo} shouldShowSelectedTax={true} componentName={'quote-calculator'} customer_shop_tax_master_uuid={get_customer_shop_tax_master_uuid} selectedTaxInfo={selectedTaxInfo} shouldRefreshSelectedTaxInfo={getshouldRefreshSelectedTaxInfo} />
                                                        </Col>
                                                    </Row>
                                                </form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* for laptop device */}
                        {isSmallLaptopDevice && <Row className="px-2 pb-3 d-none d-xl-flex">
                            <Col className="col-xl-3 col-12 card-table screen-printing-table" >
                                {/* garments table card */}
                                <Card id="step5" className="d-none d-xl-flex">
                                    <CardBody className="linear-table-card-body">
                                        {/* skeleton loading for garments table */}
                                        {getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Garments</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th th-width">Total</th>
                                                            <th className="calculator-th th-width">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                garments: getPrintingGarmentData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Garments</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.garments.map((garment, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {garment.garment_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {
                                                                                            garment.garment_label !== 'Shipping' && (
                                                                                                <Field
                                                                                                    name={`garments[${index}].garment_quantity`}
                                                                                                    type="number"
                                                                                                    render={({ field }) => (
                                                                                                        <input
                                                                                                            className="form-control fill-in-input"
                                                                                                            {...field}
                                                                                                            value={field.value || 0}
                                                                                                            onChange={(e) => {
                                                                                                                // find the total of quantity and rate based on quantity and update the state array
                                                                                                                const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                                setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                                const rate = parseFloat(values.garments[index]?.garment_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                                const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                                setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                                setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                    const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                    updatedGarmentsData[index] = {
                                                                                                                        ...updatedGarmentsData[index],
                                                                                                                        garment_quantity: newQuantity,
                                                                                                                        total: total,
                                                                                                                    };
                                                                                                                    return updatedGarmentsData;
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].total`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat
                                                                                                    prefix="$"
                                                                                                    decimalScale={2}
                                                                                                    value={field.value || 0}
                                                                                                    className={`form-control ${garment.garment_label === 'Shipping' ? 'fill-in-input' : 'auto-populate-input'}`}
                                                                                                    readOnly={garment.garment_label !== 'Shipping'}
                                                                                                    onValueChange={(values) => {
                                                                                                        const { floatValue: newRate } = values; // Extract the floating-point number
                                                                                                        setFieldValue(`garments[${index}].total`, newRate || 0, false);
                                                                                                        setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                total: newRate || 0,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {
                                                                                            garment.garment_label !== 'Shipping' && (
                                                                                                <Field
                                                                                                    name={`garments[${index}].garment_rate`}
                                                                                                    render={({ field }) => (
                                                                                                        <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                            onChange={(e) => {
                                                                                                                // find the total of quantity and garment_rate based on rate and update the state
                                                                                                                const newRate = e.target.value.replace('$', '');
                                                                                                                setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                                const quantity = values.garments[index].garment_quantity || 0;
                                                                                                                const total = +newRate * quantity;
                                                                                                                setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                                setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                    const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                    updatedGarmentsData[index] = {
                                                                                                                        ...updatedGarmentsData[index],
                                                                                                                        garment_rate: newRate,
                                                                                                                        total: total,
                                                                                                                    };
                                                                                                                    return updatedGarmentsData;
                                                                                                                });
                                                                                                            }}
                                                                                                            onKeyDown={(e) => {
                                                                                                                if (e.key === '-' || e.keyCode === 189) {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(
                                                                                            Array.isArray(values.garments)
                                                                                                ? values.garments.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)
                                                                                                : 0
                                                                                        ).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-xl-3 col-12 card-table screen-printing-table">
                                {/* stitch count table card */}
                                <Card id="step6">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isInkLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Ink</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            key={stitchCountKey}
                                            initialValues={{
                                                inkCount: getPrintingInkCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th ink-count-calculator-th">Ink</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.inkCount.map((inkCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {inkCount.ink_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`inkCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.inkCount[index]?.ink_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                        setPrintingInkCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].ink_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`inkCount[${index}].ink_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.inkCount[index].quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2) || 0;
                                                                                                        setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                        setPrintingInkCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                ink_rate: +newRate,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.inkCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* setup table card */}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isSetupLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Setup</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getPrintingSetUpData,
                                            }}

                                            key={jobCriteriaKey}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th setup-table-head">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td setup-td">
                                                                                        {setup.setup_label}
                                                                                        {
                                                                                            index === 6 && (
                                                                                                <span>
                                                                                                    <i id="re-setup-fee" className={`bx ms-1 mt-2 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                    <UncontrolledTooltip placement="bottom" target="re-setup-fee" style={{ background: 'black' }}>
                                                                                                        RESETUP FEE: NUMBER OF TOTAL SCREENS BEING USED / SETUP IN THE JOB.<br />

                                                                                                        Only for jobs that we still have screens made for.
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.laborPerHour ? 'auto-populate-input' : (setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') ? 'editable-input' : 'fill-in-input'}`}

                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and rate based on quantity and update the state array
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        const rate = parseFloat(values.setup[index]?.setup_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setPrintingSetupData((prevSetupData) => {
                                                                                                            const updatedSetupData = [...prevSetupData];
                                                                                                            updatedSetupData[index] = {
                                                                                                                ...updatedSetupData[index],
                                                                                                                setup_quantity: newQuantity,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedSetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.laborPerHour}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setPrintingSetupData((prevSetupData) => {
                                                                                                                const updatedSetupData = [...prevSetupData];
                                                                                                                updatedSetupData[index] = {
                                                                                                                    ...updatedSetupData[index],
                                                                                                                    setup_rate: +newRate,
                                                                                                                    total: total,
                                                                                                                };
                                                                                                                return updatedSetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block markup-table-card art-table-card" id="artTable">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isArtTableLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Art</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                artCount: getPrintingArtCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th art-count-head">Art</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.artCount.map((artCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {artCount.art_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`artCount[${index}].art_quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.artCount[index]?.art_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                art_quantity: newQuantity,
                                                                                                                total: total || 0,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`artCount[${index}].art_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.artCount[index].art_quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                art_rate: +newRate,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.artCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                            </Col>
                            <Col className="col-xl-3 col-12 card-table screen-printing-table">
                                {/* setup table card */}
                                {isSmallLaptopDevice && <Card className="d-xl-block d-none setup-table-card" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isSetupLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <tr>
                                                        <th className="calculator-th">Setup</th>
                                                        <th className="calculator-th">Quantity</th>
                                                        <th className="calculator-th th-width">Total</th>
                                                        <th className=" calculator-th th-width">Rate</th>
                                                    </tr>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getPrintingSetUpData,
                                            }}
                                            key={jobCriteriaKey}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th setup-table-head">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td setup-td">
                                                                                        {setup.setup_label}
                                                                                        {
                                                                                            index === 6 && (
                                                                                                <span>
                                                                                                    <i id="re-setup-fee" className={`bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                    <UncontrolledTooltip placement="bottom" target="re-setup-fee" style={{ background: 'black' }}>
                                                                                                        RESETUP FEE: NUMBER OF TOTAL SCREENS BEING USED / SETUP IN THE JOB.<br />

                                                                                                        Only for jobs that we still have screens made for.
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.laborPerHour ? 'auto-populate-input' : (setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') ? 'editable-input' : 'fill-in-input'}`}

                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and rate based on quantity and update the state array
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        const rate = parseFloat(values.setup[index]?.setup_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setPrintingSetupData((prevSetupData) => {
                                                                                                            const updatedSetupData = [...prevSetupData];
                                                                                                            updatedSetupData[index] = {
                                                                                                                ...updatedSetupData[index],
                                                                                                                setup_quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedSetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.laborPerHour}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setPrintingSetupData((prevSetupData) => {
                                                                                                                const updatedSetupData = [...prevSetupData];
                                                                                                                updatedSetupData[index] = {
                                                                                                                    ...updatedSetupData[index],
                                                                                                                    setup_rate: +newRate,
                                                                                                                    total: +total || 0,
                                                                                                                };
                                                                                                                return updatedSetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                                {/* technical specification table card */}
                                <Card id="step8">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th job-criteria-table ">Job Criteria</th>
                                                            <th className="calculator-th"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td calculator-tech-skeleton">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td calculator-tech-skeleton">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Formik
                                                key={laborPerHourKey}
                                                initialValues={{
                                                    jobCriteria: getPrintingJobCriteriaData,
                                                }}
                                                render={({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <Table className="table align-middle mb-0 table-nowrap">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th className="calculator-th job-criteria-table ">Job Criteria</th>
                                                                        <th className="calculator-th"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="calculator-table-body">
                                                                    <FieldArray
                                                                        name="jobCriteria"
                                                                        render={() => (
                                                                            <>
                                                                                {values && values.jobCriteria && values.jobCriteria.map((jobCriteria, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            {jobCriteria.job_criteria_label} {(jobCriteria.job_criteria_label === masterTableLable.of_location || jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) && (<span class="text-danger">*</span>)}
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`jobCriteria[${index}].job_criteria_quantity`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat
                                                                                                        allowNegative={false}
                                                                                                        decimalScale={0}
                                                                                                        fixedDecimalScale={false}
                                                                                                        value={field.value || 0}
                                                                                                        className={`form-control ${jobCriteria.job_criteria_label === masterTableLable.shirt_in_job ? 'auto-populate-input' : 'editable-input'}`}
                                                                                                        onValueChange={({ value }) => {
                                                                                                            const newRate = value || 0;
                                                                                                            setFieldValue(`jobCriteria[${index}].job_criteria_quantity`, newRate, false);

                                                                                                            setPrintingJobCriteria((prevJobCriteriaData) => {
                                                                                                                const updatedJobCriteria = [...prevJobCriteriaData];
                                                                                                                updatedJobCriteria[index] = {
                                                                                                                    ...updatedJobCriteria[index],
                                                                                                                    job_criteria_quantity: +newRate || 0,
                                                                                                                };
                                                                                                                return updatedJobCriteria;
                                                                                                            });

                                                                                                            const clonedJobCriteriaData = [...getPrintingJobCriteriaData];
                                                                                                            if (jobCriteria.job_criteria_label === masterTableLable.of_location) {
                                                                                                                laborPerHourQuantity(
                                                                                                                    itemQuantity,
                                                                                                                    newRate,
                                                                                                                    clonedJobCriteriaData[1].job_criteria_quantity,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                            } else if (jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) {
                                                                                                                laborPerHourQuantity(
                                                                                                                    itemQuantity,
                                                                                                                    clonedJobCriteriaData[2].job_criteria_quantity,
                                                                                                                    newRate,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={jobCriteria.job_criteria_label === masterTableLable.shirt_in_job}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                            onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Form>
                                                )}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-xl-3 col-12 card-table screen-printing-table">
                                {/* Art table card */}
                                <Card id="artTable"  >
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isArtTableLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Art</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                artCount: getPrintingArtCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th art-count-head">Art</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.artCount.map((artCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {artCount.art_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`artCount[${index}].art_quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.artCount[index]?.art_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                art_quantity: newQuantity,
                                                                                                                total: total || 0,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`artCount[${index}].art_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.artCount[index].art_quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                art_rate: +newRate,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.artCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* mark up table card */}
                                <Card className="markup-table-card">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isInkLoading && getScreenPrintingMasterTableLoadingStatus.isArtTableLoading && getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading && getScreenPrintingMasterTableLoadingStatus.isSetupLoading && getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Markup</th>
                                                            <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                            <th className="calculator-th">Price/Shirt </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Formik
                                                key={printingMarkupKey}
                                                initialValues={{ markupData: getPrintingMarkupData, }} >
                                                {({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <FieldArray name="markupData">
                                                                {({ remove, push }) => (
                                                                    <>
                                                                        <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                            {/* Table Header */}
                                                                            <thead className="table-light">
                                                                                <tr>
                                                                                    <th className="calculator-th">Markup</th>
                                                                                    <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                    <th className="calculator-th">Price/Shirt </th>
                                                                                </tr>
                                                                            </thead>
                                                                            {/* Table Body */}
                                                                            <tbody>
                                                                                {values.markupData.map((data, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`markupData.${index}.markup`}
                                                                                                className="form-control auto-populate-input"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control fill-in-input"
                                                                                                        {...field}
                                                                                                        value={`${Number(field.value || 0)}%`}
                                                                                                        onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                            onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                            if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                        }}
                                                                                                        onKeyUp={(e) => {
                                                                                                            const newMarkUp = e.target.value.replace('%', '');
                                                                                                            let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                                                                                                            let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
                                                                                                            let setupTotalData = getPrintingSetUpData.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0);
                                                                                                            let totalGrandTotal = (+garmentTotal + +inkCountDataTotal + +setupTotalData).toFixed(2);
                                                                                                            let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                            setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                            setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                            setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                            setPrintingMarkupData((prevMarkupData) => {
                                                                                                                const updatedMarkupData = [...prevMarkupData];
                                                                                                                updatedMarkupData[index] = {
                                                                                                                    ...updatedMarkupData[index],
                                                                                                                    markup: +newMarkUp,
                                                                                                                    totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                    pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                                };
                                                                                                                return updatedMarkupData;
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`markupData.${index}.totalCost`}
                                                                                                className="form-control auto-populate-input"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                        readOnly
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`markupData[${index}].pricePerShirt`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                        readOnly
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger"> *</span></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="calculator-td">
                                                                                        <input
                                                                                            className="form-control auto-populate-input"
                                                                                            name="quantity"
                                                                                            placeholder="Enter quantity"
                                                                                            value={isNaN(quoteSalesPercentage) ? 0 + '%' : quoteSalesPercentage + '%'}
                                                                                            type="text"
                                                                                            readOnly
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td"></td>
                                                                                    <td className="calculator-td">
                                                                                        <NumericFormat className="form-control fill-in-input"
                                                                                            onKeyUp={(e) => {
                                                                                                let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                                let numericPrice = parseFloat(price) || 0;
                                                                                                setQuotePrintingSalesPrice(numericPrice);
                                                                                                if (itemQuantity !== undefined) {
                                                                                                    let gross_sale = (numericPrice * +itemQuantity);
                                                                                                    let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                                    let profit_margin = gross_sale === 0 ? 0 : ((gross_sale - finalFigurescalculatorState.gross_cost) / gross_sale) * 100;
                                                                                                    setfinalFigurescalculatorState(prevState => ({
                                                                                                        ...prevState,
                                                                                                        gross_sale: (gross_sale).toFixed(2),
                                                                                                        net_profit: (net_profit).toFixed(2),
                                                                                                        profit_margin_percentage: (profit_margin).toFixed(2)
                                                                                                    }));
                                                                                                }
                                                                                            }}
                                                                                            thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                            onChange={(e) => {
                                                                                                let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                                let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                                setQuotePrintingSalesPrice(numericPrice);
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === '-' || e.keyCode === 189) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            value={quotePrintingSalesPrice} />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>}
                        {/* for tablet linear view */}
                        {(window.innerWidth >= 768 && window.innerWidth <= 1200) && <Row className="d-none d-md-flex d-xl-none mb-2">
                            <Col className="col-6 pe-1">
                                {/* garments table card */}
                                <Card id="step5">
                                    <CardBody className="linear-table-card-body">
                                        {/* skeleton loading for garments table */}
                                        {getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Garments</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th th-width">Total</th>
                                                            <th className="calculator-th th-width">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                garments: getPrintingGarmentData,
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th">Garments</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th th-width">Total</th>
                                                                    <th className="calculator-th th-width">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="calculator-table-body">
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.garments.map((garment, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td">
                                                                                        {garment.garment_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {
                                                                                            garment.garment_label !== 'Shipping' && (
                                                                                                <Field
                                                                                                    name={`garments[${index}].garment_quantity`}
                                                                                                    type="number"
                                                                                                    render={({ field }) => (
                                                                                                        <input
                                                                                                            className="form-control fill-in-input"
                                                                                                            {...field}
                                                                                                            value={field.value || 0}
                                                                                                            onChange={(e) => {
                                                                                                                // find the total of quantity and rate based on quantity and update the state array
                                                                                                                const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                                setFieldValue(`garments[${index}].garment_quantity`, newQuantity, false);
                                                                                                                const rate = parseFloat(values.garments[index]?.garment_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                                const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                                setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                                setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                    const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                    updatedGarmentsData[index] = {
                                                                                                                        ...updatedGarmentsData[index],
                                                                                                                        garment_quantity: newQuantity,
                                                                                                                        total: total,
                                                                                                                    };
                                                                                                                    return updatedGarmentsData;
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`garments[${index}].total`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat
                                                                                                    prefix="$"
                                                                                                    decimalScale={2}
                                                                                                    value={field.value || 0}
                                                                                                    className={`form-control ${garment.garment_label === 'Shipping' ? 'fill-in-input' : 'auto-populate-input'}`}
                                                                                                    readOnly={garment.garment_label !== 'Shipping'}
                                                                                                    onValueChange={(values) => {
                                                                                                        const { floatValue: newRate } = values; // Extract the floating-point number
                                                                                                        setFieldValue(`garments[${index}].total`, newRate || 0, false);
                                                                                                        setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                            const updatedGarmentsData = [...prevGarmentsData];
                                                                                                            updatedGarmentsData[index] = {
                                                                                                                ...updatedGarmentsData[index],
                                                                                                                total: newRate || 0,
                                                                                                            };
                                                                                                            return updatedGarmentsData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {
                                                                                            garment.garment_label !== 'Shipping' && (
                                                                                                <Field
                                                                                                    name={`garments[${index}].garment_rate`}
                                                                                                    render={({ field }) => (
                                                                                                        <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                            onChange={(e) => {
                                                                                                                // find the total of quantity and garment_rate based on rate and update the state
                                                                                                                const newRate = e.target.value.replace('$', '');
                                                                                                                setFieldValue(`garments[${index}].garment_rate`, newRate, false);
                                                                                                                const quantity = values.garments[index].garment_quantity || 0;
                                                                                                                const total = +newRate * quantity;
                                                                                                                setFieldValue(`garments[${index}].total`, total || 0);
                                                                                                                setPrintingGarmentsData((prevGarmentsData) => {
                                                                                                                    const updatedGarmentsData = [...prevGarmentsData];
                                                                                                                    updatedGarmentsData[index] = {
                                                                                                                        ...updatedGarmentsData[index],
                                                                                                                        garment_rate: newRate,
                                                                                                                        total: total,
                                                                                                                    };
                                                                                                                    return updatedGarmentsData;
                                                                                                                });
                                                                                                            }}
                                                                                                            onKeyDown={(e) => {
                                                                                                                if (e.key === '-' || e.keyCode === 189) {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                                <td className="total-value calculator-td text-end" colSpan={2}>Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-start ps-0"
                                                                                        value={`$${Number(
                                                                                            Array.isArray(values.garments)
                                                                                                ? values.garments.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)
                                                                                                : 0
                                                                                        ).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                                {/* <td className="calculator-td"></td> */}
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* setup table card */}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block" id="step7">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isSetupLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Setup</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                setup: getPrintingSetUpData,
                                            }}
                                            key={jobCriteriaKey}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th setup-table-head">Setup</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="setup"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.setup.map((setup, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td setup-td">
                                                                                        {setup.setup_label}
                                                                                        {
                                                                                            index === 6 && (
                                                                                                <span>
                                                                                                    <i id="re-setup-fee" className={`bx ms-1 mt-1 bx-info-circle cursor-pointer text-yellow position-absolute info-icon-spacing ${document.body.classList.contains('sidebar-enable') && !isLinearView ? 'close-sidebar-info-icon' : ''}  info-icon`} />
                                                                                                    <UncontrolledTooltip placement="bottom" target="re-setup-fee" style={{ background: 'black' }}>
                                                                                                        RESETUP FEE: NUMBER OF TOTAL SCREENS BEING USED / SETUP IN THE JOB.<br />

                                                                                                        Only for jobs that we still have screens made for.
                                                                                                    </UncontrolledTooltip>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`setup[${index}].setup_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className={`form-control ${setup.setup_label === masterTableLable.laborPerHour ? 'auto-populate-input' : (setup.setup_label === 'Pre-Print' || setup.setup_label === 'Clean Up') ? 'editable-input' : 'fill-in-input'}`}
                                                                                                    {...field}
                                                                                                    value={(field.value || 0).toString()}
                                                                                                    onChange={(e) => {
                                                                                                        // find the total of quantity and rate based on quantity and update the state array
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`setup[${index}].setup_quantity`, newQuantity, false);
                                                                                                        const rate = parseFloat(values.setup[index]?.setup_rate) || 0; // Ensure rate is a valid number or fallback to 0
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                        setPrintingSetupData((prevSetupData) => {
                                                                                                            const updatedSetupData = [...prevSetupData];
                                                                                                            updatedSetupData[index] = {
                                                                                                                ...updatedSetupData[index],
                                                                                                                setup_quantity: newQuantity,
                                                                                                                total: total,
                                                                                                            };
                                                                                                            return updatedSetupData;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoCorrect="off"
                                                                                                    disabled={setup.setup_label === masterTableLable.laborPerHour}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].total`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        readOnly
                                                                                                        min={0}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        {/* Conditionally render Rate field for RPM and Location */}
                                                                                        {setup.setup_label === masterTableLable.rpm || setup.setup_label === masterTableLable.location ? null : (
                                                                                            <Field
                                                                                                name={`setup[${index}].setup_rate`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className={`form-control ${setup.setup_label === masterTableLable.hoopingFee ? 'auto-populate-input' : 'editable-input'} `}
                                                                                                        onChange={(e) => {
                                                                                                            const newRate = e.target.value.replace('$', '');
                                                                                                            setFieldValue(`setup[${index}].setup_rate`, newRate, false);
                                                                                                            const quantity = values.setup[index].setup_quantity || 0;
                                                                                                            const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                            setFieldValue(`setup[${index}].total`, total || 0);
                                                                                                            setPrintingSetupData((prevSetupData) => {
                                                                                                                const updatedSetupData = [...prevSetupData];
                                                                                                                updatedSetupData[index] = {
                                                                                                                    ...updatedSetupData[index],
                                                                                                                    setup_rate: +newRate,
                                                                                                                    total: +total || 0,
                                                                                                                };
                                                                                                                return updatedSetupData;
                                                                                                            });
                                                                                                        }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={setup.setup_label === masterTableLable.hoopingFee}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.setup.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                                {!isSmallLaptopDevice && <Card className="d-xl-none d-block markup-table-card art-table-card" id="artTable">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isArtTableLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Art</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(2)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            initialValues={{
                                                artCount: getPrintingArtCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th art-count-head">Art</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.artCount.map((artCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {artCount.art_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`artCount[${index}].art_quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.artCount[index]?.art_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                art_quantity: newQuantity,
                                                                                                                total: total || 0,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`artCount[${index}].art_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`artCount[${index}].art_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.artCount[index].art_quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`artCount[${index}].total`, total || 0);
                                                                                                        setPrintingArtCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                art_rate: +newRate,
                                                                                                                total: +total || 0,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.artCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>}
                            </Col>
                            <Col className="col-6 ps-1">
                                {/* stitch count table card */}
                                <Card id="step6">
                                    <CardBody className="linear-table-card-body" id="step6">
                                        {getScreenPrintingMasterTableLoadingStatus.isInkLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Ink</th>
                                                            <th className="calculator-th">Quantity</th>
                                                            <th className="calculator-th">Total</th>
                                                            <th className="calculator-th">Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className="bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (<Formik
                                            key={stitchCountKey}
                                            initialValues={{
                                                inkCount: getPrintingInkCountData
                                            }}
                                            render={({ values, setFieldValue }) => (
                                                <Form>
                                                    <div className="table-responsive">
                                                        <Table className="table align-middle mb-0 table-nowrap">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className="calculator-th ink-count-calculator-th">Ink</th>
                                                                    <th className="calculator-th">Quantity</th>
                                                                    <th className="calculator-th">Total</th>
                                                                    <th className="calculator-th">Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <FieldArray
                                                                    name="garments"
                                                                    render={(arrayHelpers) => (
                                                                        <>
                                                                            {values.inkCount.map((inkCount, index) => (
                                                                                <tr key={index}>
                                                                                    <td className="calculator-td ink-charges-label">
                                                                                        {inkCount.ink_label}
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].quantity`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control fill-in-input"
                                                                                                    {...field}
                                                                                                    value={field.value || 0}
                                                                                                    onChange={(e) => {
                                                                                                        const newQuantity = parseFloat(e.target.value) || 0;
                                                                                                        setFieldValue(`inkCount[${index}].quantity`, newQuantity, false);
                                                                                                        // base don the value of the quantity find the total of rate and quantity
                                                                                                        const rate = parseFloat(values.inkCount[index]?.ink_rate) || 0;
                                                                                                        const total = parseFloat((newQuantity * rate).toFixed(2));

                                                                                                        setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                        setPrintingInkCountData((prevGarmentsData) => {
                                                                                                            const updatedInkTable = [...prevGarmentsData];
                                                                                                            updatedInkTable[index] = {
                                                                                                                ...updatedInkTable[index],
                                                                                                                quantity: newQuantity,
                                                                                                                total: total || 0,
                                                                                                            };
                                                                                                            return updatedInkTable;
                                                                                                        });
                                                                                                    }}
                                                                                                    autoComplete="off"
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].total`}
                                                                                            type="number"
                                                                                            render={({ field }) => (
                                                                                                <input
                                                                                                    className="form-control auto-populate-input"
                                                                                                    {...field}
                                                                                                    readOnly
                                                                                                    min={0}
                                                                                                    value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td">
                                                                                        <Field
                                                                                            name={`inkCount[${index}].ink_rate`}
                                                                                            render={({ field }) => (
                                                                                                <NumericFormat prefix="$" decimalScale={2} value={field.value || 0} className="form-control editable-input"
                                                                                                    onChange={(e) => {
                                                                                                        const newRate = e.target.value.replace('$', '');
                                                                                                        setFieldValue(`inkCount[${index}].ink_rate`, newRate, false);
                                                                                                        // find the total of object based on rate quantity and updated rate and then update the state array
                                                                                                        const quantity = values.inkCount[index].quantity || 0;
                                                                                                        const total = parseFloat(+newRate * quantity).toFixed(2);
                                                                                                        setFieldValue(`inkCount[${index}].total`, total || 0);
                                                                                                        setPrintingInkCountData((prevInkData) => {
                                                                                                            const updatedPrintingData = [...prevInkData];
                                                                                                            updatedPrintingData[index] = {
                                                                                                                ...updatedPrintingData[index],
                                                                                                                ink_rate: +newRate,
                                                                                                                total: +total,
                                                                                                            };
                                                                                                            return updatedPrintingData;
                                                                                                        });
                                                                                                    }}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === '-' || e.keyCode === 189) {
                                                                                                            e.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            <tr>
                                                                                <td className="calculator-td"></td>
                                                                                <td className="total-value calculator-td">Total</td>
                                                                                <td className="calculator-td" colSpan={2}>
                                                                                    <input
                                                                                        className="form-control bg-trnsperent border-0 total-input text-left"
                                                                                        value={`$${Number(values.inkCount.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0)).toFixed(2)}`}
                                                                                        readOnly
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                                                                />
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Form>
                                            )}
                                        />)}
                                    </CardBody>
                                </Card>
                                {/* technical specification table card */}
                                <Card id="step8">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th job-criteria-table">Job Criteria</th>
                                                            <th className="calculator-th"> </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} width={300} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Formik
                                                key={laborPerHourKey}
                                                initialValues={{
                                                    jobCriteria: getPrintingJobCriteriaData,
                                                }}
                                                render={({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <Table className="table align-middle mb-0 table-nowrap">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th className="calculator-th job-criteria-table">Job Criteria</th>
                                                                        <th className="calculator-th"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="calculator-table-body">
                                                                    <FieldArray
                                                                        name="jobCriteria"
                                                                        render={() => (
                                                                            <>
                                                                                {values && values.jobCriteria && values.jobCriteria.map((jobCriteria, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            {jobCriteria.job_criteria_label} {(jobCriteria.job_criteria_label === masterTableLable.of_location || jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) && (<span class="text-danger">*</span>)}
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`jobCriteria[${index}].job_criteria_quantity`}
                                                                                                render={({ field }) => (
                                                                                                    <NumericFormat
                                                                                                        allowNegative={false}
                                                                                                        decimalScale={0}
                                                                                                        fixedDecimalScale={false}
                                                                                                        value={field.value || 0}
                                                                                                        className={`form-control ${jobCriteria.job_criteria_label === masterTableLable.shirt_in_job ? 'auto-populate-input' : 'editable-input'}`}
                                                                                                        onValueChange={({ value }) => {
                                                                                                            const newRate = value || 0;
                                                                                                            setFieldValue(`jobCriteria[${index}].job_criteria_quantity`, newRate, false);

                                                                                                            setPrintingJobCriteria((prevJobCriteriaData) => {
                                                                                                                const updatedJobCriteria = [...prevJobCriteriaData];
                                                                                                                updatedJobCriteria[index] = {
                                                                                                                    ...updatedJobCriteria[index],
                                                                                                                    job_criteria_quantity: +newRate || 0,
                                                                                                                };
                                                                                                                return updatedJobCriteria;
                                                                                                            });

                                                                                                            const clonedJobCriteriaData = [...getPrintingJobCriteriaData];
                                                                                                            if (jobCriteria.job_criteria_label === masterTableLable.of_location) {
                                                                                                                laborPerHourQuantity(
                                                                                                                    itemQuantity,
                                                                                                                    newRate,
                                                                                                                    clonedJobCriteriaData[1].job_criteria_quantity,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                            } else if (jobCriteria.job_criteria_label === masterTableLable.shirtPerHour) {
                                                                                                                laborPerHourQuantity(
                                                                                                                    itemQuantity,
                                                                                                                    clonedJobCriteriaData[2].job_criteria_quantity,
                                                                                                                    newRate,
                                                                                                                    setFieldValue
                                                                                                                );
                                                                                                            }
                                                                                                        }}
                                                                                                        disabled={jobCriteria.job_criteria_label === masterTableLable.shirt_in_job}
                                                                                                        onKeyDown={(e) => {
                                                                                                            if (e.key === '-' || e.keyCode === 189) {
                                                                                                                e.preventDefault();
                                                                                                            }
                                                                                                            onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Form>
                                                )}
                                            />
                                        )}
                                    </CardBody>
                                </Card>
                                {/* mark up table card */}
                                <Card className="markup-table-card">
                                    <CardBody className="linear-table-card-body">
                                        {getScreenPrintingMasterTableLoadingStatus.isInkLoading && getScreenPrintingMasterTableLoadingStatus.isArtTableLoading && getScreenPrintingMasterTableLoadingStatus.isJobCriteriaLoading && getScreenPrintingMasterTableLoadingStatus.isSetupLoading && getScreenPrintingMasterTableLoadingStatus.isgarmentLoading ? (
                                            <div className="table-responsive">
                                                <table className="table align-middle mb-0 table-nowrap">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className="calculator-th">Markup</th>
                                                            <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                            <th className="calculator-th">Price/Shirt </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {[...Array(5)].map((_, index) => (
                                                            <tr key={index}>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft " />
                                                                </td>
                                                                <td className="calculator-td">
                                                                    <Skeleton height={30} baseColor='var(--secondary-white)' highlightColor="var(--primary-dark-white)" className=" bg-soft" />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <Formik
                                                key={printingMarkupKey}
                                                initialValues={{ markupData: getPrintingMarkupData, }} >
                                                {({ values, setFieldValue }) => (
                                                    <Form>
                                                        <div className="table-responsive">
                                                            <FieldArray name="markupData">
                                                                {({ remove, push }) => (
                                                                    <>
                                                                        <table id="step9" className="table align-middle mb-0 table-nowrap">
                                                                            {/* Table Header */}
                                                                            <thead className="table-light">
                                                                                <tr>
                                                                                    <th className="calculator-th">Markup</th>
                                                                                    <th className="calculator-th">Total Cost<i className="bx bx-info-circle cursor-pointer position-relative info-tooltip ms-1 text-yellow" id="headerInfo" data-toggle='tooltip' data-title={'(shirt / labor / ink / art) * Margin'}></i></th>
                                                                                    <th className="calculator-th">Price/Shirt </th>
                                                                                </tr>
                                                                            </thead>
                                                                            {/* Table Body */}
                                                                            <tbody>
                                                                                {values.markupData.map((data, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`markupData.${index}.markup`}
                                                                                                className="form-control auto-populate-input"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control fill-in-input"
                                                                                                        {...field}
                                                                                                        value={`${Number(field.value || 0)}%`}
                                                                                                        onChange={(e) => { setFieldValue(`markupData[${index}].markup`, +e.target.value.replace('%', '') || 0, false); }}
                                                                                                        onKeyDown={(e) => {
                                                                                                            e.target.setSelectionRange(e.target.value.indexOf('%'), e.target.value.indexOf('%'));
                                                                                                            onKeyDownAllowUserToAddOnlyNumber(e);
                                                                                                            if (e.target.value.replace('%', '') + e.key === '100') { e.preventDefault(); }
                                                                                                        }}
                                                                                                        onKeyUp={(e) => {
                                                                                                            const newMarkUp = e.target.value.replace('%', '');
                                                                                                            let garmentTotal = getPrintingGarmentData.reduce((sum, garment) => sum + (parseFloat(garment.total) || 0), 0);
                                                                                                            let inkCountDataTotal = getPrintingInkCountData.reduce((sum, ink) => sum + (parseFloat(ink.total) || 0), 0);
                                                                                                            let setupTotalData = getPrintingSetUpData.reduce((sum, setup) => sum + (parseFloat(setup.total) || 0), 0);
                                                                                                            let totalGrandTotal = (+garmentTotal + +inkCountDataTotal + +setupTotalData).toFixed(2);
                                                                                                            let totalCost = (totalGrandTotal / (100 - +newMarkUp));
                                                                                                            setFieldValue(`markupData[${index}].markup`, +newMarkUp || 0, false);
                                                                                                            setFieldValue(`markupData[${index}].totalCost`, +newMarkUp === 0 ? 0 : (totalCost * 100).toFixed(2), false);
                                                                                                            setFieldValue(`markupData[${index}].pricePerShirt`, +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2), false);
                                                                                                            setPrintingMarkupData((prevMarkupData) => {
                                                                                                                const updatedMarkupData = [...prevMarkupData];
                                                                                                                updatedMarkupData[index] = {
                                                                                                                    ...updatedMarkupData[index],
                                                                                                                    markup: +newMarkUp,
                                                                                                                    totalCost: +newMarkUp === 0 ? 0 : +(+totalCost * 100).toFixed(2),
                                                                                                                    pricePerShirt: +newMarkUp === 0 ? 0 : +((+totalCost * 100) / itemQuantity).toFixed(2)
                                                                                                                };
                                                                                                                return updatedMarkupData;
                                                                                                            });
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                type="text"
                                                                                                name={`markupData.${index}.totalCost`}
                                                                                                className="form-control auto-populate-input"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                        readOnly
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="calculator-td">
                                                                                            <Field
                                                                                                name={`markupData[${index}].pricePerShirt`}
                                                                                                type="number"
                                                                                                render={({ field }) => (
                                                                                                    <input
                                                                                                        className="form-control auto-populate-input"
                                                                                                        {...field}
                                                                                                        value={`$${Number(field.value || 0).toFixed(2)}`}
                                                                                                        readOnly
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <table id="step10" className="table align-middle mb-0 table-nowrap">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th className="calculator-th quote-salesPrice-title" colSpan={3}>Enter the quoted Sales Price<span className="text-danger"> *</span></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="calculator-td">
                                                                                        <input
                                                                                            className="form-control auto-populate-input"
                                                                                            name="quantity"
                                                                                            placeholder="Enter quantity"
                                                                                            value={isNaN(quoteSalesPercentage) ? 0 + '%' : quoteSalesPercentage + '%'}
                                                                                            type="text"
                                                                                            readOnly
                                                                                        />
                                                                                    </td>
                                                                                    <td className="calculator-td"></td>
                                                                                    <td className="calculator-td">
                                                                                        <NumericFormat className="form-control fill-in-input"
                                                                                            onKeyUp={(e) => {
                                                                                                let price = e.target.value.replace('$', '').replace(/,/g, '');
                                                                                                let numericPrice = parseFloat(price) || 0;
                                                                                                setQuotePrintingSalesPrice(numericPrice);
                                                                                                if (itemQuantity !== undefined) {
                                                                                                    let gross_sale = (numericPrice * +itemQuantity);
                                                                                                    let net_profit = (+(gross_sale).toFixed(2) - finalFigurescalculatorState.gross_cost);
                                                                                                    let profit_margin = gross_sale === 0 ? 0 : ((gross_sale - finalFigurescalculatorState.gross_cost) / gross_sale) * 100;
                                                                                                    setfinalFigurescalculatorState(prevState => ({
                                                                                                        ...prevState,
                                                                                                        gross_sale: (gross_sale).toFixed(2),
                                                                                                        net_profit: (net_profit).toFixed(2),
                                                                                                        profit_margin_percentage: (profit_margin).toFixed(2)
                                                                                                    }));
                                                                                                }
                                                                                            }}
                                                                                            thousandSeparator={true} prefix="$" decimalScale={2}
                                                                                            onChange={(e) => {
                                                                                                let price = e.target.value.replace('$', '').replace(/,/g, ''); // Remove dollar sign and commas
                                                                                                let numericPrice = parseFloat(price) || 0;// Parse the value as a float
                                                                                                setQuotePrintingSalesPrice(numericPrice);
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === '-' || e.keyCode === 189) {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            value={quotePrintingSalesPrice} />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>}
                    </div>
                }
                {/* footer */}
                <Row>
                    <Col>
                        <div className="calculator-footer">
                            <div className={`d-md-flex d-block justify-content-between my-2 ${isLinearView ? 'my-md-2 linear-view-footer-div' : 'my-md-3'} mx-3 flex-row align-items-center`}>
                                {/* indicator */}
                                <div className="d-flex align-items-center mb-md-1 mb-3 ms-1 justify-content-between">
                                    <div className="d-flex">
                                        <span className="editable-input badge-span me-1 me-md-2"></span>
                                        <Badge className="me-1 fw-medium badge-indicator editable">
                                            Editable
                                        </Badge>
                                        <span className="auto-populated-input badge-span me-1 me-md-2"></span>
                                        <Badge className="me-1 fw-medium badge-indicator auto-populated-input-text ">
                                            Fill in
                                        </Badge>
                                    </div>
                                    {/* total item count */}
                                    {!isMediumDevice &&
                                        quoteItems.length > 0 && (
                                            <div id="step18" className=" align-items-center text-muted fw-medium d-flex d-md-none">
                                                Total item: {localStorage.getItem('quote_items') === null ? 0 : JSON.parse(localStorage.getItem('quote_items')).length}
                                                <i className=" bi  bi-eye position-relative cursor-pointer quote-id-info ms-1 text-yellow" id="totalItemTable" data-toggle='tooltip' data-title="total item" onClick={() => { localStorage.getItem('quote_items') == null ? setisPreviewModal(false) : setisPreviewModal(true); }}>
                                                    <div className="quote-item-tooltip">
                                                        <p className="mb-0">View quote items</p>
                                                    </div>
                                                </i>
                                            </div>
                                        )
                                    }
                                </div>
                                {/* buttons */}
                                <div className="d-flex ms-auto ms-md-none justify-md-content-center justify-content-end mt-md-0 mt-2">
                                    {/* total item count */}
                                    {
                                        isMediumDevice && quoteItems.length > 0 && (
                                            <div id="step18" className=" align-items-center text-muted fw-medium me-2 d-md-flex d-none">
                                                Total item: {localStorage.getItem('quote_items') === null ? 0 : JSON.parse(localStorage.getItem('quote_items')).length}
                                                <i className="bi  bi-eye position-relative cursor-pointer quote-id-info ms-1 text-yellow" id="totalItemTable" data-toggle='tooltip' data-title="total item" onClick={() => { localStorage.getItem('quote_items') == null ? setisPreviewModal(false) : setisPreviewModal(true); }}>
                                                    <div className="quote-item-tooltip">
                                                        <p className="mb-0">View quote items</p>
                                                    </div>
                                                </i>
                                            </div>
                                        )
                                    }
                                    {(JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details !== null &&
                                        (
                                            JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN ||
                                            JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN ||
                                            JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid === SUBSCRIPTION_PLAN_UUID.BASIC_PALN // Fixed typo here
                                        )) && (
                                            <button id="resetCalculator" className="btn btn-fill" onClick={() => {
                                                resetQuoteCalculator(activeTabview);
                                                localStorage.removeItem('taxInfo');
                                                setshouldRefreshSelectedTaxInfo(true);
                                                setTimeout(() => {
                                                    setshouldRefreshSelectedTaxInfo(false);
                                                }, 100);
                                            }}>
                                                <i className="bx bx-reset font-size-17 me-1 position-relative reset-button" /> Reset
                                            </button>
                                        )}
                                    {
                                        (
                                            // Show button if the plan details are null
                                            JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details === null ||
                                            // Show button if none of the plan UUIDs match the basic plans
                                            (
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN &&
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN &&
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.BASIC_PALN
                                            )
                                        ) && (
                                            <button
                                                className="btn-secondary-add me-2 ms-2 me-md-3 d-flex align-items-center"
                                                id="step17"
                                                type="button"
                                                disabled={
                                                    itemQuantity === 0 ||
                                                    getItemName === '' ||
                                                    error !== '' ||
                                                    (
                                                        activeTabview !== CALCULATOR_TYPE.SCREEN_PRINTING_CALCULATOR
                                                            ? getGarmentPerHour === 0 || getLocation === 0
                                                            : quotePrintingSalesPrice === 0 || getPrintingJobCriteriaData[1].job_criteria_quantity === 0 || getPrintingJobCriteriaData[2].job_criteria_quantity === 0
                                                    ) ||
                                                    itemQuantity === undefined
                                                }
                                                onClick={() => { onClickAddItemtoQuote() }}
                                            >
                                                {isLoading && isEditMode ? <Spinners setLoading={setLoading} /> : <i className="bx bx-plus font-size-17 me-1"></i>}
                                                Add Item
                                            </button>
                                        )
                                    }
                                    {
                                        isEditMode === true && (
                                            <button
                                                type="submit"
                                                className="btn-outline save-user save-quote d-flex align-items-center"
                                                onClick={() => { navigate('/quote-list') }}
                                            >
                                                Cancel
                                            </button>
                                        )
                                    }
                                    {
                                        isEditMode === false && (
                                            // Show button if the plan details are null
                                            JSON.parse(sessionStorage.getItem('userLogin')).data.subscription_details.subscription_plan_details === null ||
                                            // Show button if none of the plan UUIDs match the basic plans
                                            (
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.PRINT_SHOP_BASIC_PLAN &&
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.SCREEN_PRINTINTING_BASIC_PLAN &&
                                                JSON.parse(sessionStorage.getItem('userLogin'))?.data?.subscription_details?.subscription_plan_details?.subscription_plan_uuid !== SUBSCRIPTION_PLAN_UUID.BASIC_PALN
                                            )
                                        ) && (
                                            <button
                                                type="submit"
                                                id="step19"
                                                className="btn-fill save-user save-quote d-flex align-items-center"
                                                onClick={() => { setModal(true); resetQuoteCalculator(activeTabview); }}
                                                disabled={localStorage.getItem('quote_items') === null}
                                            ><i className="bx bx-save font-size-17 me-1"></i>
                                                Save Quote
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* Preview table */}
            <Modal className='delete-customer-modal' size="lg" isOpen={isPreviewModal} centered={true}>
                <ModalHeader toggle={() => setisPreviewModal(false)} tag="h4">
                    Quote Items
                </ModalHeader>
                <ModalBody>
                    {/* quote table  */}
                    <div className="table-responsive preview-quote-table-responsive">
                        <Table className="table-nowrap preview-quote-table">
                            <thead className="preview-quote-table-head">
                                <tr>
                                    <th className="table-th text-center">Item</th>
                                    <th className="table-th table-name-th">Name</th>
                                    <th className="table-th text-start table-quantity-th">Quantity</th>
                                    <th className="text-end table-th table-rate-th">Rate</th>
                                    <th className="text-end table-th">Amount</th>
                                    <th className="text-center table-th table-action-th">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quoteItems.map((item, index) => (
                                        <>
                                            <tr key={item.quote_item_details.item_description} className="position-relative">
                                                <td className={`text-start table-data ${item?.quote_item_tax_details?.length === 0 || item?.quote_item_details?.quote_item_tax_details === null ? 'align-middle' : 'align-top'} position-relative img-td`}>
                                                    <ImageUpload
                                                        selectedImage={selectedImages[index]}
                                                        handleImageChange={(e) => handleImageChange(e, index, item.quote_item_details.quote_item_uuid)}
                                                        handlePreviewImage={handlePreviewImage}
                                                        handleDeleteImage={handleDeleteImage}
                                                        index={index}
                                                        quoteItemDetails={item.quote_item_details}
                                                        isEditMode={true}
                                                        isFromPreviewQuote={false}
                                                    />
                                                </td>
                                                <td className={`text-start table-data  ${item?.quote_item_details?.quote_item_tax_details === null || item?.quote_item_tax_details?.length === 0 ? 'align-middle' : 'align-top'}`}>
                                                    <p className="mb-0 item_name" id={`item_name${index}`}>{item.quote_item_details.item_name}</p>
                                                    <p className="mt-1 mb-0 item_description" id={`item_description${index}`}>{item.quote_item_details.item_description}</p>
                                                    {
                                                        item.quote_item_details?.item_name?.length > 20 && (
                                                            <UncontrolledTooltip placement="bottom" target={`item_name${index}`}>{item.quote_item_details.item_name}</UncontrolledTooltip>
                                                        )
                                                    }
                                                    {
                                                        item.quote_item_details?.item_description?.length > 20 && (
                                                            <UncontrolledTooltip placement="bottom" target={`item_description${index}`}>{item.quote_item_details.item_description}</UncontrolledTooltip>
                                                        )
                                                    }
                                                </td>
                                                <td className={`text-start table-data  ${item?.quote_item_tax_details?.length === 0 || item?.quote_item_details?.quote_item_tax_details === null ? 'align-middle' : 'align-top'}`}>
                                                    {item.quote_item_details.item_quantity}
                                                </td>
                                                <td className={`text-center table-data ${item?.quote_item_tax_details?.length === 0 || item?.quote_item_details?.quote_item_tax_details === null ? 'align-middle' : 'align-top'} text-end`}>
                                                    <div className="text-end ">
                                                        <span>${item.quote_item_details.quoted_sales_price.toFixed(2)}</span>
                                                    </div>
                                                    {
                                                        item.quote_item_details.quote_item_tax_details && item.quote_item_details.quote_item_tax_details.map((taxInfo, index) => (
                                                            <div className="d-flex justify-content-between tax_detail_row">
                                                                <span id={`text_name${index}-${item.quote_item_details.quote_item_uuid}-${item.quote_item_details.id}`} className={`mt-1 me-3 tax-display-quote-items-popup ${taxInfo.tax_name.length > 9 ? 'cursor-pointer' : ''}`}>{taxInfo.tax_name}</span>
                                                                {
                                                                    taxInfo.tax_name.length > 9 && (
                                                                        <UncontrolledTooltip placement="bottom" target={`text_name${index}-${item.quote_item_details.quote_item_uuid}-${item.quote_item_details.id}`}>{taxInfo.tax_name}</UncontrolledTooltip>
                                                                    )
                                                                }
                                                                <span className="mb-0 mt-1">{taxInfo.tax_percentage.toFixed(2)}%</span>
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        item?.quote_item_tax_details && item?.quote_item_tax_details.map((taxInfo, index) => (
                                                            <div className="d-flex justify-content-between tax_detail_row">
                                                                <span id={`text_name${index}-${item.quote_item_details.quote_item_uuid}-${item.quote_item_details.id}`} className={`mt-1 me-3 tax-display-quote-items-popup ${taxInfo.tax_name.length > 9 ? 'cursor-pointer' : ''}`}>{taxInfo.tax_name}</span>
                                                                {
                                                                    taxInfo.tax_name.length > 9 && (
                                                                        <UncontrolledTooltip placement="bottom" target={`text_name${index}-${item.quote_item_details.quote_item_uuid}-${item.quote_item_details.id}`}>{taxInfo.tax_name}</UncontrolledTooltip>
                                                                    )
                                                                }
                                                                <span className="mb-0 mt-1">{taxInfo.tax_percentage}%</span>
                                                            </div>
                                                        ))
                                                    }
                                                </td>
                                                <td className={`text-center table-data ${item?.quote_item_tax_details?.length === 0 || item?.quote_item_details?.quote_item_tax_details === null ? 'align-middle' : 'align-top'} `}>
                                                    <p className="mb-0 text-end ">${(item.quote_item_details.item_quantity * item.quote_item_details.quoted_sales_price).toFixed(2)}</p>
                                                    <div className="text-end">
                                                        {
                                                            item?.quote_item_tax_details && item?.quote_item_tax_details.map((taxInfo, index) => (
                                                                <div className="mt-1 text-end">
                                                                    <span className="text-end">${((item.quote_item_details.item_quantity * item.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100)).toFixed(2)}</span>
                                                                </div>
                                                            ))
                                                        }
                                                        {
                                                            item.quote_item_details.quote_item_tax_details && item.quote_item_details.quote_item_tax_details.map((taxInfo, index) => (
                                                                <div className="mt-1 text-end">
                                                                    <span className="text-end">${((item.quote_item_details.item_quantity * item.quote_item_details.quoted_sales_price) * (taxInfo.tax_percentage / 100)).toFixed(2)}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                                <td className={`text-center table-data ${item?.quote_item_tax_details?.length === 0 || item?.quote_item_details?.quote_item_tax_details === null ? 'align-middle' : 'align-top'} `}><i className="mdi mdi-trash-can text-danger font-size-16 cursor-pointer" id="deletetooltip"
                                                    onClick={() => {
                                                        setDeleteModal(true);
                                                        setiDeleteQuoteItem(item);
                                                        setisDeleted(false);
                                                    }} /><UncontrolledTooltip placement="bottom" target="deletetooltip"> Delete</UncontrolledTooltip></td>
                                                {imgTypeErrorArr && (
                                                    <span className="text-danger image-error-msg position-absolute bottom-0 p-0">
                                                        {imgTypeErrorArr[index]}
                                                    </span>
                                                )}
                                            </tr >
                                        </>
                                    ))
                                }
                            </tbody>
                        </Table>
                        {localStorage.getItem('quote_items') === null && (
                            <div className="d-flex justify-content-center align-items-center no-item-added-to-quote-info">
                                <i className="bx bx-info-circle"></i>
                                <p className="text-center mb-0 ms-1">No item added to quote.</p>
                            </div>
                        )}
                        {/* Modal for image preview */}
                        <ImagePreviewModal imgSrc={previewImage} onCloseClick={() => setPreviewImage(null)}></ImagePreviewModal>
                    </div>
                </ModalBody>
            </Modal>
            {/* save Quote modal */}
            <Modal isOpen={modal} toggle={() => closeModal()} className="save-quote-modal my-0 h-100 d-flex align-items-center">
                <ModalHeader toggle={() => closeModal()} tag="h4">
                    Save Quote
                </ModalHeader>
                {/* modal body */}
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    >
                        <Row>
                            {/* wizard */}
                            <div className={`wizard  ${!isExisting ? 'increaseHeight' : ''} clearfix`}>
                                {/* wizard tab */}
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem
                                            className={classnames({
                                                current: activeTabVartical === 1,
                                            })}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: activeTabVartical === 1,
                                                })}
                                                onClick={() => {
                                                    toggleTabVertical(1)
                                                }}
                                                disabled={!(passedStepsVertical || []).includes(1)}
                                            > Quote
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={classnames({
                                                current: activeTabVartical === 2,
                                            })}
                                        >
                                            <NavLink
                                                className={classnames({
                                                    active: activeTabVartical === 2,
                                                })}
                                                onClick={() => {
                                                    toggleTabVertical(2)
                                                }}
                                            >
                                                <span>Client</span>
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                                {/* tab content */}
                                <div className="content clearfix p-0">
                                    <TabContent
                                        activeTab={activeTabVartical}
                                        className="body"
                                    >
                                        {/* quote tab content */}
                                        <TabPane tabId={1}>
                                            <Col className="col-12 job-section-col">
                                                <Row>
                                                    <Col className="col-12">
                                                        <div className="mb-3">
                                                            <label className="muted-text mb-0">Please provide the details below for inclusion in the PDF version of the quote.</label>
                                                        </div></Col>
                                                    <Col className="col-6">
                                                        {/*job name */}
                                                        <div className="mb-3">
                                                            <Label className="form-label font-size-15">Job Name<span className='text-danger'> *</span></Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="jobName"
                                                                placeholder="Enter Job Name"
                                                                value={validation.values.jobName || ''}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.jobName && validation.errors.jobName ? true : false
                                                                }
                                                            />
                                                            {validation.touched.jobName && validation.errors.jobName ? (
                                                                <FormFeedback type="invalid">{validation.errors.jobName}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* expires at date */}
                                                    <Col className="col-6">
                                                        <div className="mb-3">
                                                            <Label className="form-label font-size-15">Expires at<span className='text-danger'> *</span></Label>
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    className="form-control d-block"
                                                                    placeholder="M dd,yyyy"
                                                                    value={date}
                                                                    id="expiresAt"
                                                                    onOpen={() => {
                                                                        if (!expiresAtVal) {
                                                                            setExpiresAtVal('');
                                                                        }
                                                                    }}
                                                                    options={{
                                                                        altInput: true,
                                                                        altFormat: "F j, Y",
                                                                        dateFormat: "Y-m-d",
                                                                        minDate: "today"
                                                                    }}
                                                                    onChange={([date]) => {
                                                                        setExpiresAtVal(date);
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            {expiresAtVal === '' ? (
                                                                <span className="invalid-msg">{saveQuoteModalErr.ERR_EXPIRED_AT_REQUIRED}</span>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* notes field */}
                                                    <Col className="col-12">
                                                        <div className="mb-3">
                                                            <Label className="form-label font-size-15">Notes</Label>
                                                            <RichTextEditor
                                                                editorClassName="custom-editor"
                                                                editorStyle={{ fontSize: "13px", fontFamily: "Poppins, sans-serif", overflow: 'scroll' }}
                                                                placeholder="Enter notes"
                                                                value={getQuoteNotes}
                                                                onChange={(e) => { setQuoteNotes(e) }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </TabPane>
                                        {/* client tab content */}
                                        <TabPane tabId={2} className="mt-3">
                                            <div>
                                                <Col className="col-12">
                                                    {/* radio button for client */}
                                                    <Row>
                                                        <Col className="col-12 mb-3"> <Label className="mb-0 font-size-15">Choose the option that you want to send the quote to.</Label></Col>
                                                    </Row>
                                                    <Col className="mb-4">
                                                        <div>
                                                            <div className="form-check form-check-inline font-size-16">
                                                                <Input
                                                                    type="radio"
                                                                    value="1"
                                                                    id="customRadioInline1"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input radio-btn-label"
                                                                    defaultChecked
                                                                    onClick={() => changeRadioBtnValue(true)
                                                                    }
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13 client-radio-label"
                                                                    htmlFor="customRadioInline1"
                                                                >
                                                                    Existing client
                                                                </Label>
                                                            </div>
                                                            <div className="form-check form-check-inline font-size-16">
                                                                <Input
                                                                    type="radio"
                                                                    value="2"
                                                                    id="customRadioInline2"
                                                                    name="customRadioInline1"
                                                                    className="form-check-input radio-btn-label"
                                                                    onClick={() => changeRadioBtnValue(false)}
                                                                />
                                                                <Label
                                                                    className="form-check-label font-size-13 client-radio-label"
                                                                    htmlFor="customRadioInline2"
                                                                >New client
                                                                </Label>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    {isExisting ?
                                                        <>
                                                            <Col className="col-12">
                                                                <div className="mb-3">
                                                                    <Label className="font-size-15">Select Client<span className='text-danger'> *</span></Label>
                                                                    <Select
                                                                        value={selectedGroup}
                                                                        onChange={(selectedGroupName) => {
                                                                            setselectedGroup(selectedGroupName);
                                                                        }}
                                                                        options={quoteClientList?.map((e, key) => {
                                                                            return { label: e?.client_name, value: e.quote_client_info_uuid }
                                                                        })}
                                                                        className="form-control select2-selection"
                                                                    />
                                                                    {selectedGroup === '' ? (
                                                                        <span className="invalid-msg">{saveQuoteModalErr.ERR_SALECT_CLIENT_NAME_REQUIRED}</span>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-3">
                                                                    {selectedGroup && <p className="muted-text">This quote will be saved for {selectedGroup.label}.</p>}
                                                                </div>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Row>

                                                                <Col className="col-md-6 col-12 mt-2">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label font-size-15">Client Name<span className='text-danger'> *</span></Label>
                                                                        <Input
                                                                            type="email"
                                                                            className="form-control"
                                                                            id="clientName"
                                                                            placeholder="Enter Client Name"
                                                                            value={validation.values.clientName || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.clientName && validation.errors.clientName ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.clientName && validation.errors.clientName ? (
                                                                            <FormFeedback type="invalid">{validation.errors.clientName}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 col-md-6 mt-2">
                                                                    {/*client company name  */}
                                                                    <div className="mb-3">
                                                                        <Label className="form-label font-size-15">Company Name<span className='text-danger'> *</span></Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="clientCompanyName"
                                                                            placeholder="Enter Name"
                                                                            value={validation.values.clientCompanyName || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.clientCompanyName && validation.errors.clientCompanyName ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.clientCompanyName && validation.errors.clientCompanyName ? (
                                                                            <FormFeedback type="invalid">{validation.errors.clientCompanyName}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 mt-2">
                                                                    {/*Client Company Email */}
                                                                    <div className="mb-3">
                                                                        <Label className="form-label font-size-15">Company Email<span className='text-danger'> *</span></Label>
                                                                        <Input
                                                                            type="email"
                                                                            className="form-control"
                                                                            id="clientCompanyEmail"
                                                                            placeholder="Enter Email"
                                                                            value={validation.values.clientCompanyEmail || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.clientCompanyEmail && validation.errors.clientCompanyEmail ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.clientCompanyEmail && validation.errors.clientCompanyEmail ? (
                                                                            <FormFeedback type="invalid">{validation.errors.clientCompanyEmail}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-12 mt-2">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label font-size-15">Company Address</Label>
                                                                        <Input
                                                                            className="notes-textarea"
                                                                            name="address"
                                                                            type="textarea"
                                                                            placeholder="Enter Address"
                                                                            rows="3"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={validation.values.address || ""}
                                                                            invalid={
                                                                                validation.touched.address && validation.errors.address ? true : false
                                                                            }
                                                                        />
                                                                        {validation.touched.address && validation.errors.address ? (
                                                                            <FormFeedback className="invalid-feedback" type="invalid">{validation.errors.address}</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                {/* client city */}
                                                                <Col md={6} className="mt-2">
                                                                    <div className="mb-3">
                                                                        <Label for="client_city" className="form-label font-size-15">
                                                                            City
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="client_city"
                                                                            placeholder="Enter City"
                                                                            value={validation.values.client_city || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={validation.touched.client_city && validation.errors.client_city ? true : false}
                                                                        />
                                                                        {validation.touched.client_city && validation.errors.client_city ? (<FormFeedback type="invalid">{validation.errors.client_city}</FormFeedback>) : null}
                                                                    </div>
                                                                </Col>
                                                                {/* client state */}
                                                                <Col md={6} className="mt-2">
                                                                    <div className="mb-3">
                                                                        <Label for="client_state" className="form-label font-size-15">
                                                                            State
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="client_state"
                                                                            placeholder="Enter State"
                                                                            value={validation.values.client_state || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={validation.touched.client_state && validation.errors.client_state ? true : false}
                                                                        />
                                                                        {validation.touched.client_state && validation.errors.client_state ? (<FormFeedback type="invalid">{validation.errors.client_state}</FormFeedback>) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col md={6} className="mt-2">
                                                                    <div className="mb-3">
                                                                        <Label for="client_country" className="form-label font-size-15">
                                                                            Country
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="client_country"
                                                                            placeholder="Enter Country"
                                                                            value={validation.values.client_country || ''}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={validation.touched.client_country && validation.errors.client_country ? true : false}
                                                                        />
                                                                        {validation.touched.client_country && validation.errors.client_country ? (<FormFeedback type="invalid">{validation.errors.client_country}</FormFeedback>) : null}
                                                                    </div>
                                                                </Col>
                                                                {/* client zip code */}
                                                                <Col md={6} className="mt-2">
                                                                    <div className="mb-3">
                                                                        <Label for="client_zip_code" className="form-label font-size-15">
                                                                            Zip Code
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="client_zip_code"
                                                                            placeholder="Enter Zip Code"
                                                                            value={validation.values.client_zip_code || ''}
                                                                            onChange={(e) => {
                                                                                const { value } = e.target;
                                                                                const formattedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
                                                                                if (formattedValue.length > 5) {
                                                                                    validation.handleChange({
                                                                                        target: {
                                                                                            name: 'client_zip_code',
                                                                                            value: `${formattedValue.slice(0, 5)}-${formattedValue.slice(5)}`,
                                                                                        },
                                                                                    });
                                                                                } else {
                                                                                    validation.handleChange({
                                                                                        target: {
                                                                                            name: 'client_zip_code',
                                                                                            value: formattedValue,
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            invalid={
                                                                                validation.touched.client_zip_code && validation.errors.client_zip_code ? true : false
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                // Allow Backspace if the length is greater than 0
                                                                                if (e.key === 'Backspace' && validation.values.client_zip_code.length > 0) {
                                                                                    return;
                                                                                }

                                                                                // Limit to 10 characters including hyphen
                                                                                if (e.target.value.length >= 10 && e.key !== 'Backspace') {
                                                                                    e.preventDefault();
                                                                                }
                                                                                // Allow numeric digits, hyphen, and Backspace
                                                                                if (!(
                                                                                    (e.key >= '0' && e.key <= '9') ||
                                                                                    e.key === 'Backspace' || e.key === '-'
                                                                                )) {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                        />
                                                                        {validation.touched.client_zip_code && validation.errors.client_zip_code ? (<FormFeedback type="invalid">{validation.errors.client_zip_code}</FormFeedback>) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div>
                                                                        {validation.values.clientCompanyName && !validation.errors.clientCompanyName && validation.values.clientCompanyEmail && !validation.errors.clientCompanyEmail && !validation.errors.clientName && <p className="muted-text">This quote will be saved for {validation.values.clientName}, and we will save the client's information for future use.</p>}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </Col>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </Row>
                        {/* footer */}
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    {activeTabVartical === 2 && <button
                                        className="btn-outline me-3"
                                        type="button"
                                        onClick={() => toggleTabVertical(activeTabVartical - 1)}
                                    >
                                        Previous
                                    </button>}
                                    <button
                                        type="button"
                                        className="btn-fill save-user d-flex"
                                        disabled={(activeTabVartical === 1 && (validation.errors.jobName || validation.errors.notes || (!expiresAtVal === '' || !expiresAtVal)))
                                            || ((activeTabVartical === 2 && ((!isExisting && (validation.errors.clientCompanyEmail || validation.errors.clientCompanyName || validation.errors.clientName || validation.errors.client_zip_code || validation.errors.client_state || validation.errors.client_city || validation.errors.client_country || validation.errors.jobName || validation.errors.notes || (!expiresAtVal === '' || !expiresAtVal))))) ||
                                                (activeTabVartical === 2 && ((isExisting && ((!selectedGroup === '' || !selectedGroup) || validation.errors.notes || (!expiresAtVal === '' || !expiresAtVal))))))}
                                        onClick={() => toggleTabVertical(activeTabVartical + 1)}
                                    >
                                        {isLoading ? <Spinners setLoading={setLoading} /> : ''}
                                        {activeTabVartical === 2 ? 'Save' : 'Next'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            {/* Toast message */}
            <ToastContainer />
            <ConfirmationModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                title={'Delete quote'}
                description={'Are you sure you want to delete this quote item?'}
                onAction={() => isDeleteHandle()}
                afterAction={isDeleted}
                actionName={'Delete'}
            />
            <SubscriptionMiddleware
                show={showMiddleware}
                onclickcancle={() => { setShowMiddleware(false); }}
                isFromAuthGuard={true}
            />
        </React.Fragment >
    );
}

export default QuoteCalculator;